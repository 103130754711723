import React, { useContext, useEffect, useState } from "react";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import MiscellaneousMaster from "../API/API_SERVISES/MiscellaneousMaster";
import AddAddress from "../API/API_SERVISES/AddAddress";
import CurrentDateAndTime from "../API/API_SERVISES/CurrentDateAndTime";
import GoogleMap from "./GoogleMap";
import MyContext from "./MyContext";


const MainModalComponent2 = (props) => {

  const { serviceType, getDeliveryTimeSlots, getDeliveryAndTimeSlotId, setLoading,
    heading1, heading2, ChangeDeliveryDate, AllottedTimeSlot, deliveryDate, deliveryTime, setDeliveryTime } = props;

  const { selectedService, CompanyDetail, setAddAddresses, addresses } = useContext(MyContext)

  const padWithZero = (num) => num.toString().padStart(2, '0');

  const getNext5Days = async () => {
    const startDateString = await CurrentDateAndTime.getCurrentDate();

    const startDate = new Date(startDateString);
    // alert(startDate)

    // Ensure the start date is valid
    if (isNaN(startDate)) {
      throw new Error('Invalid start date');
    }

    const nextFiveDates = [];
    const data = serviceType.filter(item => item.id == selectedService);

    for (let i = 0; i < data[0].advanceOrderDays; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);

      const formattedDate = `${padWithZero(date.getDate())}-${padWithZero(date.getMonth() + 1)}-${date.getFullYear()}`;
      nextFiveDates.push(formattedDate);
    }

    return nextFiveDates;
  };

  const [next5Days, setNext5Days] = useState([]);

  const [Distance, setDistance] = useState(4);
  const [TimeSlot, setTimeslot] = useState([]);
  const [TimeSlotAccToKM, setTimeSlotAccToKM] = useState({});


  // useEffect(() => {
  //   const fetchTimeSlot = async () => {
  //     props.setLoading(true)
  //     try {
  //       const data = await DeliveryAndTimeSlot.GetDeliveryAndTimeSlot();
  //       // console.log(data);

  //       if (data) {
  //         setTimeslot(data);
  //         props.setLoading(false)
  //       } else {
  //         props.setLoading(false)
  //         alert("No data found in alloted");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching time slots:", error);
  //       // Handle the error appropriately, e.g., show an error message to the user
  //     }
  //   };

  //   fetchTimeSlot();
  // }, []); // Make sure to provide an empty dependency array to run the effect only once when the component mounts

  useEffect(() => {
    const fetchNext5Days = async () => {
      const dates = await getNext5Days();
      setNext5Days(dates);
    };

    fetchNext5Days();
  }, [selectedService]);

  function convertTo12HourFormat(twentyFourHourTime) {
    // Check if input is valid
    if (!twentyFourHourTime || typeof twentyFourHourTime !== 'string') {
      return 'Invalid input';
    }
    
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);
  
    // Handle invalid hour or minute values
    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return 'Invalid time format';
    }
  
    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }

    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    if (minutes != undefined && minutes != null) {
      const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
      return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
    }
    else {
      return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
    }
  }
  useEffect(() => {
    const fetchTime = () => {
      setLoading(true)
      // Function to find the array element based on delivery distance
      function findArrayElement(variable) {
        for (const element of TimeSlot) {
          if (
            variable >= element.delvryDistRangeFromKM &&
            variable <= element.delvryDistRangeToKM
          ) {
            setLoading(false)
            return element;
          }
        }
        setLoading(false)
        return null;
      }

      const result = findArrayElement(Distance);
      setLoading(false)
      if (result) {
        setLoading(false)
        setTimeSlotAccToKM(result.deliveryTimeSlots);
      } else {
        setLoading(false)
        // console.log("No time slot found for the given distance");
      }
    };

    fetchTime(); // Call fetchTime inside the second useEffect
  }, [Distance, TimeSlot]); // Include Distance and TimeSlot in the dependency array

  // Assuming you have a state variable like this
  const [miscellaneousData, setMiscellaneousData] = useState([]);
  // useEffect(() => {
  //   const fetchMiscellaneousData = async (id) => { 
  //     try {
  //       props.setLoading(true)
  //       const miscellaneousTime =
  //         await MiscellaneousMaster.GetMiscellaneousMasterById(id, 170);
  //       // console.log("MiscellaneousTime", miscellaneousTime);
  //       return miscellaneousTime;
  //     } catch (error) {
  //       props.setLoading(false)

  //       console.error("Error fetching miscellaneous data:", error);
  //       return null;
  //     }
  //   };

  //   // Check if TimeSlotAccToKM is an array before using forEach
  //   if (Array.isArray(TimeSlotAccToKM)) {
  //     // Initialize an empty array to store responses
  //     const responses = [];

  //     // Use Promise.all to wait for all API calls to complete
  //     Promise.all(
  //       TimeSlotAccToKM.map(async (id) => {
  //         const response = await fetchMiscellaneousData(id);
  //         responses.push(response);
  //       })
  //     ).then(() => {
  //       // Now responses array contains all the API responses
  //       setMiscellaneousData(responses);
  //       props.setLoading(false)

  //     });
  //   } else {
  //     console.error("TimeSlotAccToKM is not an array");
  //   }
  // }, [TimeSlotAccToKM]);

  const dataForAllotedTime = {
    deliveryPointName: 1,
    orderType: selectedService,
    distance: 0,
    isCurrentDate: true
  }

  useEffect(() => {
    const fetchdata = async () => {
      setLoading(true)
      try {
        const deliverySlot = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(dataForAllotedTime, CompanyDetail.isEnableGoogleMap);

        const data = deliverySlot.filter((data, index, self) =>
          index === self.findIndex((t) => (
            t.id === data.id
          ))
        );

        const serviceTypeId = dataForAllotedTime.orderType;

        const serviceData = serviceType.filter(item => item.id == serviceTypeId)
        const selectedTimeSlot = data.slice(0, serviceData[0]?.noOfTimeSlot);
        //alert(`Main Modal2- ${serviceData[0]?.noOfTimeSlot}`);
        setLoading(false)

        if (data) {
          setMiscellaneousData(selectedTimeSlot);
          setLoading(false)
        } else {
          setLoading(false)
          alert("No data found in alloted");
        }
      } catch (error) {
        console.error("Error fetching time slots:", error);
        setLoading(false)
      }
    }
    fetchdata()
  }, [selectedService])

  const fetchAddress = async () => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    if (loginData) {
      var addresses = await AddAddress.getAddress(loginData.d1Id);
      // console.log(addresses);
      if (addresses) {
        //setAddAddresses(addresses);
        setAddAddress(addresses);
      } else {
        alert("Error Occoured");
      }
    }
  }

  const fetchLoginAddress = async () => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    if (loginData) {
      var addresses = await AddAddress.getAddress(loginData.d1Id);
      // console.log(addresses);
      if (addresses) {
        setAddAddresses(addresses);
        setAddAddress(addresses);
      } else {
        alert("Error Occoured");
      }
    }
  }
  useEffect(() => {
    fetchLoginAddress();
  }, []); //addresses

  const [selectedOption, setSelectedOption] = useState(0);

  const handleOptionChange = async (index, addressId) => {
    //debugger;
    setSelectedOption(index);

    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);

    var getaddress = await AddAddress.getAddressById(addressId, loginData.d1Id);
    if (getaddress.isDefault == false) {
      const updatedValues = {
        ...getaddress,
        "isDefault": true,
      };
      await AddAddress.updateAddress(updatedValues, loginData.d1Id);
      fetchAddress();

      if (CompanyDetail.isEnableGoogleMap) {
        if (getaddress.distance == 0 || getaddress.distance == null || getaddress.distance == undefined) {
          alert("The distance is 0. Please select an address from Google Maps");
        }
      }

      const updatedDeliveryTimeSlot = {
        deliveryPointName: 1,
        orderType: 752,
        distance: getaddress.distance || 0,
        isCurrentDate: true,
      };
      getDeliveryTimeSlots(updatedDeliveryTimeSlot)
    }
  };

  const [isLogin, setIsLogin] = useState();

  useEffect(() => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    setIsLogin(loginData)
  }, [])

  const [addAddress, setAddAddress] = useState([]);

  const getLocalStorageData = () => {
    var googleData = localStorage.getItem("googleMapData");
    var googleMap = JSON.parse(googleData);
    // console.log("Google Map data:", googleMap);
    if (googleData != null) {
      const googleMap = JSON.parse(googleData);
      const address = googleMap.destination_addresses[0];
      console.log("Address:", address);
      setAddAddress(address);
    }
  }

  const [triggerUseEffect, setTriggerUseEffect] = useState(false)

  useEffect(() => {
    getLocalStorageData();
  });
  useEffect(() => {
    getLocalStorageData();
    // console.log(triggerUseEffect);
    setTriggerUseEffect(false)
  }, [triggerUseEffect]);

  const convertDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const handleDeliveryTimeChange = (e) => {
    setDeliveryTime(e.target.value)
    localStorage.setItem("deliveryTime", e.target.value)
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div className="main-modal-section-2">
        <div style={{ marginBottom: "8px" }} className="">
          Home Delivery Location             {/* {props.heading1} */}
        </div>
        <div className="select-k1" style={{ width: "100%" }}>
          <select name="" id="">
            <option value="">Mother's Hut</option>
            {/* <option value="">Other's</option> */}
          </select>
        </div>
      </div>

      <div className="main-modal-section-4">
        <div style={{ marginBottom: "8px" }} className="">
          {heading1}
        </div>

        <div className="select-k1 address-box-formodal mainModalAddress_Scroll" style={{ height: "auto", maxHeight: "105px", width: "100%" }}>
          {isLogin && isLogin != "" ?
            // <div style={{ color: "white" }}>
            //   {addAddress && addAddress
            //     .filter(item => item.isDefault === true)
            //     .map((item, index) => (
            //       <div key={index}>
            //         <label style={{ color: "white" }}>{item.address}</label>
            //       </div>
            //     ))}
            // </div>
            null
            :
            <div style={{ color: "white" }}>
              {addAddress}
            </div>}

          {/* {addresses && addresses.length > 0 ? (<div><hr /><label style={{ color: "white" }}> Previous Address :-</label></div>) : null} */}
          {addresses && addresses.length > 0 ? (
            addresses.map((address, index) => (
              <div className="addressDiv" key={address.id} onClick={() => handleOptionChange(index, address.id)}>
                <label className="label-1">
                  <input className="input-1"
                    type="radio"
                    value={index}
                    checked={selectedOption === index}
                    onChange={() => handleOptionChange(index, address.id)}
                  />
                  <label style={{
                    color: (selectedOption === index) ? "white" : "rgba(156, 163, 175, 1)",
                    fontSize: (selectedOption === index) ? "13px" : "12px",
                    marginLeft: "20px"
                  }}>
                    {address.address} --- {address.distance ? address.distance : 0} Km</label>
                  <span className="checkmark"></span>
                </label>
              </div>
            ))
          ) : (
            null
          )}
          {/* <select>
            {addresses && addresses.length > 0 ? (
              addresses.map((address, index) => (
                <option>
                  <div className="address-box" key={index}>
                    <div className="address-text">{address.locality} </div>
                    <div className="address-text">{address.address} </div>

                  </div>
                </option>
              ))
            ) : (
              <div></div>
            )}
          </select> */}
        </div>
        {CompanyDetail.isEnableGoogleMap && (
          <>
            <div className="text-between-lines">
              <hr className="line2" />
              <span className="text">OR</span>
              <hr className="line2" />
            </div>
            <div>
              <GoogleMap setTriggerUseEffect={setTriggerUseEffect}
                getDeliveryTimeSlots={getDeliveryTimeSlots}
                getDeliveryAndTimeSlotId={(getDeliveryAndTimeSlotId)}
                fetchLoginAddress={fetchLoginAddress}
                getLocalStorageData={getLocalStorageData}
              />
            </div>
          </>
        )}

      </div>
      <div className="main-modal-section-3">
        <div className=""> {heading2} </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className="select-k1" style={{ width: "50%" }}>
            <label htmlFor="">Delivery Date</label>
            <select name="" value={deliveryDate} id="" onChange={ChangeDeliveryDate}>
              {next5Days && next5Days.map((date, index) => (
                <option key={index} value={convertDateFormat(date)}>
                  {date}
                </option>
              ))}
            </select>
          </div>
          <div className="select-k1" style={{ width: "50%" }}>
            <label htmlFor="">Delivery Time</label>
            <select name="" id="" onChange={handleDeliveryTimeChange} value={deliveryTime}>
              {(() => {
                const AllottedTimeSlot1 = AllottedTimeSlot
                if (
                  AllottedTimeSlot1 &&
                  Array.isArray(AllottedTimeSlot1) &&
                  AllottedTimeSlot1.length > 0
                ) {
                  return AllottedTimeSlot1.map((data) => (
                    <option key={data.id} value={data.id}>
                      {convertTo12HourFormat(data.availableFrom)} to{" "} {convertTo12HourFormat(data.availableTo)}
                    </option>
                  ));
                } else {
                  return (
                    <option value="">Not available</option>
                  );
                }
              })()}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainModalComponent2;
