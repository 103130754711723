import React, { useContext } from "react";
import axios from "../axiosConfig";
import AddAddress from "./AddAddress";
import CurrentDateAndTime from "./CurrentDateAndTime";
import MyContext from "../../Component/MyContext";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const DeliveryAndTimeSlot = {
  GetDeliveryAndTimeSlot: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot/GetDeliveryAndTimeSlots`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 5000
        },
        {
          withCredentials: true,
        }
      );
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data.result;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  InsertDeliveryAndTimeSlot: async (data, checkedIds) => {
    console.log(data)
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot`;
      const response = await axios.post(
        apiUrl,
        {
          deliveryPointName: data.deliveryPointName,
          orderType: data.orderType,
          mobile: data.mobile,
          website: data.website,
          serviceTax: data.serviceTax,
          delvryDistRangeFromKM: data.delvryDistRangeFromKM,
          delvryDistRangeToKM: data.delvryDistRangeToKM,
          minDelvryAmtInCart: data.minDelvryAmtInCart,
          deliveryCharges: data.deliveryCharges,
          estimateDelvryTime: data.estimateDelvryTime,
          noOfDelveriesTakenInPerTimeSlot: data.noOfDelveriesTakenInPerTimeSlot,
          deliveryStatus: parseBoolean(data.deliveryStatus),
          deliveryTimeSlots: checkedIds,
          isActive: true,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return true;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetDeliveryAndTimeSlotById: async (DeliveryAndTimeSlotId) => {
    try {
      console.log("get by id");
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot?DeliveryAndTimeSlotId=${DeliveryAndTimeSlotId}`;
      const response = await axios.get(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 5000
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // // ************************* Get By Id ****************************
  // GetAgentById: async (id) => {
  //   try {
  //     const apiUrl = `${WebApiUrl}/api/Agent?agentId=${id}`;
  //     const response = await axios.get(apiUrl, {}, {
  //       withCredentials: true,
  //     });
  //     console.log('Response:', response.data.result);
  //     //setEditCallBack(response.data.result);
  //    return response.data.result;
  //   } catch (error) {
  //     console.error('Error:', error.message);
  //   }
  // },

  deleteDeliveryAndTimeSlot: async (DeliveryAndTimeSlotId) => {
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot?DeliveryAndTimeSlotId=${DeliveryAndTimeSlotId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  updateDeliveryAndTimeSlot: async (data) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }

    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot?DeliveryAndTimeSlotId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          name: data.name,
          DeliveryAndTimeSlotId: data.DeliveryAndTimeSlotId,
          DeliveryAndTimeSlotGSTIN: data.DeliveryAndTimeSlotGSTIN,
          address: data.address,
          city: data.city,
          pinCode: data.pinCode,
          phone: data.phone,
          email: data.email,
          state: data.state,
          transportationMode: data.transportationMode,
          vehicleNo: data.vehicleNo,
          vehicleType: data.vehicleType,
          fuelAvgLtrKM: Number(data.fuelAvgLtrKM),
          fuelChargeKM: Number(data.fuelChargeKM),
          docLandingRR_LR: data.docLandingRR_LR,
          extraField1: data.extraField1,
          extraField2: data.extraField2,
          extraField3: data.extraField3,
          extraField4: data.extraField4,
          extraField5: data.extraField5,
          isActive: parseBoolean(data.isActive),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return response.data.isSuccess

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  getAllottedDeliveryTimeSlots: async (DeliveryAndTimeSlotId, isEnableGoogleMap) => {
    const currentDate = await CurrentDateAndTime.getCurrentDate();
    const selectedIsoDate = localStorage.getItem("deliverydate")
    var isCrntDate = DeliveryAndTimeSlotId.isCurrentDate;
    if (currentDate == selectedIsoDate)
      isCrntDate = true
    else
      isCrntDate = false
    // *******************************
    let timeDistance = DeliveryAndTimeSlotId.distance || 0;
    if (isEnableGoogleMap == true) {
      if (DeliveryAndTimeSlotId.orderType == 752) {
        if (DeliveryAndTimeSlotId.distance == 0 || DeliveryAndTimeSlotId.distance == null) {
          return [];
          // var loginDataString = localStorage.getItem("accountLogin");
          // var loginData = JSON.parse(loginDataString);
       
          // if (loginData) {
          //   debugger
          //   const defaultData = await AddAddress.getAddress(loginData.d1Id)
          //   if (defaultData) {
          //     const defaultAddress = defaultData.filter(item => item.isDefault == true);
          //     if (defaultAddress && defaultAddress.length > 0) {
          //       if (defaultAddress[0].distance != null && defaultAddress[0].distance != 0) {
          //         timeDistance = defaultAddress[0]?.distance;
          //       }
          //     }
          //     // else {
          //     //   return false;
          //     // }
          //   }
          //   else {
          //     return [];
          //   }
          // }
          // else {
          //   const googleData = localStorage.getItem("googleMapData");
          //   const distance = JSON.parse(googleData);

          //   const totaldistance = distance && distance?.rows[0]?.elements[0]?.distance?.text;
          //   if (totaldistance) {
          //     const spiltData = totaldistance.split(" ");
          //     timeDistance = spiltData[0];
          //   }
          //   else {
          //     return [];
          //   }
          // }
        }
        // else if (timeDistance == 0 || timeDistance == undefined || timeDistance == null) {
        //   alert("The distance is 0. Please select an address from Google Maps")
        //   return []
        // }
        // else {
        //   return []
        // }
      }
    }

    if (DeliveryAndTimeSlotId.orderType == 751 || DeliveryAndTimeSlotId.orderType == 753) {
      timeDistance= 0
    }

    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot/getAllottedDeliveryTimeSlots`;
      const response = await axios.post(
        apiUrl,
        {
          deliveryPointName: DeliveryAndTimeSlotId.deliveryPointName,
          orderType: DeliveryAndTimeSlotId.orderType,
          distance: parseFloat(timeDistance),
          isCurrentDate: isCrntDate,//Boolean(DeliveryAndTimeSlotId.isCurrentDate),
          deliveryStatus: true,
          isActive: true,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ******************************
  getAllottedDeliveryTimeSlotsDistanceWise: async (DeliveryAndTimeSlotId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot/getAllottedDeliveryTimeSlots`;
      const response = await axios.post(
        apiUrl,
        {
          deliveryPointName: DeliveryAndTimeSlotId.deliveryPointName,
          orderType: DeliveryAndTimeSlotId.orderType,
          distance: parseFloat(DeliveryAndTimeSlotId.distance),
          isCurrentDate: Boolean(DeliveryAndTimeSlotId.isCurrentDate),
          deliveryStatus: true,
          isActive: true,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


};

export default DeliveryAndTimeSlot;
