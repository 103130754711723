import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import AddAddress from '../API/API_SERVISES/AddAddress';
import DeliveryAndTimeSlot from '../API/API_SERVISES/DeliveryAndTimeSlot';
import MyContext from './MyContext';

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const mapContainerStyle = {
  width: '100%',
  height: '350px'
};
const libraries = ['places'];

const GoogleMapPage = ({ setTriggerUseEffect, getDeliveryTimeSlots, getDeliveryAndTimeSlotId, fetchLoginAddress, getLocalStorageData }) => {
  const [selectedAddress, setSelectedAddress] = useState('');
  const [distance, setDistance] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentCoordinates, setCurrentCoordinates] = useState(null);
  const [locationConfirmed, setLocationConfirmed] = useState(false);
  const { setAddAddresses, setAllottedTimeSlot } = useContext(MyContext);

  const input1Ref = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA3Z3M4ymJUaQnTnD5ZD8lLbGbXntTKLHI',
    libraries
  });

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const loginDataString = localStorage.getItem("accountLogin"); // This is a string
        const loginData = JSON.parse(loginDataString);

        if (loginData?.d1Id) { // Optional chaining in case loginData is null or undefined
          const getaddress = await AddAddress.getAddress(loginData.d1Id);
          console.log(getaddress);

          if (getaddress && getaddress.length > 0) {
            const findDefault = getaddress.find(address => address.isDefault === true);
            console.log(findDefault); // Log or handle the default address as needed
            if (findDefault.distance == 0) {
              try {
                const { latitude, longitude } = await getLocation();
                fetchDistance(latitude, longitude);
              } catch (error) {
                console.error('Error fetching location:', error);
              }
            }
            else {
              setAllottedTimeSlot()
            }
          } else {
            console.log('No addresses found');
          }
        } else {
          try {
            const { latitude, longitude } = await getLocation();
            fetchDistance(latitude, longitude);
          } catch (error) {
            console.error('Error fetching location:', error);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentCoordinates({ lat: latitude, lng: longitude });
            resolve({ latitude, longitude });
          },
          (error) => {
            console.error('Error getting location:', error);
            reject(error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        reject(new Error('Geolocation not supported'));
      }
    });
  };

  const openGoogleMaps = () => {
    setShowModal(true);
    // input1Ref.current.focus();
  };

  const closeModal = () => {
    getLocalStorageData();
    fetchLoginAddress();

    const dist = distance && distance?.rows[0]?.elements[0]?.distance?.text;
    if (dist) {
      const splitData = dist.split(' ')
      const updatedValues = {
        distance: splitData[0],
        orderType: 752,
        deliveryPointName: 1,
        isCurrentDate: true
      };
      getDeliveryTimeSlots(updatedValues)
    }
    setShowModal(false);
  };

  const confirmLocation = async () => {
    if (currentCoordinates) {
      setSelectedAddress('')
      localStorage.setItem("googleMapData", JSON.stringify(distance));
      console.log('Selected Coordinates:', currentCoordinates);
      // getDeliveryTimeSlots(getDeliveryAndTimeSlotId)
    } else {
      console.error('No location selected.');
      return;
    }

    const addressString = distance.rows[0].elements[0].distance.value;
    console.log(distance.destination_addresses[0]);

    const addressss = distance.destination_addresses[0];

    const words = addressss.split(/\s+/);
    const pinCode = words[words.length - 2];
    const pc = pinCode.split(',')

    const parts = addressss.split(',');
    const pincodeIndex = parts.length - 2;
    const addressExceptPinCode = parts.slice(0, pincodeIndex + 1).join(',');

    setTriggerUseEffect(true);
    setLocationConfirmed(true);
    // alert(currentCoordinates.lat)
    // alert(currentCoordinates.lng)  

    const storedArrayString = localStorage.getItem('accountLogin');
    if (storedArrayString) {
      try {
        const loginUser = JSON.parse(storedArrayString);

        const addressData = {
          name: loginUser.name,
          mobile: loginUser.mobileNo,
          pincode: pc[0],
          state: 0,
          address: addressExceptPinCode,
          locality: "",
          city: "",
          latitude: currentCoordinates.lat,
          longitude: currentCoordinates.lng,
          typeOfAddress: 0,
          isDefault: true,
        };
        await AddAddress.insertAddress(addressData, loginUser.d1Id);

        // *********** Fetch Address**********************
        var loginData = JSON.parse(storedArrayString);
        if (loginData) {
          var addresses = await AddAddress.getAddress(loginData.d1Id);
          if (addresses) {
            setAddAddresses(addresses);
          }
        }
      } catch (error) {
        console.error("Error inserting address:", error);
      }
    } else {
      const addressData = {
        name: "",
        mobile: "",
        pincode: pc[0],
        state: 0,
        address: addressExceptPinCode,
        locality: "",
        city: "",
        latitude: currentCoordinates.lat,
        longitude: currentCoordinates.lng,
        typeOfAddress: 0,
        isDefault: true,
      };
      localStorage.setItem("DefaultAddress", JSON.stringify(addressData));
    }
    closeModal();
  };


  const handleSelectedAddressChange = (address) => {
    setSelectedAddress(address);
  };

  const handleSelect = async (address) => {
    setSelectedAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setCurrentCoordinates(latLng);
      fetchDistance(latLng.lat, latLng.lng);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const [isDeliverDistance, setIsDeliverDistance] = useState(0);

  const fetchDistance = async (lat, lng) => {
    try {
      // const apiUrl = `${express_server_url}/api/map/fetchDistance?origin=${currentCoordinates.lat},${currentCoordinates.lng}&destination=${lat},${lng}`;
      const apiUrl = `${WebApiUrl}/api/map/fetchDistance?origin=${`23.3698269`},${"88.4821275"}&destination=${lat},${lng}`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      setDistance(response.data);
      //localStorage.setItem("googleMapData", JSON.stringify(response.data));

      const distance = response.data?.rows[0]?.elements[0]?.distance?.text;
      const splitData = distance.split(' ');
      //alert(splitData[0]);

      const updatedValue = {
        deliveryPointName: 1,//getDeliveryAndTimeSlotId.deliveryPointName,
        orderType: 752,
        distance: splitData[0],
        isCurrentDate: true,
      }
      let length = 0;
      console.log(updatedValue);
      const data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlotsDistanceWise(updatedValue)

      length = data?.length
      //alert(`length - ${length}`)
      setIsDeliverDistance(length || 0)
      // alert(response.data.rows[0].elements[0].distance.value)
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  const onMapClick = (event) => {
    const { latLng } = event;
    const latitude = latLng.lat();
    const longitude = latLng.lng();
    setCurrentCoordinates({ lat: latitude, lng: longitude });
    fetchDistance(latitude, longitude);
  };

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={currentCoordinates}
        onClick={onMapClick}
      >
        {currentCoordinates && <Marker position={currentCoordinates} />}
      </GoogleMap>
    );
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps...';


  return (
    <div>
      <div className="input-container">
        <PlacesAutocomplete
          value={selectedAddress}
          onChange={handleSelectedAddressChange}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input onClick={openGoogleMaps}
                {...getInputProps({
                  placeholder: '⊕ Enter your delivery Address',
                  className: 'input-with-image'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? '#41b6e6' : '#fff'
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style
                      })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <button className="input-btn" onClick={openGoogleMaps}>
          Change Address
        </button>
      </div>

      {showModal && (
        <div className="modal-back1">
          <div className="map-modal" style={{ top: "50%" }}>
            <div style={{ width: '100%', top: '0%' }}>
              <div
                onClick={closeModal}
                style={{ width: 'auto', display: 'flex', justifyContent: 'flex-end' }}
              >
                <i className="fa-solid fa-xmark" style={{ color: 'black', fontSize: '16px' }}></i>
              </div>
              <div style={{ width: '100%', padding: '10px' }}>
                <PlacesAutocomplete
                  value={selectedAddress}
                  onChange={handleSelectedAddressChange}
                  onSelect={handleSelect}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <input //ref={input1Ref}
                        {...getInputProps({
                          placeholder: 'Search address',
                          className: 'input-with-image'
                        })}
                        style={{ width: "85%" }}
                      />
                      <div className="autocomplete-dropdown-container" style={{ position: "absolute", zIndex: "100" }}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                            padding: "10px",
                          };
                          return (
                            <div {...getSuggestionItemProps(suggestion, { style })}>
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div>
                {currentCoordinates && (
                  <div className="testing" style={{ width: '100%', height: '100%' }}>
                    {renderMap()}
                  </div>
                )}
              </div>
              <div>
                {distance !== null && (
                  <div style={{ backgroundColor: 'white', padding: '5px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <label style={{ color: 'blue', fontWeight: '600' }}>Distance : {distance && distance?.rows[0]?.elements[0]?.distance?.text}</label>
                  </div>
                )}
              </div>
              <div>
                {distance !== null && (
                  <div style={{ backgroundColor: 'white', padding: '5px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <label style={{ color: 'black', fontWeight: '500' }}><span style={{ fontWeight: '600' }}>Address :</span> {distance && distance?.destination_addresses[0]}</label>
                  </div>
                )}
              </div>
              <div>
                {distance !== null && (
                  <div style={{ backgroundColor: 'white', padding: '5px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {
                      isDeliverDistance && isDeliverDistance != 0 ? (  //&& isDeliverDistance > 0
                        //parseInt(distance && distance?.rows[0]?.elements[0]?.distance?.value) >= 15000 ? (
                        <button onClick={confirmLocation} className="confirmLocation">
                          Confirm Location
                        </button>
                      ) : (
                        <label style={{ color: 'red', fontWeight: '600' }}>Currently not delivering to this address</label>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GoogleMapPage;