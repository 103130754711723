import React, { useEffect, useState } from "react";
import AddAddressModal from "./AddAddressModal";
import AddAddressModalProfle from "./AddAddressModalProfle";
import AddAddress from "../API/API_SERVISES/AddAddress";
import Loader from "./Loader";
import MyAccountComponent2Address from "./MyAccountComponent2Address";

const MyAccountComponent2 = (props) => {


  return (
    <div>
      <div
        style={{
          display: "flex", // height: "90vh",
          justifyContent: "center"
        }}
        className=""
      >
        <div
          style={{ height: "78vh", borderRadius: "15px" }}
          className="card-cart cart height"
        >
          {/* <button className="add-address-button" onClick={openAddressmodal}>
            Add Address <i className="fa-solid fa-plus"></i>
          </button> */}
          
          <label
            style={{
              justifyContent: "flex-start",
              paddingLeft: "60px !important",
            }}
            className="title"
          >
            &nbsp; Address
          </label>
          <MyAccountComponent2Address />
        </div>
      </div>
    </div>
  );
};

export default MyAccountComponent2;
