import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;
var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const SmsTemplate = {

    GetAssignTemplates: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates/GetAssignTemplates`;
            //const apiUrl = `${express_server_url}/api/SmsTemplate/GetSmsTemplateMasters`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ******************* Get Assign Template by Name *****************
    GetAssignTemplatesByName: async (smsType, transId, accId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates/GetAssignTemplatesByName`;
            const response = await axios.post(apiUrl,
                {
                    smsType: smsType,
                    transId: transId,
                    accId: accId
                }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    }
};

export default SmsTemplate;
