import React, { useContext, useEffect, useState } from "react";
// import CartProduct from '../Component/CartProduct'
import MyContext from "../Component/MyContext";
import MobilesSection1 from "../Component/MobileSections/MobilesSection1";
import MobilesSection2 from "../Component/MobileSections/MobilesSection2";
import MobilesSection3 from "../Component/MobileSections/MobilesSection3";
import AddCouponModal from "../Component/AddCouponModal";
import AddAddressModal from "../Component/AddAddressModal";
import cod from "../Assets/cash-on-delivery.png";
import pod from "../Assets/payment-method.png";
import Order from "../API/API_SERVISES/Order";
import TableApi from "../API/API_SERVISES/Table";
import TableGroupApi from "../API/API_SERVISES//TableGroup";
import MiscellaneousMaster from "../API/API_SERVISES//MiscellaneousMaster";
import TaxCategoryApi from "../API/API_SERVISES/TaxCategory";
import OrderAPI from "../API/API_SERVISES/Order";
import Loader from "../Component/Loader";
import MyAccountComponent2 from "../Component/MyAccountComponent2";
import AccountApi from "../API/API_SERVISES/Account";
import MyAccountComponent2Address from "../Component/MyAccountComponent2Address";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import BillTypeApi from "../API/API_SERVISES/BillType";
import BillSeriesMasterApi from "../API/API_SERVISES/BillSeriesMaster";
import moment from "moment";
import EmailAPI from "../API/API_SERVISES/Email";
import SMSAPI from "../API/API_SERVISES/SMS";
import { useNavigate } from "react-router-dom";
import Table from "../API/API_SERVISES/Table";
import TableAndAddress from "../Component/TableAndAddress";
import PaymentModeSelection from "../Component/PaymentModeSelection";
import TableGroup from "../API/API_SERVISES//TableGroup";
import Account from "../API/API_SERVISES/Account";
import CurrentDateAndTime from "../API/API_SERVISES/CurrentDateAndTime";
import AddAddress from "../API/API_SERVISES/AddAddress";
import SmsTemplate from "../API/API_SERVISES/SMSTemplate";
import SMS from "../API/API_SERVISES/SMS";
import MainModal from "../Component/MainModal";

// import BillTypeApi from "../API/API_SERVISES/BillType"
// import BillSeriesMasterApi from "../API/API_SERVISES/BillSeriesMaster"
// import MyAccount from "./MyAccount";

const MyCart = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const productlist = props.data;
  // // console.log(productlist)
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [RemoveOnMobile, setRemoveOnMobile] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setRemoveOnMobile(false);
      } else {
        setRemoveOnMobile(true);
      }
    };
    // Initial check
    handleResize();
    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [isFromOrderTkr, setIsFromOrderTkr] = useState();

  useEffect(() => {
    var isFromOrderTak = localStorage.getItem("OrderTaker");
    setIsFromOrderTkr(isFromOrderTak);
  }, [])
  const [filteredTable, setFilteredTable] = useState({});

  const filterDataFuntn = (tableId) => {
    if (Array.isArray(tableNumberData) && tableNumberData.length > 0) {
      var filteredTableName = tableNumberData.filter(
        (item) => item.id == tableId
      );
      console.log(filteredTableName);
      // alert("if")
      setFilteredTable(filteredTableName);
    } else {
      setFilteredTable("");
      // alert("else")
    }
  }

  const navigate = useNavigate();
  const {
    myaccountmodal,
    myaccountmodal1,
    buttonClickCounts,
    setButtonClickCounts,
    orderList3,
    setOrderList3,
    setOrderList,
    // orderList,
    cartData,
    setCartData,
    LoginShow,
    setLoginShow,
    imageBaseUrl,
    CompanyDetail,
    setSelectedService,
    selectedService,
    setOrderType,
    OrderType,
    isInputDisabled,
    fetchItemMaster,
    isFromOrderTaker,
    RemoveFromCart,
    AddToCart,
    RunningOrderData,
    productlist,
    fetchCartdata,
    setProductList,
    loginData,
    currentTimeData,
    setAllottedTimeSlot,
    AllottedTimeSlot,
    messageWhatsAppFunction,
    closemainmodal,
    mainModal,
    fetchData,
    setAddAddresses,
    setActiveLink,
  } = useContext(MyContext);

  // console.log(selectedService);
  const [selectedServiceID, setSelectedServiceID] = useState();
  const [AddressChange, setAddressChange] = useState("address1");
  const [payment, setPayment] = useState("0");
  const [, setConstextProduct] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  // const [orderList, setOrderList] = useState([]);
  // const [orderList3, setOrderList3] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalamountmain, setTotalamountmain] = useState(0);
  const [dateOptions, setDateOptions] = useState([]);
  // const [AllottedTimeSlot, setAllottedTimeSlot] = useState([]);

  // useEffect(() => {
  //   let total = 0;
  //   cartData.forEach(item => {
  //     total += item.salePrice * item.quantity;
  //   });

  //   setTotalAmount(total);

  //   const totalWithCharges = total + total / 20 + total / 25;

  //   setTotalamountmain(totalWithCharges);

  //   console.log(totalWithCharges);
  // }, [cartData]);

  // console.log("setOrderList", orderList);
  useEffect(() => {
    const removedata = () => {
      const userArrayString = localStorage.getItem('accountLogin');
      const userArray = userArrayString ? JSON.parse(userArrayString) : null;
      setLoginShow(userArray !== null ? false : true);
      // The following console.log might not show the updated value immediately
      console.log(LoginShow);
    };
    // Call the removedata function when the component mounts
    removedata();
    setActiveLink("/MyCart")
  }, []);

  useEffect(() => {
    calculateTotalAmount();
  }, [cartData]);

  const [imageModal, setimageModal] = useState(false)
  const [selectedImgId, setSelectedImgId] = useState(null);

  const modalimageOpen = (imgId) => {
    setimageModal(!imageModal);
    setSelectedImgId(imgId); // Save the selected image ID to state
  }

  const handleFileChange = async (e, id) => {
    setLoading(true)
    const file = e.target.files[0];

    if (file) {
      // Prepare the form data to send
      const formData = new FormData();
      formData.append('files', file);
      formData.append('id', id); // Include the ID

      try {
        // Make API request
        const response = await OrderAPI.imageUploadForCart(formData);

        if (response) {
          console.log('File uploaded successfully:', response);
          // var isFromOrderTaker = localStorage.getItem("OrderTaker");
          // if (isFromOrderTaker !== "Yes") {
          fetchCartdata()
          // }
          setLoading(false)
        }

        // Handle response or update state as needed
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };


  const DeleteOpen = async (imgId) => {
    try {
      // Make API request
      const response = await OrderAPI.DeleteImage(imgId);
      if (response) {
        // var isFromOrderTaker = localStorage.getItem("OrderTaker");
        // if (isFromOrderTaker !== "Yes") {
        fetchCartdata()
        // }
        setLoading(false)
      }

      // Handle response or update state as needed
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  // const {
  //   RemoveItemInCart,
  //   addingItemInCart,
  // } = props;

  // useEffect(() => {
  //   const getStoredValue = () => {
  //     try {
  //       const value = localStorage.getItem("cartData");
  //       const parsedValue = JSON.parse(value) || value;
  //       setProductList(parsedValue);
  //     } catch (error) {
  //       console.error("Error retrieving value from local storage:", error);
  //     }
  //   };
  //   getStoredValue();
  // }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
    // return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve values from localStorage
      var deliveryTime = localStorage.getItem("deliveryTime");
      //var KotDischargetime = localStorage.getItem("KotDischargetime");
      var deliverydate = localStorage.getItem("deliverydate");

      let delDate = deliverydate;

      const d1 = new Date(deliverydate);
      const currentDate = await getCurrentDate();
      const d2 = new Date(currentDate);

      if (d1 < d2) {
        console.log('Date1 is earlier than Date2');
        localStorage.setItem("deliverydate", currentDate);
        delDate = currentDate;
      } else if (deliverydate || deliverydate == "") {
        console.log('empty date');
        localStorage.setItem("deliverydate", currentDate);
        delDate = currentDate;
      }

      // Update state using setInputValues callback
      if (deliverydate) {
        setInputValues((prevValues) => ({
          ...prevValues,
          deliveryDate: delDate,
        }));
        setOrderData((prevValues) => ({
          ...prevValues,
          deliveryDate: delDate,
        }));
      } else {
        // If deliverydate is not found in localStorage, prepare next three dates
        const today = new Date();
        const nextThreeDates = Array.from({ length: 3 }, (_, index) => {
          const nextDate = new Date(today);
          nextDate.setDate(today.getDate() + index + 1);
          return {
            formattedDate: formatDate(nextDate),
            isoDate: nextDate.toISOString().split("T")[0],
          };
        });

        // Set initial values for deliveryDate in both inputValues and orderData
        setOrderData((prevValues) => ({
          ...prevValues,
          deliveryDate: formatDate(today),
        }));

        setInputValues((prevValues) => ({
          ...prevValues,
          deliveryDate: formatDate(today),
        }));
      }
    }
    fetchData();
  }, []);

  const getCurrentAndNextThreeDates = (serviceType) => {
    const today = new Date();
    const data = serviceType.filter(item => item.id == selectedService)

    const nextThreeDates = Array.from({ length: (data[0]?.advanceOrderDays - 1) }, (_, index) => {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + index + 1);
      return {
        formattedDate: formatDate(nextDate),
        isoDate: nextDate.toISOString().split("T")[0], // Get only the date part
      };
    });

    // setOrderData((prevValues) => ({
    //   ...prevValues,
    //   deliveryDate: formatDate(today),
    // }));

    // setInputValues((prevValues) => ({
    //   ...prevValues,
    //   deliveryDate: formatDate(today),
    // }));
    return [
      {
        formattedDate: formatDate(today),
        isoDate: today.toISOString().split("T")[0], // Get only the date part
      },
      ...nextThreeDates,
    ];
  };
  useEffect(() => {

    setDateOptions(getCurrentAndNextThreeDates(serviceType));

    // const intervalId = setInterval(() => {
    //   setDateOptions(getCurrentAndNextThreeDates());
    // }, 2000);

    // // Cleanup the interval on component unmount
    // return () => clearInterval(intervalId);
  }, [selectedService]);

  useEffect(() => {
    setLoading(true);
    const fetchdata = async () => {
      const data = await Order.getServiceType();
      if (data) {
        setServiceType(data);
        setSelectedServiceID(`${data[0].id}`);
        setInputValues((prevValues) => ({
          ...prevValues,
          serviceTypeId: `${data[0].id}`,
        }));
        setDateOptions(getCurrentAndNextThreeDates(data));
        // let timeDistance;
        // if (loginData) {
        //   const defaultData = await AddAddress.getAddress(loginData.d1Id)
        //   if (defaultData) {
        //     setAddAddresses(defaultData);
        //     const defaultAddress = defaultData.filter(item => item.isDefault == true);
        //     //alert(defaultAddress[0].distance)
        //     if (defaultAddress[0]?.distance != null && defaultAddress[0]?.distance != 0) {
        //       timeDistance = defaultAddress[0]?.distance;
        //     }
        //   }
        // }

        // const updatedValues = {
        //   ...getDeliveryAndTimeSlotId,
        //   orderType: selectedService ? selectedService : `${data[0].id}`,
        //   deliveryPointName: 1,
        //   distance: timeDistance,
        //   isCurrentDate: true
        // };
        // const previousTime = localStorage.getItem("deliveryTime")
        // await fetchAllotedTimeSlot(updatedValues, data);
        // if (previousTime) {
        //   //localStorage.setItem("deliveryTime", previousTime)
        //   setInputValues((prevValues) => ({
        //     ...prevValues,
        //     deliveryTimeId: previousTime,
        //   }));
        //   setOrderData((prevValues) => ({
        //     ...prevValues,
        //     deliveryTimeId: previousTime,
        //   }));
        // }
        setLoading(false);
      } else {
        setLoading(false);
      }
      setLoading(false);
    };
    fetchdata();
  }, []);

  const convertToIsoFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
  };

  const ChangeDeliveryDate = async (e) => {
    const selectedIsoDate = e.target.value;
    setInputValues((prevValues) => ({
      ...prevValues,
      "deliveryDate": selectedIsoDate,
    }));
    localStorage.setItem("deliverydate", selectedIsoDate)

    const currentDate = await getCurrentDate();
    // alert(currentDate == convertToIsoFormat(selectedIsoDate))
    // ****************** fetch time slot **************************
    var isCrntDate;
    if (currentDate == selectedIsoDate)//convertToIsoFormat())
      isCrntDate = true
    else
      isCrntDate = false

    let timeDistance = 0;

    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);

    if (loginData) {
      const defaultData = await AddAddress.getAddress(loginData.d1Id)
      if (defaultData) {
        setAddAddresses(defaultData);
        const defaultAddress = defaultData.filter(item => item.isDefault == true);
        //alert(defaultAddress[0].distance)
        if (defaultAddress && defaultAddress.length > 0 && defaultAddress[0].distance != null && defaultAddress[0].distance != 0) {
          timeDistance = defaultAddress[0].distance;
        }
      }
    }
    else {
      const googleData = localStorage.getItem("googleMapData");
      const distance = JSON.parse(googleData);

      const totaldistance = distance && distance?.rows[0]?.elements[0]?.distance?.text;
      if (totaldistance) {
        const spiltData = totaldistance.split(" ");
        timeDistance = spiltData[0];
      }
    }

    let updatedValues = {
      ...getDeliveryAndTimeSlotId,
      distance: timeDistance,
      orderType: selectedService,
      isCurrentDate: isCrntDate,
    };
    setDeliveryAndTimeSlotId((prevState) => ({
      ...prevState,
      distance: timeDistance,
      orderType: selectedService,
      isCurrentDate: isCrntDate,
    }));
    fetchAllotedTimeSlot(updatedValues);
  };

  const subtract30Minutes = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    date.setMinutes(date.getMinutes() - 30);

    const updatedHours = String(date.getHours()).padStart(2, '0');
    const updatedMinutes = String(date.getMinutes()).padStart(2, '0');
    const updatedSeconds = String(date.getSeconds()).padStart(2, '0');

    return `${updatedHours}:${updatedMinutes}:${updatedSeconds}`;
  }

  const ChangeDeliveryTime = (e) => {
    const val = e.target.value;
    const data = AllottedTimeSlot.filter(item => item.id == val);
    var kotDisTargetTime = "00";
    var deliveryCharge = 0;
    if (data.length > 0) {
      kotDisTargetTime = subtract30Minutes(data[0].availableFrom);
      deliveryCharge = data[0].deliveryCharges
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      deliveryTimeId: val,
      kotDispatchTargetTime: kotDisTargetTime,
      deliveryCharges: deliveryCharge
    }))

    localStorage.setItem("deliveryTime", e.target.value)
    // localStorage.setItem("KotDischargetime", data[0].availableFrom)
  };

  const [AccountData, setAccountData] = useState();

  useEffect(() => {
    // const fetchData = async () => {
    //   var orderno = await OrderAPI.getBillSeriesCounter(1);
    //   setInputValues((prevValues) => ({
    //     ...prevValues,
    //     orderNo: Number(orderno) + 1,
    //     autoOrderNo: Number(orderno) + 1,
    //   }));
    // };
    // fetchData();

    const fetchData1 = async () => {
      const storedData = localStorage.getItem("accountLogin");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        setAccountData(parsedData);
        console.log(parsedData);
        setInputValues((prevValues) => ({
          ...prevValues,
          "d1AccountId": parsedData ? (parsedData.d1Id || "") : "", // Set default value if parsedData is null or d1Id is undefined
          "d2AccountId": parsedData ? (parsedData.d2Id || "") : "", // Set default value if parsedData is null or d2Id is undefined
        }));

      } else {
        // If data is not found in localStorage, set default values or empty string
        setInputValues((prevValues) => ({
          ...prevValues,
          "d1AccountId": "",
          "d2AccountId": "",
        }));
      }
    };
    fetchData1();
  }, []);

  const [formattedDate, setFormattedDate] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const date = await CurrentDateAndTime.getCurrentDateTime()
      setFormattedDate(date)
    }
    fetchData();
  }, [])

  // const currentDate = new Date();
  // const formattedDate = currentDate.toISOString();

  const [tableGroup, setTableGroup] = useState();
  const [, setOrderData] = useState();
  const [tableNumberData, setTableNumberData] = useState();
  const [timeSlot, setTimeSlot] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const d1String = localStorage.getItem("deliverydate");
      const d1 = new Date(d1String);
      const currentDate = await getCurrentDate();
      const d2 = new Date(currentDate);

      if (d1 < d2) {
        console.log('Date1 is earlier than Date2');
        localStorage.setItem("deliverydate", currentDate);
      }
      else if (d1String || d1String == "") {
        console.log('empty date');
        localStorage.setItem("deliverydate", currentDate);
      }
    }
    fetchData();
  }, [])

  const [inputValues, setInputValues] = useState({
    ord1Id: 0,
    orderSeriesId: 1,
    orderNo: "", //  from bill series
    autoOrderNo: "", //  from bill series
    serviceTypeId: 0,
    orderStatus: "Pending", //confirm only for Confirm & KOT
    orderDate: "", // current date
    orderTime: "", //  current time
    orderDoneBy: 1,  // login user Id
    orderDoneOn: "", // current time
    orderConfirmedBy: 1, // 
    orderConfirmedOn: formattedDate, // currentDate
    orderDoneFrom: "Web",
    d1AccountId: 0, // get account details id of the user that login(Customer id)
    d2AccountId: 0, // get account details id of the user that login(Customer id)
    billTypeId: 1, // get the bill type
    tableGroupId: "", // table group id
    tableGroupName: "",
    tableName: "",
    tableId: "", // table id
    noOfPerson: 0,
    noOfAdult: 0,
    noOfChild: 0,
    couponCode: "20", // hard coded
    couponCodeAmount: 0, // we have to devide the total amoun by 20
    deliveryCharges: 0, // from delivery time slot setting 
    deliveryDate: localStorage.getItem("deliverydate"), //  selected date from dropdown
    deliveryTimeId: 825, // the id of the from time slot from miscele
    deliveryStatus: "Pending", // from time slot
    deliveryBoyId: 0, // from time slot
    deliveryBoyDispatchTime: "00:00:00", // from time slot
    deliveryBoyTripNo: 0, // from time slot
    deliveryBoyTripIncentive: 0, // from time slot
    deliveryReferenceNo: 0, // from time slot
    deliveryPreferenceNo: 0, // from time slot
    deliveryTripStatus: "", // from time slot
    orderPrintStatus: 0,
    totalAmt: "", // of cart totalamount
    initialOrderTotalAmt: "",
    grandTotalAmt: "", // total amount
    paymentStatus: "Pending",
    paymentAmount: 0,
    returnAmount: 0,
    discountAmount: 0, // from coupon api
    paymentDoneBy: 0,
    paymentDoneOn: "2024-02-08T06:05:28.900Z", // time of the payment
    paymentAttemptedCounter: 0,
    paymentMode: 0,   //
    paymentBank: "",
    paymentRemarks: "",
    remarks: "",
    orderSuggestion: "", // over all suggestion
    kotDispatchTargetTime: "", // deliveryTime 
    latitude: 0, // from map
    longitude: 0, // from map
    currency: 0, // set default currency\
    hasRunningOrder: "",
    runningOrderCounter: "",
    runningOrderTotalAmt: "",
    deliveryAddress: "",
    name: "",
    mobileNo: "",
    companyGSTNo: "",
    companyDetails: ""
  });
  useEffect(() => {
    const removedata = () => {
      const userArrayString = localStorage.getItem("accountLogin");
      const userArray = userArrayString ? JSON.parse(userArrayString) : null;
      setLoginShow(userArray !== null ? false : true);
      // The following console.log might not show the updated value immediately
      console.log(LoginShow);
    };
    // Call the removedata function when the component mounts
    removedata();
  }, []);

  useEffect(() => {
    calculateTotalAmount();
  }, [cartData]);

  // useEffect(() => {
  //   // Function to get the value from local storage
  //   const getStoredValue = () => {
  //     try {
  //       // Retrieve the value from local storage based on the key
  //       const value = localStorage.getItem("cartData");

  //       // If there's a value, parse it as JSON and set it to the state
  //       if (value) {
  //         const parsedValue = JSON.parse(value);
  //         setProductList(parsedValue);
  //       } else {
  //         // If there's no value, set an empty array or a default value
  //         setProductList([]);
  //       }
  //     } catch (error) {
  //       // Handle errors if any occur during JSON parsing or storage retrieval
  //       console.error("Error retrieving value from local storage:", error);
  //       // Set an empty array or a default value in case of an error
  //       setProductList([]);
  //     }
  //   };

  //   // Call the function to get the value from local storage
  //   getStoredValue();
  // }, []);

  // const addingItemInCart = async (id) => {
  //   handleButtonClick();
  //   const product = cartData.find((item) => item.d2Id === id);
  //   const selectedItem = product;
  //   const currentCount = buttonClickCounts[id] || 0;
  //   setButtonClickCounts({
  //     ...buttonClickCounts,
  //     [id]: currentCount + 1,
  //   });

  //   if (selectedItem) {
  //     const itemIndex = cartData.findIndex((item) => item.d2Id === id);
  //     // console.log(cartData);
  //     if (itemIndex !== -1) {
  //       handleIncrementValue1(id);
  //     } else {
  //       setCartData((prevCartData) => {
  //         const updatedOrderStatus = [...prevCartData];
  //         const updatedItem = {
  //           ...selectedItem,
  //           quantity: 1,
  //           deliveryPreference: 0,
  //         };
  //         updatedOrderStatus.push(updatedItem);
  //         return updatedOrderStatus;
  //       });

  //       setOrderList3({
  //         orderStatus: "Received",
  //         orderDateTime: "2024-02-06T09:54:21.043Z",
  //       });
  //       //  console.log( "tax" ,selectedItem.taxCategory1)

  //       if (
  //         selectedItem.taxCategory1 !== 0 &&
  //         selectedItem.taxCategory1 !== null
  //       ) {
  //         // console.log(quantity * selectedItem.salePrice)
  //         const data = await TaxCategoryApi.getTaxCategoryById(
  //           selectedItem.taxCategory1
  //         );
  //         // console.log(data)
  //         setOrderList([
  //           ...orderList,
  //           {
  //             //ord1Id: order2[0].ord1Id,
  //             // id: id,
  //             godownId: selectedItem.defaultGodown, //will get from Item Master
  //             d1ItemId: selectedItem.d1ItemId, //will get from Item Master
  //             d2ItemId: selectedItem.d2ItemId, //will get from Item Master
  //             qty: selectedItem.quantity,
  //             rate: selectedItem.salePrice,
  //             totalAmt: selectedItem.quantity * selectedItem.salePrice, //selectedItem.quantity
  //             kotStatus: "Received",
  //             preferenceNo: 0, //selectedItem.deliveryPreference,
  //             orderDate: "",
  //             orderTime: "",
  //             itemSuggestion: "",
  //             itemRunningOrderCounter: 0,
  //             orderRunningQty: 0,
  //             itemRunningQty: 0,
  //             taxableAmount: selectedItem.quantity * selectedItem.salePrice,
  //             cgstPer: data.localTaxCGST || 0,
  //             cgstValue:
  //               quantity * selectedItem.salePrice * (data.localTaxCGST / 100),
  //             sgstPer: data.localTaxSGST,
  //             sgstValue:
  //               quantity * selectedItem.salePrice * (data.localTaxSGST / 100),
  //             igstPer: data.centralTaxIGST,
  //             igstValue:
  //               quantity * selectedItem.salePrice * (data.centralTaxIGST / 100),
  //             cessPer: data.cessTax,
  //             cessValue:
  //               quantity * selectedItem.salePrice * (data.cessTax / 100),
  //             totalTaxableAmt: selectedItem.quantity * selectedItem.salePrice,
  //             isLocal: true, //true for table order
  //             isInclusive: false,
  //             hasComplementryItem: selectedItem.addasComplementaryItem,
  //             cookStatus: "",
  //             itemIncentivePoint: selectedItem.salesManItemIncentive,
  //             ord2Id: 0,
  //           },
  //         ]);
  //         // localStorage.setItem("orderList", JSON.stringify(orderList));
  //       } else {
  //         // console.log("else tax category")
  //         // console.log(quantity * selectedItem.salePrice)

  //         setOrderList([
  //           ...orderList,
  //           {
  //             //ord1Id: order2[0].ord1Id,
  //             // id: id,
  //             godownId: selectedItem.defaultGodown, //will get from Item Master
  //             d1ItemId: selectedItem.d1ItemId, //will get from Item Master
  //             d2ItemId: selectedItem.d2ItemId, //will get from Item Master
  //             qty: selectedItem.quantity,
  //             rate: selectedItem.salePrice,
  //             totalAmt: selectedItem.quantity * selectedItem.salePrice, //selectedItem.quantity
  //             kotStatus: "Received",
  //             preferenceNo: 0, //selectedItem.deliveryPreference,
  //             orderDate: "",
  //             orderTime: "",
  //             itemSuggestion: "",
  //             itemRunningOrderCounter: 0,
  //             orderRunningQty: 0,
  //             itemRunningQty: 0,
  //             taxableAmount: selectedItem.quantity * selectedItem.salePrice,
  //             cgstPer: 0,
  //             cgstValue: 0,
  //             sgstPer: 0,
  //             sgstValue: 0,
  //             igstPer: 0,
  //             igstValue: 0,
  //             cessPer: 0,
  //             cessValue: 0,
  //             totalTaxableAmt: quantity * selectedItem.salePrice,
  //             isLocal: false,
  //             isInclusive: false,
  //             hasComplementryItem: false,
  //             cookStatus: "",
  //             itemIncentivePoint: 0,
  //             ord2Id: 0,
  //           },
  //         ]);
  //       }
  //     }
  //     calculateTotalAmount();
  //   }
  // };


  const addingItemInCart = (id) => {
    const currentCount = buttonClickCounts[id] || 0;
    setButtonClickCounts({
      ...buttonClickCounts,
      [id]: currentCount + 1,
    });

    const itemIndex = cartData.findIndex((item) => item.d2Id === id);
    if (cartData[itemIndex].quantity >= 1) {
      if (itemIndex !== -1) {
        const updatedCartData = [...cartData];
        updatedCartData[itemIndex].quantity += 1;
        setCartData(updatedCartData);
        const orderIndex = orderList.findIndex(
          (Order) => Order.d2ItemId === id
        );
        if (orderIndex !== -1) {
          const updatedOrderData = [...orderList];
          updatedOrderData[orderIndex].qty += 1;
          updatedOrderData[orderIndex].totalAmt =
            updatedOrderData[orderIndex].qty * updatedOrderData[orderIndex].rate;
          setOrderList(updatedOrderData);
        }

      }
      // console.log( "dec", cartData)
    }
    calculateTotalAmount();
  }

  const RemoveItemInCart = (id) => {
    const currentCount = buttonClickCounts[id] || 0;
    // Check if currentCount is greater than 0 before decrementing
    if (currentCount > 0) {
      setButtonClickCounts({
        ...buttonClickCounts,
        [id]: currentCount - 1,
      });
    }

    const itemIndex = cartData.findIndex((item) => item.d2Id === id);
    console.log(itemIndex);
    if (itemIndex !== -1) {
      const item = cartData[itemIndex];
      console.log(item.quantity);
      if (("item quantity", item.quantity > 1)) {
        // Decrease quantity by 1

        const updatedCartData = [...cartData];
        updatedCartData[itemIndex].quantity -= 1;
        setCartData(updatedCartData);
        console.log(item.quantity);

        const orderIndex = orderList.findIndex(
          (Order) => Order.d2ItemId === id
        );
        if (orderIndex !== -1) {
          const updatedOrderData = [...orderList];
          updatedOrderData[orderIndex].qty -= 1;
          updatedOrderData[orderIndex].totalAmt =
            updatedOrderData[orderIndex].qty *
            updatedOrderData[orderIndex].rate;
          setOrderList(updatedOrderData);
        }
      } else {
        // Remove item from cart and order list
        console.log(item.quantity);
        const updatedCartData = cartData.filter(
          (item, index) => index !== itemIndex
        );

        setCartData(updatedCartData);

        console.log(item.quantity);

        // Remove item from order list
        const updatedOrderData = orderList.filter(
          (order) => order.d2ItemId !== id
        );
        console.log(updatedOrderData);
        setOrderList(updatedOrderData);

      }
    }
    calculateTotalAmount();
    // console.log(orderList);
  };

  useEffect(() => {
    console.log("this is my cart data", cartData);
  }, [cartData]);

  const [showToast, setShowToast] = useState(false);

  const handleButtonClick = () => {

    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    });
  };

  const deleteCartItemClick = (id) => {
    const updatedCartData = cartData.filter((item) => item.d2Id !== id);
    setCartData(updatedCartData);
  };

  const handleIncrementValue1 = (id) => {
    const itemIndex = cartData.findIndex((item) => item.d2Id === id);
    // console.log(itemIndex);
    if (itemIndex !== -1) {
      const updatedCartData = [...cartData];
      updatedCartData[itemIndex].quantity += 1;
      //updatedCartData[itemIndex].price = (updatedCartData[itemIndex].quantity + 1) * updatedCartData[itemIndex].price;
      setCartData(updatedCartData);
      // **********************************


      // const orderIndex = orderList.findIndex((Order) => Order.d2ItemId === id);
      // console.log(orderIndex);
      // const updatedOrderData = [...orderList];
      // // console.log(updatedOrderData);
      // updatedOrderData[orderIndex].qty += 1;
      // updatedOrderData[orderIndex].totalAmt =
      //   updatedOrderData[orderIndex].qty * updatedOrderData[orderIndex].rate;

      // setOrderList(updatedOrderData);
    }

    calculateTotalAmount();
  };

  const OrderUpdate = (event, id) => {
    const { value } = event.target;
    console.log(`Changing itemSuggestion for d1ItemId ${id} to ${value}`);
    console.log(cartData);
    setCartData((prevCartData) => {
      const updatedItems = prevCartData.map((item) => {
        // Check if the item's d1ItemId matches the one you want to update
        if (item.d1ItemId === id) {
          // Update the 'itemSuggestion' property of the item
          return {
            ...item,
            itemSuggestion: value, // Add or update the 'itemSuggestion' property
          };
        }
        console.log(orderList);
        return item;
      });
      // Update the 'orderList' array in the state
      return updatedItems;
    });
    calculateTotalAmount();
  };

  const saveSuggestion = (e, EcomcartId) => {
    var suggestion = e.target.value
    const filteredData = [
      {
        operationType: 0,
        path: "ItemSuggestion",
        op: "Add",
        from: "string",
        value: suggestion,
      }
    ]
    var data = OrderAPI.UpdateEcomCartPartially(filteredData, EcomcartId)
    if (data) {
      // var isFromOrderTaker = localStorage.getItem("OrderTaker");
      // if (isFromOrderTaker !== "Yes") {
      fetchCartdata()
      // }
    }
  }



  // useEffect(() => {
  //   const storedProductList = localStorage.getItem("cartData");

  //   if (storedProductList) {
  //     const parsedProductList = JSON.parse(storedProductList);
  //     setConstextProduct(parsedProductList);
  //     setproductList(parsedProductList);
  //   }
  //   const orderlist = localStorage.getItem("orderList");
  //   const orderlist3 = localStorage.getItem("orderList3");
  //   if (orderlist) {
  //     const parsedProductList = JSON.parse(orderlist);
  //     setOrderList(parsedProductList);
  //   }
  //   if (orderlist3) {
  //     const parsedProductList = JSON.parse(orderlist3);
  //     setOrderList3(parsedProductList);
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      const localData = localStorage.getItem("OrderTakerId");
      // console.log("localData", localData)
      var data;
      if (localData != null && localData != undefined && localData.length > 0) {
        const allocatedTable = await Table.getTableAllocationById(localData);
        data = allocatedTable && allocatedTable.filter((item) => item.status === "Free");
        // alert("if")
      } else {
        // alert("else")
        data = await Table.getTable();
      }
      console.log("table data", data);
      setTableNumberData(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const OrderTakerId = localStorage.getItem("OrderTakerId");
      const OrderTaker = localStorage.getItem("OrderTaker");
      try {
        let data;
        const allocatedTable = await Table.getTableAllocationById(OrderTakerId);
        const freeTable = allocatedTable && allocatedTable.filter(item => item.status == "Free");
        const tableGroupData = await TableGroupApi.getTableGroup();
        if (OrderTaker == "Yes") {
          data = tableGroupData && tableGroupData.filter(item1 =>
            freeTable && freeTable.some(item2 => item2.tableGroupId === item1.id)
          );
          console.log("tableGroup", data);
        }
        else {
          data = await TableGroupApi.getTableGroup();
        }
        // console.log(data);
        if (data.length) {
          console.log(data);
          setTableGroup(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData();

    const fetchdata1 = async () => {
      const data = await MiscellaneousMaster.GetMiscellaneousMaster(170);
      console.log(data);
      setTimeSlot(data);
    };
    fetchdata1();
  }, []);

  useEffect(() => {
    const gettable = async () => {
      var tableId = localStorage.getItem("Tablechild")
      if (tableId && tableId != 0) {
        // var tableGroup = 0;
        // if (tableId !== 0) {
        //   tableGroup = await Table.getTableById(tableId);
        //   //filterDataFuntn(tableId);
        // }
        const tableData = await Table.getTableById(tableId);
        console.log("table", tableData)

        setInputValues((prevValues) => ({
          ...prevValues,
          "tableId": tableId,
          "tableGroupId": tableData.tableGroupId,
          "tableName": tableData.tableName,
          "tableGroupName": tableData.tableGroup,
        }));

        setOrderData((prevValues) => ({
          ...prevValues,
          "tableId": tableId,
          "tableGroupId": tableData.tableGroupId,
          "tableName": tableData.tableName,
          "tableGroupName": tableData.tableGroup,
        }));
      }
    }
    gettable()
  }, [])

  const onFloorSelect = async (id) => {
    const fieldName = "tableGroupId";
    const operatorName = "Equal";
    const compareValue = id;

    if (id && id != "0") {
      let data = [];
      const OrderTakerId = localStorage.getItem("OrderTakerId");
      if (OrderTakerId) {
        //alert(id)
        const allocatedTable = await Table.getTableAllocationById(OrderTakerId);
        //data = allocatedTable.filter(item => item.status === "Free");
        data = allocatedTable && allocatedTable.filter(item => item.status == "Free" && item.tableGroupId == id);
        //alert(id)
        console.log("tableData 1", allocatedTable)
      }
      else {
        data = await TableApi.getTableFromTableGroup(fieldName, operatorName, compareValue);
      }
      console.log("tableData", data)
      setTableNumberData(data);

      const tableData = await TableGroup.getTableGroupById(id);

      setOrderData((prevValues) => ({
        ...prevValues,
        tableGroupId: id,
        tableGroupName: tableData.name,
        tableId: 0
      }));

      setInputValues((prevValues) => ({
        ...prevValues,
        tableGroupId: id,
        tableGroupName: tableData.name,
        tableId: 0
      }));
    }

    localStorage.setItem("TableGroup", id)
    localStorage.setItem("Tablechild", 0)
  };

  const onTableSelect = async (e) => {
    const tableId = e.target.value;
    //alert(tableId)
    if (tableId && tableId != "0") {
      var tableGroup = 0;
      // if (tableId !== 0) {

      //   tableGroup = await Table.getTableById(tableId);
      //   //filterDataFuntn(tableId);
      // }
      const tableData = await Table.getTableById(tableId);
      console.log("table", tableData)

      setInputValues((prevValues) => ({
        ...prevValues,
        "tableId": tableId,
        "tableGroupId": tableData.tableGroupId,
        "tableName": tableData.tableName,
        "tableGroupName": tableData.tableGroup,
      }));

      setOrderData((prevValues) => ({
        ...prevValues,
        "tableId": tableId,
        "tableGroupId": tableData.tableGroupId,
        "tableName": tableData.tableName,
        "tableGroupName": tableData.tableGroup,
      }));
    }
    localStorage.setItem("Tablechild", tableId)
  };

  // const { onQuantityChange } = props;

  // const handleQuantityChange = (productId, newQuantity) => {
  //   const updatedProductList = productlist.map((product) => {
  //     if (product.d2Id === productId) {
  //       return { ...product, quantity: newQuantity };
  //     }
  //     return product;
  //   });
  //   setproductList(updatedProductList);
  //   if (updatedProductList === undefined) {
  //     console.log("if");
  //     localStorage.setItem("cartData", "");
  //   } else {
  //     localStorage.setItem("cartData", JSON.stringify(updatedProductList));
  //   }
  //   calculateTotalAmount();
  // };

  // const handleIncrementValue = (id) => {
  //   addingItemInCart(id);
  // };

  // const handleDecrementValue = (id) => {
  //   RemoveItemInCart(id);
  //   const currentCount = buttonClickCounts[id] || 0;
  //   setButtonClickCounts({
  //     ...buttonClickCounts,
  //     [id]: currentCount - 1,
  //   });
  //   calculateTotalAmount();
  // };


  const RemoveCart = async (item) => {
    var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    var loginData = JSON.parse(loginDataString); // Now it's an object
    // console.log(loginData)

    const data = {
      d1ItemId: item.d1Id || item.d1ItemId,
      d2ItemId: item.d2Id || item.d2ItemId,
      d1AccountId: loginData.d1Id,
      d2AccountId: loginData.d2Id,
      userId: 0,
      quantity: 0,
      unit: "Pcs",
      itemPrice: item.salePrice || item.itemPrice,
    };
    const isSuccess = await Order.RemoveEcomCartDetail(data);
    if (isSuccess) {
      // var isFromOrderTaker = localStorage.getItem("OrderTaker");
      // if (isFromOrderTaker !== "Yes") {
      fetchCartdata()
      // }
      setInputValues({
        d1ItemId: " ",
        d2ItemId: " ",
        d1AccountId: " ",
        d2AccountId: " ",
        userId: " ",
        quantity: " ",
        unit: " ",
        itemPrice: " ",
      });
      calculateTotalAmount();
    }
  };

  const calculateTotalAmount = () => {
    console.log("this is my cartData list", cartData);
    console.log("this is my orderlist list", orderList);
    // Calculate the total amount of all items in orderList
    const totalAmount = cartData.reduce((total, item) => {
      return total + item.quantity * item.itemPrice;
    }, 0);

    setTotalAmount(totalAmount);

    let grandTotal1 = 0; // Initialize grandTotal1 to 0
    if (RunningOrderData && RunningOrderData[0] && RunningOrderData[0].o1TotalAmt) {
      grandTotal1 = RunningOrderData[0].o1TotalAmt;
    }

    let grandTotal2 = 0; // Initialize grandTotal2

    for (let i = 0; i < cartData.length; i++) {
      // console.log("cartData[i].o2TotalAmt:", cartData[i].z)
      grandTotal2 += cartData[i].itemPrice * cartData[i].quantity || 0;
    }

    console.log("Grand Total 1:", grandTotal1);
    console.log("Grand Total 2:", grandTotal2);

    var GrandTotal = grandTotal1 + grandTotal2;
    // console.log("grandTotal:", GrandTotal);
    console.log("GrandTotal", GrandTotal);
    console.log("totalAmount", totalAmount);

    setTotalamountmain(GrandTotal);
  };

  const filteredProducts = cartData.filter((item) => item.quantity > 0);
  // console.log(filteredProducts);

  const handlePayment = (event) => {
    setPayment(event.target.id);
    setInputValues((prevValues) => ({
      ...prevValues,
      paymentMode: event.target.id,
    }));
  };

  const [getDeliveryAndTimeSlotId, setDeliveryAndTimeSlotId] = useState({
    deliveryPointName: 1,
    orderType: 752,
    distance: 0,
    isCurrentDate: true,
  });

  useEffect(() => {
    if (OrderType == 752) {
      setOrderType("Home delivery");
    } else if (OrderType == 753) {
      setOrderType("Pick Up");
    } else if (OrderType == 751) {
      setOrderType("Table Order");
    } else if (OrderType == undefined) {
      setOrderType("");
      // setSelectedService()
    }
  }, [OrderType]);

  const getCurrentDate = async () => {
    const date = await CurrentDateAndTime.getCurrentDate()
    return date;
  };

  useEffect(() => {
    handleServiceChange();
  }, [])

  const handleServiceChange = async (event) => {
    const newServiceId = Number(event?.target?.id) || Number(selectedService);
    // alert(newServiceId)
    if (newServiceId) {
      setSelectedService(newServiceId);
      setSelectedServiceID(newServiceId);
      localStorage.setItem("ServiceType", newServiceId);
      if (newServiceId == 752) {
        setOrderType("Home delivery");
      } else if (newServiceId == 753) {
        setOrderType("Pick Up");
      } else if (newServiceId == 751) {
        setOrderType("Table Order");
      }
      else if (newServiceId == undefined) {
        setOrderType("");
        // setSelectedService()
      }

      var deliverydate = localStorage.getItem("deliverydate");

      let delDate = deliverydate;

      const d1 = new Date(deliverydate);
      const currentDate = await getCurrentDate();
      const d2 = new Date(currentDate);

      if (d1 < d2) {
        console.log('Date1 is earlier than Date2');
        localStorage.setItem("deliverydate", currentDate);
        delDate = currentDate;
      } else if (deliverydate || deliverydate == "") {
        console.log('empty date');
        localStorage.setItem("deliverydate", currentDate);
        delDate = currentDate;
      }
      // Update state using setInputValues callback
      console.log(selectedService, inputValues.serviceTypeId);
      var isCrntDate;
      if (currentDate === inputValues.deliveryDate)
        isCrntDate = true
      else
        isCrntDate = false


      setInputValues((prevValues) => ({
        ...prevValues,
        deliveryDate: delDate,
        serviceTypeId: newServiceId,
      }));
      setOrderData((prevValues) => ({
        ...prevValues,
        deliveryDate: delDate,
        serviceTypeId: newServiceId,
      }));

      var updatedValues = [];
      if (newServiceId == 752) {
        let timeDistance;
        if (loginData) {
          const defaultData = await AddAddress.getAddress(loginData.d1Id)
          if (defaultData) {
            const defaultAddress = defaultData.filter(item => item.isDefault == true);
            //alert(defaultAddress[0].distance)
            if (defaultAddress && defaultAddress.length > 0 && defaultAddress[0].distance != null && defaultAddress[0].distance != 0) {
              timeDistance = defaultAddress[0].distance;
            }
          }
        }

        if (CompanyDetail.isEnableGoogleMap) {
          if (timeDistance == 0 || timeDistance == null || timeDistance == undefined) {
            alert("The distance is 0. Please select an address from Google Maps");
          }
        }
        updatedValues = {
          ...getDeliveryAndTimeSlotId,
          orderType: event?.target?.id || Number(selectedService),
          deliveryPointName: 1,
          distance: timeDistance,
          isCurrentDate: isCrntDate,
        };
        setDeliveryAndTimeSlotId((prevState) => ({
          ...prevState,
          orderType: event?.target?.id || Number(selectedService),
          deliveryPointName: 1,
          distance: timeDistance,
          isCurrentDate: isCrntDate,
        }));
      } else {
        updatedValues = {
          ...getDeliveryAndTimeSlotId,
          orderType: event?.target?.id || Number(selectedService),
          deliveryPointName: 1,
          distance: 0,
          isCurrentDate: isCrntDate,
        };
        setDeliveryAndTimeSlotId((prevState) => ({
          ...prevState,
          orderType: event?.target?.id || Number(selectedService),
          deliveryPointName: 1,
          distance: 0,
          isCurrentDate: isCrntDate,
        }));

      }
      fetchAllotedTimeSlot(updatedValues);
    }

  };

  const handleAddressChange = (event) => {
    setAddressChange(event.target.id);
  };

  const [addCoupon, setAddCoupon] = useState(false);
  const [addAddress, setAddAddress] = useState(false);

  const opencoupon = () => {
    setAddCoupon(!addCoupon);
    document.body.style.overflow = addCoupon ? "auto" : "hidden";
  };

  const openaddress = () => {
    setAddAddress(!addAddress);
    document.body.style.overflow = addAddress ? "auto" : "hidden";
  };

  const logindone = () => {
    setLoginShow(false);
    myaccountmodal();
    setMobileSection2(false);
    setMobileSection3(false);
    setMobileSection1(true);
  };

  const [mobilesection1, setMobileSection1] = useState(true);
  const [mobilesection2, setMobileSection2] = useState(false);
  const [mobilesection3, setMobileSection3] = useState(false);

  const showchange = () => {
    setMobileSection1(!mobilesection1);
    setMobileSection2(!mobilesection2);
  };

  const showloginsighin = () => {
    setMobileSection1(!mobilesection1);
    setMobileSection3(!mobilesection3);
  };

  const gotofrist = () => {
    setMobileSection1(true);
    setMobileSection3(false);
    setMobileSection2(false);
  };

  useEffect(() => {
    if (mobilesection2 || mobilesection3) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      // Reset body overflow when the component unmounts
      document.body.style.overflow = "auto";
    };
  }, [mobilesection2, mobilesection3]);

  const [fileNames, setFileNames] = useState([]);

  const isMobile = window.innerWidth <= 768;

  const savedata = async (order, order2, order3, LatestCartData) => {
    if (order2.length === 0) {
      setLoading(false);
      alert("Your cart is Empty Add Item First");
      navigate("/OurMenu")
      // } else if (order.paymentMode === "" || order.paymentMode === undefined) {
      //   setLoading(false);
      //   alert("Please select the Payment Mode");
    }
    else if (order.deliveryDate === "" || order.deliveryDate === undefined) {
      setLoading(false);
      alert("Please select the Delivery Date");
      if (isMobile) {
        openTable();
      }
    } else if (
      order.deliveryTimeId === "" ||
      order.deliveryTimeId === undefined
    ) {
      setLoading(false);
      alert("Please select the Delivery Time");
      if (isMobile) {
        openTable();
      }
    } else if (selectedService == 0) {
      alert("Please Select Service Type")
      setLoading(false);
      if (isMobile) {
        showchange()
      }
    }
    else if (selectedService == null && selectedService == undefined) {
      alert("Please select Service Type");
      setLoading(false);
      if (isMobile) {
        showchange()
      }
    }
    else if (selectedService == 753) {
      if (CompanyDetail.isEnablePickUp != true) {
        alert("Pickup Order is  not available")
        return false;
      }
      else {
        createOrder(order, order2, order3, LatestCartData);
      }
    }
    else if (selectedService == 752) {
      if (CompanyDetail.isEnableHomeDelivery != true) {
        alert("Home Delivery is  not available")
        return false;
      }
      // const retrievedArrayString = localStorage.getItem('Address');
      // const retrievedArray = JSON.parse(retrievedArrayString);
      // console.log(retrievedArray);

      const loginDataString = localStorage.getItem("accountLogin"); // This is a string
      const loginData = JSON.parse(loginDataString);

      const retrievedArray = await AddAddress.getAddress(loginData.d1Id);
      // const isAvailableForHomeDel = filteredProducts.every(product => product.availableForHomeDelivery);
      // console.log(filteredProducts);
      // if (!isAvailableForHomeDel) {
      //   alert("Some products are not available for Home Delivery.");
      //   setLoading(false);
      //   return false;
      // }
      if (retrievedArray && retrievedArray.length > 0) {
        // const defaultAddress = retrievedArray[0].isDefault;
        const defaultAddress = retrievedArray.filter(item => item.isDefault == true);
        const firstAddress = defaultAddress[0].address;
        if (defaultAddress && defaultAddress.length > 0) {
          if (firstAddress && typeof firstAddress === 'string' && firstAddress.trim() !== "") {
            createOrder(order, order2, order3, LatestCartData);
          } else {
            alert("Address is not available");
            if (isMobile) {
              openTable();
            }
          }
        }
        else {
          alert("Please select default address");
          setLoading(false);
          if (isMobile) {
            openTable();
          }
        }
      } else {
        alert("Please Add Address");
        setLoading(false);
        if (isMobile) {
          openTable();
        }
      }
    }
    else if (selectedService == 751) {
      if (CompanyDetail.isAllowTblOptionForGuest != true) {
        if (isFromOrderTkr != "Yes") {
          alert("Table Order is not available")
          return false;
        }
      }
      if (order.tableId == 0 || order.tableId == "" || order.tableId === undefined || order.tableId === null || order.tableGroupId == "" || order.tableGroupId === undefined
      ) {
        if (order2[0].ord1Id == null || order2[0].ord1Id == undefined || order2[0].ord1Id == 0 || order2[0].ord1Id == "") {
          setLoading(false);
          alert("Please select the Table");
          if (isMobile) {
            openTable();
          }
          return false;
        }
      }
      const tableStatus = await Table.checkTableStatus(order.tableId);
      if (tableStatus == "Busy") {
        alert("This table is already Busy. Please select another table")
        setLoading(false);
        if (isMobile) {
          openTable();
        }
      }
      else {
        createOrder(order, order2, order3, LatestCartData);
      }
    } else {
      createOrder(order, order2, order3, LatestCartData);
    }
  };

  const createOrder = async (order, order2, order3, LatestCartData) => {
    setLoading(true);
    localStorage.removeItem('Address');
    let saveOrder;
    // for (var i = 0; i <= 10000; i++) {
    saveOrder = await Order.insertOrder(
      order,
      order2,
      order3,
      totalamountmain
    );
    // }

    if (saveOrder) {
      setCartData([]);
      setOrderList([]);
      setOrderList3([]);
      setPayment([]);
      setAddressChange([]);
      setSelectedServiceID([]);
      setSelectedService([]);
      setTotalAmount([0]);
      setButtonClickCounts({})

      // const sendEmail = await EmailAPI.SendEmail(AccountData, saveOrder);
      // // console.log("sendEmail",sendEmail);

      const orderaData1 = saveOrder.order1.orderNo;
      const order1Id = saveOrder.order1.id;
      const d2AccountId = saveOrder.order1.d2AccountId;
      if (orderaData1.message == "Transaction Failed") {
        alert("Order Failed");
        return false;
      }
      alert(`Thanks for Your Order & Your Order no is ${orderaData1}`);
      localStorage.setItem("Tablechild", 0);
      localStorage.setItem("TableGroup", 0);
      // ******************** SMS/MSG **********************************
      messageWhatsAppFunction("on_place_order", saveOrder.order1.id, saveOrder.order1.d1AccountId, (saveOrder.order1.mobileNo || saveOrder.order1.accMobileNo))

      // ****************************************************************
      await fetchItemMaster()
      const filteredData = [{
        operationType: 0,
        path: "tableStatus",
        op: "Add",
        from: "string",
        value: "Busy"
      }]

      if (selectedService == 751)  //inputValues.serviceTypeId
        await Table.updateTablePartially(filteredData, order.tableId);

      const transformedImaged = LatestCartData && LatestCartData.map(item => ({
        CartImageid: item.ecomCartImgId,
        d2ItemId: item.d2ItemId,
      }));

      const transformedOrd2Id = saveOrder.orders2.map(item => ({
        Ord1Id: item.ord1Id,
        Ord2Id: item.id,
        d2ItemId: item.d2ItemId,
      }));


      // Step 1: Create a mapping from d2ItemId to CartImageid
      const d2ItemIdToCartImageId = new Map(transformedImaged && transformedImaged.map(item => [item.d2ItemId, item.CartImageid]));

      // Step 2: Create a new array with the CartImageid included
      const updatedTransformedOrd2Id = transformedOrd2Id && transformedOrd2Id.map(item => ({
        Ord1Id: item.Ord1Id,
        Ord2Id: item.Ord2Id,
        d2ItemId: item.d2ItemId,
        CartImageid: d2ItemIdToCartImageId.get(item.d2ItemId) // Lookup CartImageid using the map
      }));

      console.log(updatedTransformedOrd2Id);

      //       MastId order 2 ki Id
      //       ImgCounter order 1 id 

      async function uploadImages(item) {
        const { CartImageid, Ord2Id, Ord1Id } = item;
        // Check if any of the required fields are null, undefined, or blank
        if (CartImageid || Ord2Id || Ord1Id) {
          try {
            // Prepare data for the API call
            const filteredData = [
              {
                operationType: 0,
                path: "ImgCounter",
                op: "Add",
                from: "string",
                value: Ord1Id,
              },
              {
                operationType: 0,
                path: "MastId",
                op: "Add",
                from: "string",
                value: Ord2Id,
              }
            ];

            // Call the API
            const data = await Order.UpdateImgStorePartially(filteredData, CartImageid);
            console.log(`Upload successful for image ID ${CartImageid}`, data);
          } catch (error) {
            console.error(`Error uploading image ID ${CartImageid}`, error);
          }
        } else {
          console.warn(`Skipping upload for item with missing fields: ${JSON.stringify(item)}`);
        }
      }

      // Loop through updatedTransformedOrd2Id and call uploadImages for each item
      for (const item of updatedTransformedOrd2Id) {
        await uploadImages(item);
      }

      // Iterate through arrayOfObjects to find matches and replace the keys in fileNames
      // const result = Object.keys(fileNames).map(key => ({
      //   [saveOrder.orders2.find(obj => obj.d1ItemId === parseInt(key)).id]: fileNames[key]
      // }));

      // if (result) {
      //   const data = await Order.imageUpload(result)
      // }

      await Order.DeleteEcomCartByAccountId(d2AccountId)
      setLoading(false);
      // var isFromOrderTaker = localStorage.getItem("OrderTaker");
      // if (isFromOrderTaker !== "Yes") {
      await fetchCartdata()
      // }
      // console.log(orderaData1);

      var isFromOrderTaker1 = localStorage.getItem("OrderTaker");
      //alert("isFromOrderTaker1",isFromOrderTaker1);

      // navigate("/TrackOrderStatus");
      if (isFromOrderTaker1 == "Yes") {
        var width = window.screen.width;
        var height = window.screen.height;
        localStorage.setItem("OrderTaker", "No");
        localStorage.removeItem("OrderTakerId");
        localStorage.clear()
        setInputValues({
          orderSeriesId: "",
          orderNo: "", //  from bill series
          autoOrderNo: "", //  from bill series
          serviceTypeId: 0,
          orderStatus: "pending", //confirm only for Confirm & KOT
          orderDate: "", // current date
          orderTime: "", //  current time
          orderDoneBy: "",
          orderDoneOn: "", // current time
          orderConfirmedBy: "",
          orderConfirmedOn: "", // currentDate
          orderDoneFrom: "Website",
          d1AccountId: "", // get account details
          d2AccountId: "", // get account details
          billTypeId: "", // get the bill type
          tableGroupId: "", // table group id
          tableId: 0, // table id
          noOfPerson: "",
          noOfAdult: "",
          noOfChild: "",
          couponCode: "", // hard coded
          couponCodeAmount: "", // we have to devide the total amount by 20
          deliveryCharges: "", // from time slot
          deliveryDate: "", //  from time slot
          deliveryTimeId: "", // from time slot
          deliveryStatus: "", // from time slot
          deliveryBoyId: "", // from time slot
          deliveryBoyDispatchTime: "00:00:00", // from time slot
          deliveryBoyTripNo: "", // from time slot
          deliveryBoyTripIncentive: "", // from time slot
          deliveryReferenceNo: "", // from time slot
          deliveryPreferenceNo: "", // from time slot
          deliveryTripStatus: "", // from time slot
          orderPrintStatus: "",
          totalAmt: totalamountmain, // of cart totalamount
          initialOrderTotalAmt: "",
          grandTotalAmt: "", // total amount
          paymentStatus: "Pending",
          paymentAmount: "",
          returnAmount: "",
          discountAmount: "",
          paymentDoneBy: "",
          paymentDoneOn: "2024-02-08T06:05:28.900Z", // time of the payment
          paymentAttemptedCounter: "",
          paymentMode: "",
          paymentBank: "",
          paymentRemarks: "",
          remarks: "",
          orderSuggestion: "", // over all suggestion
          kotDispatchTargetTime: "", // deliveryTime
          latitude: 0, // from map
          longitude: 0, // from map
          currency: "",
          name: "",
          mobileNo: "",
          companyGSTNo: "",
          companyDetails: "",
        });
        // window.open(`http://198.168.1.67:44483/OrderTakerView`, '_blank', 'width=' + width + ', height=' + height);
        // window.location.href = `http://122.168.117.143:44483/OrderTakerView`, '_blank', 'width=' + width + ', height=' + height;
        window.location.href = `${CompanyDetail.urL2}`; //`http://110.227.221.191:24754/OrderTakerView`;
        setLoading(false);
      }
      else {
        setInputValues({
          orderSeriesId: "",
          orderNo: "", //  from bill series
          autoOrderNo: "", //  from bill series
          serviceTypeId: 0,
          orderStatus: "pending", //confirm only for Confirm & KOT
          orderDate: "", // current date
          orderTime: "", //  current time
          orderDoneBy: "",
          orderDoneOn: "", // current time
          orderConfirmedBy: "",
          orderConfirmedOn: "", // currentDate
          orderDoneFrom: "Website",
          d1AccountId: "", // get account details
          d2AccountId: "", // get account details
          billTypeId: "", // get the bill type
          tableGroupId: "", // table group id
          tableId: "", // table id
          noOfPerson: "",
          noOfAdult: "",
          noOfChild: "",
          couponCode: "", // hard coded
          couponCodeAmount: "", // we have to devide the total amount by 20
          deliveryCharges: "", // from time slot
          deliveryDate: "", //  from time slot
          deliveryTimeId: "", // from time slot
          deliveryStatus: "", // from time slot
          deliveryBoyId: "", // from time slot
          deliveryBoyDispatchTime: "00:00:00", // from time slot
          deliveryBoyTripNo: "", // from time slot
          deliveryBoyTripIncentive: "", // from time slot
          deliveryReferenceNo: "", // from time slot
          deliveryPreferenceNo: "", // from time slot
          deliveryTripStatus: "", // from time slot
          orderPrintStatus: "",
          totalAmt: totalamountmain, // of cart totalamount
          initialOrderTotalAmt: "",
          grandTotalAmt: "", // total amount
          paymentStatus: "Pending",
          paymentAmount: "",
          returnAmount: "",
          discountAmount: "",
          paymentDoneBy: "",
          paymentDoneOn: "2024-02-08T06:05:28.900Z", // time of the payment
          paymentAttemptedCounter: "",
          paymentMode: "",
          paymentBank: "",
          paymentRemarks: "",
          remarks: "",
          orderSuggestion: "", // over all suggestion
          kotDispatchTargetTime: "", // deliveryTime
          latitude: 0, // from map
          longitude: 0, // from map
          currency: "",
          name: "",
          mobileNo: "",
          companyGSTNo: "",
          companyDetails: "",
        });
        navigate("/TrackOrderStatus", { state: { orderNo: orderaData1, ord1Id: order1Id } });
        // alert(`Thanks for Your Order & Your Order no is ${orderaData1}`);
      }
      setInputValues({
        orderSeriesId: "",
        orderNo: "", //  from bill series
        autoOrderNo: "", //  from bill series
        serviceTypeId: 0,
        orderStatus: "pending", //confirm only for Confirm & KOT
        orderDate: "", // current date
        orderTime: "", //  current time
        orderDoneBy: "",
        orderDoneOn: "", // current time
        orderConfirmedBy: "",
        orderConfirmedOn: "", // currentDate
        orderDoneFrom: "Website",
        d1AccountId: "", // get account details
        d2AccountId: "", // get account details
        billTypeId: "", // get the bill type
        tableGroupId: "", // table group id
        tableId: "", // table id
        noOfPerson: "",
        noOfAdult: "",
        noOfChild: "",
        couponCode: "", // hard coded
        couponCodeAmount: "", // we have to devide the total amount by 20
        deliveryCharges: "", // from time slot
        deliveryDate: "", //  from time slot
        deliveryTimeId: "", // from time slot
        deliveryStatus: "", // from time slot
        deliveryBoyId: "", // from time slot
        deliveryBoyDispatchTime: "00:00:00", // from time slot
        deliveryBoyTripNo: "", // from time slot
        deliveryBoyTripIncentive: "", // from time slot
        deliveryReferenceNo: "", // from time slot
        deliveryPreferenceNo: "", // from time slot
        deliveryTripStatus: "", // from time slot
        orderPrintStatus: "",
        totalAmt: totalamountmain, // of cart totalamount
        initialOrderTotalAmt: "",
        grandTotalAmt: "", // total amount
        paymentStatus: "Pending",
        paymentAmount: "",
        returnAmount: "",
        discountAmount: "",
        paymentDoneBy: "",
        paymentDoneOn: "2024-02-08T06:05:28.900Z", // time of the payment
        paymentAttemptedCounter: "",
        paymentMode: "",
        paymentBank: "",
        paymentRemarks: "",
        remarks: "",
        orderSuggestion: "", // over all suggestion
        kotDispatchTargetTime: "", // deliveryTime
        latitude: 0, // from map
        longitude: 0, // from map
        currency: "",
        name: "",
        mobileNo: "",
        companyGSTNo: "",
        companyDetails: "",
      });
      localStorage.removeItem("orderList");
      localStorage.removeItem("itemCount");
      localStorage.removeItem("orderList3");
      localStorage.removeItem("cartNumber");
      localStorage.removeItem("cartData");
      setButtonClickCounts({})
    }
    else {
      setLoading(false);
    }
    setLoading(false);
  };

  var orderList = []; // Initialize an array to hold order data for each item

  const calculateOrderList = () => {
    cartData.forEach(function (item) {
      // Create a new object to hold data for the current item
      var orderData = {
        orderNo: "", // You may need to generate a unique order number
        serviceTypeId: selectedServiceID, // Populate as needed
        godownId: 0, // Populate as needed
        d1ItemId: item.d1ItemId,
        d2ItemId: item.d2ItemId,
        qty: item.quantity,
        rate: item.itemPrice,
        totalAmt: item.quantity * item.itemPrice,
        kotStatus: "Pending", // Populate as needed
        preferenceNo: 0, // Populate as needed
        orderDate: "", // Extracting date from createdOn
        orderTime: "", // Extracting time from createdOn
        cancelQty: 0, // Populate as needed
        cancelAmt: 0, // Populate as needed
        taxableAmount: 0, // Populate as needed
        cgstPer: 0, // Populate as needed
        cgstValue: 0, // Populate as needed
        sgstPer: 0, // Populate as needed
        sgstValue: 0, // Populate as needed
        igstPer: 0, // Populate as needed
        igstValue: 0, // Populate as needed
        cessPer: 0, // Populate as needed
        cessValue: 0, // Populate as needed
        totalTaxableAmt: 0, // Populate as needed
        isLocal: true, // Populate as needed
        isInclusive: true, // Populate as needed
        itemSuggestion: item.itemSuggestion, // Populate as needed
        itemRunningOrderCounter: 0, // Populate as needed
        orderRunningQty: 0, // Populate as needed
        itemRunningQty: 0, // Populate as needed
        hasComplementryItem: true, // Populate as needed
        userOrderImg: 0, // Populate as needed
        cookStatus: "Pending",
        itemIncentivePoint: 0,
        itemDepartmentId: item.itemDepartId,
        preparationTime: item.preparationTimeVal,
        itemName: item.itemName,
        popupCategoryForTable: item.popupCategoryForTable,
        immediateTransferForHD: item.immediateTransferForHD,
        isActive: true
      };
      // Push the orderData object to the order2Data array
      orderList.push(orderData);
    });
  }

  useEffect(() => {
    calculateOrderList()
  }, [cartData])

  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const checkout = async () => {
    setIsButtonVisible(false);
    setTimeout(() => {
      setIsButtonVisible(true);
    }, 3000);

    setLoading(true);
    if (AllottedTimeSlot == undefined || AllottedTimeSlot == null || AllottedTimeSlot.length == 0) {
      if (inputValues.ord1Id == 0) {
        alert("Please select a delivery time or check if the time slot is available for this address.")
        setLoading(false);
        return false;
      }
    }

    debugger
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);

    let LatestCartData;

    // var isFromOrderTaker = localStorage.getItem("OrderTaker");
    // if (isFromOrderTaker !== "Yes") {
    LatestCartData = await Order.getCartList(loginData ? loginData.d2Id || 0 : 0);
    setCartData(LatestCartData);
    // }

    // Function to check if the current time is within a specified range
    function isTimeWithinRange(currentTime, availableTimeFrom, availableTimeTo) {
      // If availableTimeFrom and availableTimeTo are null, item is always available
      if (availableTimeFrom === null && availableTimeTo === null) {
        return true;
      }
      return currentTime >= availableTimeFrom && currentTime <= availableTimeTo;
    }
    // Function to check if any time slot matches the current time
    function isTimeAvailable(item) {
      // const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false }); // Get current time in HH:mm:ss format
      const timeId = localStorage.getItem("deliveryTime");
      const allotedData = AllottedTimeSlot.filter(item => item.id == timeId)
      const currentTime = allotedData && allotedData.length > 0 ? allotedData[0].availableTo : currentTimeData;
      if (item.restoAvailableTime.length == 0) {
        return true
      }
      return item.restoAvailableTime.some(timeSlot =>
        isTimeWithinRange(currentTime, timeSlot.availableTimefrom, timeSlot.availableTimeTo)
      );
    }

    const notAvailableInThisTime = LatestCartData && LatestCartData.filter(item => !isTimeAvailable(item));

    const notAvailableForHomedelivery = LatestCartData && LatestCartData.some(item => !item.availForHD);
    const notAvailableForHomedeliveryItems = LatestCartData && LatestCartData.filter(item => !item.availForHD);
    const notAvailableForHomedeliveryItemname = notAvailableForHomedeliveryItems && notAvailableForHomedeliveryItems.map(item => item.itemName);

    const notAvailableFortableOrder = LatestCartData && LatestCartData.some(item => !item.availForTableOrder);
    const notAvailableFortableOrderdeliveryItems = LatestCartData && LatestCartData.filter(item => !item.availForTableOrder);
    const notAvailableFortableOrderItemname = notAvailableFortableOrderdeliveryItems && notAvailableFortableOrderdeliveryItems.map(item => item.itemName);

    const notAvailableForPickUp = LatestCartData && LatestCartData.some(item => !item.availForPickUp);
    const notAvailableForPickUpdeliveryItems = LatestCartData && LatestCartData.filter(item => !item.availForPickUp);
    const notAvailableForPickUpItemname = notAvailableForPickUpdeliveryItems && notAvailableForPickUpdeliveryItems.map(item => item.itemName);

    const StockCheckItems = LatestCartData && LatestCartData.filter(item => item.stockCheck === 1);
    const StockCheckItemsItemname = StockCheckItems && StockCheckItems.map(item => item.itemName);
    const OutOfStock = StockCheckItems && StockCheckItems.some(item => item.restoAvailableQuantity === 0 || item.quantity > item.restoAvailableQuantity)

    function getItemsNotAvailableInThisTime() {
      return LatestCartData && LatestCartData.filter(item => !isTimeAvailable(item)).map(item => item.itemName);
    }

    function getItemsNotAvailableInThisData() {
      return LatestCartData && LatestCartData.filter(item => !isDateAvailable(item)).map(item => item.itemName);
    }


    async function isDateAvailable(item) {
      const currentDate = localStorage.getItem("deliverydate") || await getCurrentDate();
      const currentDate1 = new Date(currentDate);

      // Handle cases where startDate or endDate is null, undefined, or blank
      const startDate = item.restoAvailableStartDate ? new Date(moment(item.restoAvailableStartDate).format('YYYY-MM-DD')) : null;
      const endDate = item.restoAvailableEndDate ? new Date(moment(item.restoAvailableEndDate).format('YYYY-MM-DD')) : null;

      // If either startDate or endDate is null, return true
      if (startDate === null || endDate === null) {
        return true;
      }
      // Check if the current date falls within the range
      const isAvailable = currentDate1 >= startDate && currentDate1 <= endDate;
      return isAvailable;
    }

    async function getNotAvailableItems(LatestCartData) {
      // Create an array of promises for availability checks
      const availabilityChecks = LatestCartData && LatestCartData.map(async (item) => {
        const available = await isDateAvailable(item);
        return { item, available };
      });
      if (availabilityChecks) {
        // Wait for all promises to resolve
        const results = await Promise.all(availabilityChecks);
        // Filter out items that are not available
        const notAvailableItems = results && results.filter(result => !result.available).map(result => result.item);

        return notAvailableItems;
      }
      return false;
    }

    const notAvailableInThisDate = await getNotAvailableItems(LatestCartData);

    if (notAvailableForHomedelivery && selectedService == 752) {
      alert(`${notAvailableForHomedeliveryItemname.join(', ')} is not available for Home delivery. Please Remove Item.`);
      setLoading(false)
    } else if (notAvailableFortableOrder && selectedService == 751) {
      alert(`${notAvailableFortableOrderItemname.join(', ')} is not available for Table order. Please Remove Item.`);
      setLoading(false)
    } else if (notAvailableForPickUp && selectedService == 753) {
      alert(`${notAvailableForPickUpItemname.join(', ')} is not available for Pick Up. Please Remove Item.`);
      setLoading(false)
    } else if (OutOfStock) {
      alert(`${StockCheckItemsItemname.join(', ')} is Out Of Stock. Please Remove Item.`);
      setLoading(false)
    } else if (notAvailableInThisTime && notAvailableInThisTime.length > 0) {
      alert(`${getItemsNotAvailableInThisTime().join(', ')} is not available on this time. Please Remove Item.`);
      setLoading(false)
    }
    else if (notAvailableInThisDate && notAvailableInThisDate.length > 0) {
      const notAvailableItemNames = notAvailableInThisDate.map(item => item.itemName).join(', ');
      alert(`${notAvailableItemNames}is not available on the selected date. Please remove the item.`);
      setLoading(false)
    }
    // else {
    //   alert("All items are available on the selected date.");
    // }
    else {
      const userLogin = localStorage.getItem("accountLogin");
      const userAddressData = localStorage.getItem("Address");
      const userAddress = userAddressData ? JSON.parse(userAddressData) : null;
      const userArray = userLogin ? JSON.parse(userLogin) : null;
      if (userArray) {
        // setLoading(false);
        // const currentDate1 = new Date();
        // const year = currentDate1.getFullYear();
        // const month = (currentDate1.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        // const day = currentDate1.getDate().toString().padStart(2, "0");
        const date = await CurrentDateAndTime.getCurrentDate()
        const formattedDate = date;

        const currentDateTime = await CurrentDateAndTime.getCurrentDateTime()
        // alert(currentDateTime)
        // const currentDate = new Date().toISOString();
        // const currentTime = new Date().toLocaleTimeString([], {
        //   hour: "2-digit",
        //   minute: "2-digit",
        //   second: "2-digit",
        //   hour12: false,
        // });

        const currentTime = await CurrentDateAndTime.getCurrentTime();
        // alert(`current-time${currentTime}`)
        // ***************************************
        const filteredData = [
          {
            fieldName: "IsDefault",
            operatorName: "Equal",
            compareValue: true,
          },
        ];
        const updatedData = await BillTypeApi.GetFilteredBillType(filteredData);
        const billSeries = await BillSeriesMasterApi.getBillSeriesMasterFiltered(filteredData);
        // const orderNo = await Order.getBillSeriesCounter(billSeries[0].id);

        // ***************************************
        if (!cartData[0]) {
          setLoading(false);
          alert("Please Add Item In Cart");
          navigate("/OurMenu");
        } else {
          // ***************************** Running order ********************************************
          if (RunningOrderData != 0) {
            cartData.forEach(function (item) {
              // Create a new object to hold data for the current item
              var orderData = {
                // orderNo: Number(orderNo), // You may need to generate a unique order number
                serviceTypeId: selectedServiceID, // Populate as needed
                godownId: 0, // Populate as needed
                d1ItemId: item.d1ItemId,
                d2ItemId: item.d2ItemId,
                qty: item.quantity,
                rate: item.itemPrice,
                totalAmt: item.quantity * item.itemPrice,
                kotStatus: "Pending", // Populate as needed
                preferenceNo: 0, // Populate as needed
                orderDate: formattedDate, // Extracting date from createdOn
                orderTime: currentTime, // Extracting time from createdOn
                cancelQty: 0, // Populate as needed
                cancelAmt: 0, // Populate as needed
                taxableAmount: 0, // Populate as needed
                cgstPer: 0, // Populate as needed
                cgstValue: 0, // Populate as needed
                sgstPer: 0, // Populate as needed
                sgstValue: 0, // Populate as needed
                igstPer: 0, // Populate as needed
                igstValue: 0, // Populate as needed
                cessPer: 0, // Populate as needed
                cessValue: 0, // Populate as needed
                totalTaxableAmt: 0, // Populate as needed
                isLocal: true, // Populate as needed
                isInclusive: true, // Populate as needed
                itemSuggestion: item.itemSuggestion, // Populate as needed
                itemRunningOrderCounter: 0, // Populate as needed
                orderRunningQty: 0, // Populate as needed
                itemRunningQty: 0, // Populate as needed
                hasComplementryItem: true, // Populate as needed
                userOrderImg: 0, // Populate as needed
                cookStatus: "pending", // Populate as needed
                itemIncentivePoint: 0, // Populate as needed
                itemDepartmentId: item.itemDepartId, // Populate as needed
                preparationTime: item.preparationTimeVal,
                itemName: item.itemName,
                popupCategoryForTable: item.popupCategoryForTable,
                immediateTransferForHD: item.immediateTransferForHD,
                isActive: true // Populate as needed
              };

              // Push the orderData object to the order2Data array
              orderList.push(orderData);
            });
            // *****************************Running order*******************************************
            const updatedValues2 = RunningOrderData.map((order) => ({
              ...order,
              ord1Id: RunningOrderData[0].ord1Id,
              orderDate: formattedDate,
              orderTime: currentTime,
            }));

            setOrderList((prevValues) =>
              prevValues.map((order) => ({
                ...order,
                "orderDate": formattedDate,
                "orderTime": currentTime,
              }))
            );

            let deliveryAddress;

            if (userAddress.length > 0) {
              const { address, city, locality, pincode } = userAddress[0];
              deliveryAddress = `${address}, ${locality}, ${pincode}, ${city} `;
            }
            const loginUserData = await Account.getAccountById(userArray.d1Id);

            // **************** Get current Location ***************
            // let latitude;
            // let longitude;

            // if (navigator.geolocation) {
            //   navigator.geolocation.getCurrentPosition(
            //     (position) => {
            //       latitude = position.coords.latitude;
            //       longitude = position.coords.longitude;
            //     },
            //     (error) => {
            //       console.log('Error getting location: ' + error.message);
            //     }
            //   );
            // } else {
            //   alert('Geolocation is not supported by this browser.');
            // }
            const updatedValues1 = {
              ...inputValues,
              ord1Id: RunningOrderData[0].ord1Id,
              // "latitude": latitude,
              // "longitude": longitude,
              "orderDate": formattedDate,
              "orderTime": currentTime,
              "orderConfirmedOn": currentDateTime,
              // "deliveryDate": formattedDate,
              "orderDoneOn": currentDateTime,
              // "kotDispatchTargetTime": kotDisTargetTime,
              "totalAmt": totalamountmain,
              "grandTotalAmt": totalamountmain,

              "serviceTypeId": selectedService,
              "deliveryAddress": deliveryAddress,
              "billTypeId": updatedData[0].id,
              "orderSeriesId": billSeries[0].id,
              // "orderNo": (Number(orderNo) + 1),
              // "autoOrderNo": (Number(orderNo) + 1),
              "orderConfirmedBy": loginUserData.item1[0].d1Id,

              name: userArray.name,
              mobileNo: userArray.mobileNo,
              companyGSTNo: userArray.companyGSTNo,
              companyDetails: userArray.companyDetails,
            };
            console.log(updatedValues1);
            console.log(loginUserData);
            console.log(userLogin);

            setInputValues((prevValues) => ({
              ...prevValues,
              ord1Id: RunningOrderData[0].ord1Id,
              "orderDate": formattedDate,
              "orderTime": currentTime,
              "orderConfirmedOn": currentDateTime,
              // "deliveryDate": formattedDate,
              "orderDoneOn": currentDateTime,
              // "kotDispatchTargetTime": kotDisTargetTime,
              totalAmt: totalamountmain,
              grandTotalAmt: totalamountmain,
              serviceTypeId: selectedService,
              billTypeId: updatedData[0].id,
              orderSeriesId: billSeries[0].id,
              // orderNo: Number(orderNo),
              // autoOrderNo: Number(orderNo),
              orderConfirmedBy: userArray.d1Id,
              deliveryAddress: deliveryAddress,
              name: userArray.name,
              mobileNo: userArray.mobileNo,
              companyGSTNo: userArray.companyGSTNo,
              companyDetails: userArray.companyDetails,
            }));

            // ************************** Update Order *********************
            const updatedItems = cartData
              .map((item) => {
                if (item.ord2Id !== 0) {
                  return null;
                } else {
                  return item;
                }
              })
              .filter(Boolean);
            console.log(updatedValues1);
            console.log(updatedValues2);
            console.log(cartData);

            var lengthOfNewOrder = cartData.filter(item => item.ord1Id === 0)

            const updatedOrderValues1 = {
              ...updatedValues1,
              orderDate: formattedDate,
              orderTime: currentTime,
              orderConfirmedOn: currentDateTime,
              // deliveryDate: formattedDate,
              orderDoneOn: currentDateTime,
              // kotDispatchTargetTime: kotDisTargetTime,
              totalAmt: totalamountmain,
              grandTotalAmt: totalamountmain,
              hasRunningOrder: true,
              // runningOrderCounter:
              // runningOrderCounter: (cartData[0].runningOrderCounter ? 0 : cartData[0].runningOrderCounter ) + lengthOfNewOrder.length,
              runningOrderCounter: cartData[0].runningOrderCounter ? 0 : cartData[0].runningOrderCounter,
              runningOrderTotalAmt: totalAmount,
              noOfPerson: RunningOrderData[0].noOfPerson,
              noOfAdult: RunningOrderData[0].noAdultPerson,
              noOfChild: RunningOrderData[0].noChildPerson,
              deliveryDate: RunningOrderData[0].deliveryDate,
            };

            const updatedValues3 = {
              ...orderList3,
              orderDateTime: currentDateTime,
            };
            setOrderList3((prevValues) => ({
              ...prevValues,
              orderDateTime: currentDateTime,
            }));

            if (RunningOrderData[0].ord1Id !== 0 && RunningOrderData[0].ord1Id !== undefined) {
              console.log("update");
              console.log("update", RunningOrderData[0].ord1Id);
              console.log(updatedItems);

              console.log(orderList);
              const updatedValues2 = orderList.map((item) => ({
                ...item,
                itemRunningOrderCounter: (item.itemRunningOrderCounter || 0) + 1, // Correctly increment the counter
                itemRunningQty: item.qty, // Add or update 'itemRunningQty' with the value of 'qty'
                preferenceNo: 100, // Set 'preferenceNo' to 100
              }));

              console.log("updatedvalue", updatedValues2);
              console.log(updatedOrderValues1);
              const order1Id = RunningOrderData[0].ord1Id;

              const success = await Order.updateOrder(
                updatedOrderValues1,
                updatedValues2,
                updatedValues3,
                order1Id,
                totalamountmain,
                RunningOrderData
              );
              // var width = window.screen.width;
              // var height = window.screen.height;

              if (success === true) {
                alert("Your Order has been Updated");
                await Order.DeleteEcomCartByAccountId(AccountData.d2Id)
                // var isFromOrderTaker = localStorage.getItem("OrderTaker");
                // if (isFromOrderTaker !== "Yes") {
                await fetchCartdata()
                // }
                // await fetchItemMaster()
                setLoading(true);
                setTotalAmount([0]);
                setButtonClickCounts({});
                localStorage.removeItem("orderList");
                localStorage.removeItem("itemCount");
                localStorage.removeItem("orderList3");
                localStorage.removeItem("cartNumber");
                localStorage.removeItem("cartData");
                localStorage.clear()
                // window.open(`http://122.168.117.143:44483/OrderTakerView`, '_blank', 'width=' + width + ', height=' + height);
                window.location.href = `${CompanyDetail.urL2}` //`http://110.227.221.191:24754/OrderTakerView`;
                setLoading(false);
                //alert("close")
                // window.open("http://122.168.117.143:44483/OrderTakerView");
                //window.close()
              }
            }
          }
          else {
            console.log(orderList);
            // ***************************** Regular order *********************************************
            console.log("Initial cartData length:", cartData.length);
            console.log("Initial orderList length:", orderList.length);

            cartData.forEach(function (item, index) {
              console.log("Processing item index:", index, "Current orderList length:", orderList.length);

              var orderData = {
                serviceTypeId: selectedServiceID,
                godownId: 0,
                d1ItemId: item.d1ItemId,
                d2ItemId: item.d2ItemId,
                qty: item.quantity,
                rate: item.itemPrice,
                totalAmt: item.quantity * item.itemPrice,
                kotStatus: "Pending",
                preferenceNo: 0,
                orderDate: formattedDate,
                orderTime: currentTime,
                cancelQty: 0,
                cancelAmt: 0,
                taxableAmount: 0,
                cgstPer: 0,
                cgstValue: 0,
                sgstPer: 0,
                sgstValue: 0,
                igstPer: 0,
                igstValue: 0,
                cessPer: 0,
                cessValue: 0,
                totalTaxableAmt: 0,
                isLocal: true,
                isInclusive: true,
                itemSuggestion: item.itemSuggestion,
                itemRunningOrderCounter: 0,
                orderRunningQty: 0,
                itemRunningQty: 0,
                hasComplementryItem: true,
                userOrderImg: 0,
                cookStatus: "pending",
                itemIncentivePoint: 0,
                itemDepartmentId: item.itemDepartId,
                preparationTime: item.preparationTimeVal,
                itemName: item.itemName,
                popupCategoryForTable: item.popupCategoryForTable,
                immediateTransferForHD: item.immediateTransferForHD,
                isActive: true
              };
              // Find the index of the item if it already exists in the orderList
              var existingItemIndex = orderList.findIndex(order => order.d1ItemId === item.d1ItemId && order.d2ItemId === item.d2ItemId);

              if (existingItemIndex !== -1) {
                // If item exists, update the existing orderData
                orderList[existingItemIndex] = orderData;
                console.log("Updated orderData for item index:", index, "at orderList index:", existingItemIndex);
              } else {
                // If item does not exist, push the new orderData
                orderList.push(orderData);
                console.log("Added orderData for item index:", index, "New orderList length:", orderList.length);
              }
            });
            console.log("Final orderList length:", orderList.length);
            console.log("Final orderList length:", orderList.length);
            // console.log(orderList);

            const updatedValues2 = orderList.map((order) => ({
              ...order,
              orderDate: formattedDate,
              orderTime: currentTime,
            }));

            let address, city, locality, pincode, name, mobile, latitude, longitude;

            if (userAddress != null && userAddress !== undefined && userAddress.length > 0) {
              ({ name, mobile, address, city, locality, pincode, latitude, longitude } = userAddress[0]);
            }
            const deliveryAddress = `${address}, ${locality}, ${pincode}, ${city}, ${name}, ${mobile}`//,${latitude},${longitude}

            const data = AllottedTimeSlot.filter(item => item.id == inputValues.deliveryTimeId);
            console.log(AllottedTimeSlot)
            var kotDisTargetTime = "00";
            if (data.length > 0) {
              kotDisTargetTime = subtract30Minutes(data[0].availableFrom);
              //alert(data[0].availableFrom);
            }

            let updatedValues1;

            if (selectedService == 752) {
              if (data && data.length > 0) {
                const minValue = data[0].minDelvryAmtInCart
                if (Number(totalamountmain) < Number(minValue)) {
                  alert(`Please add items to reach the minimum order amount of Rs ${minValue}.`)
                  setLoading(false)
                  return false;
                }
              }

              updatedValues1 = {
                ...inputValues,
                orderDate: formattedDate,
                orderTime: currentTime,
                orderConfirmedOn: currentDateTime,
                // deliveryDate: formattedDate,
                orderDoneOn: currentDateTime,
                totalAmt: Number(totalamountmain) + (Number(AllottedTimeSlot[0]?.deliveryCharges) || 0),
                grandTotalAmt: Number(totalamountmain) + (Number(AllottedTimeSlot[0]?.deliveryCharges) || 0),
                serviceTypeId: selectedService,
                kotDispatchTargetTime: kotDisTargetTime || "00:00:00",
                billTypeId: updatedData[0].id,
                orderSeriesId: billSeries[0].id,
                // orderNo: Number(orderNo) + 1,
                // autoOrderNo: Number(orderNo) + 1,
                orderConfirmedBy: userArray.d1Id,
                deliveryAddress: deliveryAddress,
                name: name,
                mobileNo: mobile,
                companyGSTNo: userArray.companyGSTNo,
                companyDetails: userArray.companyDetails,
                latitude: latitude,
                longitude: longitude,
                deliveryCharges: AllottedTimeSlot[0]?.deliveryCharges,
                tableGroupId: 0,
                tableId: 0,
              };
            }
            else {
              updatedValues1 = {
                ...inputValues,
                orderDate: formattedDate,
                orderTime: currentTime,
                orderConfirmedOn: currentDateTime,
                // deliveryDate: formattedDate,
                orderDoneOn: currentDateTime,
                totalAmt: totalamountmain,
                grandTotalAmt: totalamountmain,
                kotDispatchTargetTime: kotDisTargetTime || "00:00:00",
                serviceTypeId: selectedService,
                billTypeId: updatedData[0].id,
                orderSeriesId: billSeries[0].id,
                // orderNo: Number(orderNo) + 1,
                // autoOrderNo: Number(orderNo) + 1,
                orderConfirmedBy: userArray.d1Id,
                deliveryAddress: deliveryAddress,
                name: userArray.name,
                mobileNo: userArray.mobileNo,
                companyGSTNo: userArray.companyGSTNo,
                companyDetails: userArray.companyDetails,
                latitude: latitude,
                longitude: longitude,
                deliveryCharges: AllottedTimeSlot[0]?.deliveryCharges,
              };
            }

            setInputValues((prevValues) => ({
              ...prevValues,
              orderDate: formattedDate,
              orderTime: currentTime,
              orderConfirmedOn: currentDateTime,
              // deliveryDate: formattedDate,
              orderDoneOn: currentDateTime,
              totalAmt: totalamountmain,
              grandTotalAmt: totalamountmain,
              kotDispatchTargetTime: kotDisTargetTime || "00:00:00",
              serviceTypeId: selectedService,
              billTypeId: updatedData[0].id,
              orderSeriesId: billSeries[0].id,
              // orderNo: Number(orderNo) + 1,
              // autoOrderNo: Number(orderNo) + 1,
              orderConfirmedBy: userArray.d1Id,
              deliveryAddress: deliveryAddress,
              name: userArray.name,
              mobileNo: userArray.mobileNo,
              companyGSTNo: userArray.companyGSTNo,
              companyDetails: userArray.companyDetails,
              latitude: latitude,
              longitude: longitude,
              deliveryCharges: AllottedTimeSlot[0]?.deliveryCharges,
            }));

            setCartData((prevData) =>
              prevData.map((order) => ({
                ...order,
                orderDate: formattedDate,
                orderTime: currentTime,
              }))
            );

            const updatedValues3 = {
              ...orderList3,
              orderDateTime: currentDateTime,
            };
            setOrderList3((prevValues) => ({
              ...prevValues,
              orderDateTime: currentDateTime,
            }));

            console.log("updatedValues1", updatedValues1);
            console.log("insert");
            savedata(updatedValues1, updatedValues2, updatedValues3, LatestCartData);
          }
        }
      } else {
        setLoading(false);
        myaccountmodal();
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    console.log("cartData");
    console.log(cartData);
    if (RunningOrderData && RunningOrderData[0]) {
      // cartData exists, has at least one element, and RunningOrderData has at least one element
      onFloorSelect(RunningOrderData[0].tableGroupId || 0);
    } else {
      // Handle the case when cartData or RunningOrderData doesn't have any value
    }
    if (RunningOrderData && RunningOrderData[0]) {
      console.log("deliveryDate", formattedDate);
      setOrderData((prevValues) => ({
        ...prevValues,
        tableId: RunningOrderData[0].tableId || 0,
        tableGroupId: RunningOrderData[0].tableGroupId || 0,
        deliveryTimeId: RunningOrderData[0].deliveryTimeId || 0,
        deliveryDate: RunningOrderData[0].deliveryDate,
      }));
      //  setTableGroup(RunningOrderData[0].tableGroupId)

      setInputValues((prevValues) => ({
        ...prevValues,
        tableId: RunningOrderData[0].tableId,
        tableGroupId: RunningOrderData[0].tableGroupId,
        serviceTypeId: RunningOrderData[0].serviceTypeId,
        deliveryTimeId: RunningOrderData[0].deliveryTimeId,
        deliveryDate: RunningOrderData[0].deliveryDate,
      }));
      //console.log("cartData.serviceTypeId:" + RunningOrderData[0].serviceTypeId);

      //if (cartData && cartData.length > 0) {
      setSelectedService(RunningOrderData[0].serviceTypeId);
      setSelectedServiceID(RunningOrderData[0].serviceTypeId);
    }
  }, [])

  // **********************************
  const fetchAllotedTimeSlot = async (updatedValues, serviceTypeData) => {
    try {
      setLoading(true);
      const deliverySlot = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues, CompanyDetail.isEnableGoogleMap);
      console.log("selectedTimeSlot", deliverySlot);
      let data = []
      if (deliverySlot && deliverySlot.length > 0) {
        data = deliverySlot.filter((data, index, self) =>
          index === self.findIndex((t) => (
            t.id === data.id
          ))
        );
      }
      const serviceTypeId = updatedValues.orderType;
      const serviceData = (serviceTypeData != null && serviceTypeData != undefined && serviceTypeData.length > 0) ? serviceTypeData.filter(item => item.id == serviceTypeId) : serviceType.filter(item => item.id == serviceTypeId)
      const selectedTimeSlot = data.slice(0, serviceData[0]?.noOfTimeSlot);

      if (selectedTimeSlot.length > 0) {
        localStorage.setItem("deliveryTime", selectedTimeSlot[0]?.id)
      }

      console.log("selectedTimeSlot", selectedTimeSlot)
      console.log("selectedTimeSlot", updatedValues.orderType, serviceData[0]?.noOfTimeSlot)

      if (data) {
        setAllottedTimeSlot(selectedTimeSlot);
      } else {
        setAllottedTimeSlot("");
        //alert("No data found");
      }
      setLoading(false)

      var kotDisTargetTime = "00";
      if (selectedTimeSlot.length > 0) {
        kotDisTargetTime = subtract30Minutes(selectedTimeSlot[0].availableFrom);
      }
      setOrderData((prevValues) => ({
        ...prevValues,
        deliveryTimeId: data && data[0] && data[0].id !== undefined ? data[0].id : "",
        kotDispatchTargetTime: kotDisTargetTime || "00:00:00",
        deliveryCharges: selectedTimeSlot[0]?.deliveryCharges,
      }));

      //setTableGroup(cartData[0].tableGroupId)
      setInputValues((prevValues) => ({
        ...prevValues,
        serviceTypeId: serviceTypeId,
        deliveryTimeId: data && data[0] && data[0].id !== undefined ? data[0].id : "",
        kotDispatchTargetTime: kotDisTargetTime || "00:00:00",
        deliveryCharges: selectedTimeSlot[0]?.deliveryCharges,
      }));

    } catch (error) {
      setLoading(false)
      console.error("Error fetching time slots:", error);
    }
  }

  const [OpenTableSelection, setOpenTableSelection] = useState(false);
  const [OpenPaymentSelection, setPaymentSelection] = useState(false);

  const GoToPayment = () => {
    setOpenTableSelection(false);
    openPayment();
  };
  const openTable = () => {
    setOpenTableSelection(true);
  };
  const CloseTable = () => {
    setOpenTableSelection(false);
  };
  const openPayment = () => {
    setPaymentSelection(true);
  };
  const ClosePayment = () => {
    setPaymentSelection(false);
  };
  const ourMenuClick = () => {
    navigate("/OurMenu");
  }

  const testing = async () => {
    const smsData = await SmsTemplate.GetAssignTemplatesByName("order Done", 10743, 5055)

    const sendData = {
      to: "8109833688",
      templateName: smsData[0]?.templateForWhatsappAPI,
      templateLanguage: smsData[0]?.whatsappLanguage,
      headerSection: smsData[0]?.messageHeaderSection_,
      bodySection: smsData[0]?.messageBodySection_,
      fileMediaId: ""
    }

    const data = await SMSAPI.SendWhatsApp(sendData)

    // const sendData = {
    //   to: "8109833688",
    //   message: smsData[0]?.templateForFormDetails,
    //   smsTempAttr: smsData[0]?.templateForSMSAPI,
    //   smsTempVal: smsData[0]?.templateId,
    // }
    // const sendSMS = await SMSAPI.SendSMS(sendData);
  }

  return (
    <div className="cart-page">
      <div className="cartpage-left-section">
        {/* <button onClick={testing}>Testing</button> */}
        {LoginShow && (
          <div className="cartpage-right-section-2">
            <label style={{ border: "0" }} className="title dis-fl-fl-co">
              Account
            </label>
            <p
              style={{ margin: "0", fontWeight: "600", color: "gray" }}
              className="f-12"
            >
              To place your order now, log in to your existing account or sign
              up.
            </p>
            <div className="cartpage-service-options-box-1">
              <div
                className="cartpage-service-options-2"
                onClick={myaccountmodal}
              >
                <p style={{ margin: "0" }} className="f-14">
                  Have an account?
                </p>
                <p style={{ margin: "0" }} className="f-15">
                  LOG IN
                  {isFromOrderTaker}
                </p>
              </div>
              {isFromOrderTaker ? (<></>) : (
                <div
                  onClick={myaccountmodal1}
                  className="cartpage-service-options-2"
                >
                  <p style={{ margin: "0" }} className="f-14">
                    New to Mother's hut
                  </p>
                  <p style={{ margin: "0" }} className="f-15">
                    SIGN UP
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        {RemoveOnMobile && (
          <div className="cartpage-right-section-1" id="service-type-in-mycart">
            <label style={{ border: "0" }} className="title dis-fl-fl-co">
              Service type
            </label>
            <div
              className="cartpage-service-options-box"
              style={{ paddingTop: "0" }}
            >
              {/* {CompanyDetail.isEnableHomeDelivery === true ? ( */}
              <div
                className="cartpage-service-options"
                onClick={() => handleServiceChange({ target: { id: `${serviceType[1]?.id}` } },)}>
                <div className="cartoage-input-box">
                  <input
                    type="radio"
                    name="serviceType"
                    value={inputValues.serviceTypeId}
                    checked={serviceType[1]?.id == selectedService}
                    onClick={() =>
                      handleServiceChange({
                        target: { id: `${serviceType[1]?.id}` },
                      })
                    }
                  />
                  <div>
                    <p style={{ margin: "0" }} className="f-14">
                      {serviceType.length > 0 &&
                        <>{serviceType[1]?.name}</>}{" "}
                      {/* Render name if serviceType is available */}
                      <i
                        style={{ marginLeft: "5px" }}
                        className="fa-solid fa-house"
                      ></i>
                    </p>
                    <p className="service-type-paragraph">
                      Delivery amount must be Rs 0 to deliver items
                    </p>
                  </div>
                </div>
              </div>
              {/* ) : null} */}

              {/* {CompanyDetail.isEnablePickUp === true ? ( */}
              <div
                className="cartpage-service-options"
                onClick={() => handleServiceChange({ target: { id: serviceType[2]?.id } })}>
                <div className="cartoage-input-box">
                  <input
                    type="radio"
                    name="serviceType"
                    value={inputValues.serviceTypeId}
                    checked={serviceType[2]?.id == selectedService}
                    onClick={() =>
                      handleServiceChange({ target: { id: `${serviceType[2]?.id}` }, })} />
                  <div>
                    <p style={{ margin: "0" }} className="f-14">
                      {/* {serviceType[2].name} */}
                      {serviceType.length > 0 && <>{serviceType[2]?.name}</>}
                      <i style={{ marginLeft: "5px" }} className="fa-solid fa-car" ></i>
                    </p>
                    <p className="service-type-paragraph">
                      You will pick up the order yourself at the restaurant
                    </p>
                  </div>
                </div>
              </div>
              {/* ) : null} */}

              {/* /{CompanyDetail.isAllowTblOptionForGuest === true || isFromOrderTkr === "Yes" ? ( */}
              <div
                className="cartpage-service-options"
                onClick={() => handleServiceChange({ target: { id: `${serviceType[0]?.id}` }, })}>
                <div className="cartoage-input-box">
                  <input
                    type="radio"
                    name="serviceType"
                    value={inputValues.serviceTypeId}
                    checked={serviceType[0]?.id == selectedService}
                    onClick={() =>
                      handleServiceChange({ target: { id: `${serviceType[0]?.id}` }, })} />
                  <div>
                    <p style={{ margin: "0" }} className="f-14">
                      {/* {serviceType[0].name} */}
                      {serviceType.length > 0 && <>{serviceType[0]?.name}</>}
                      <i
                        style={{ marginLeft: "5px" }}
                        className="fa-solid fa-couch"
                      ></i>
                    </p>
                    <p className="service-type-paragraph">
                      Your order will be delivered to your table
                    </p>
                  </div>
                </div>
              </div>
              {/* ) : null} */}
            </div>
          </div>
        )}
        {RemoveOnMobile && (
          <TableAndAddress
            AllottedTimeSlot={AllottedTimeSlot}
            dateOptions={dateOptions}
            serviceType={serviceType}
            inputValues={inputValues}
            openaddress={openaddress}
            ChangeDeliveryDate={ChangeDeliveryDate}
            CompanyDetail={CompanyDetail}
            isInputDisabled={isInputDisabled}
            tableGroup={tableGroup}
            tableNumberData={tableNumberData}
            onTableSelect={onTableSelect}
            onFloorSelect={onFloorSelect}
            ChangeDeliveryTime={ChangeDeliveryTime}
            totalAmount={totalAmount}
            setInputValues={setInputValues}
            selectedService={selectedService}
            setOrderData={setOrderData}
            getDeliveryAndTimeSlotId={getDeliveryAndTimeSlotId}
            setAllotedTimeSlot={fetchAllotedTimeSlot}
            setDeliveryAndTimeSlotId={setDeliveryAndTimeSlotId}
          />
        )}
        {RemoveOnMobile && (
          <PaymentModeSelection
            handlePayment={handlePayment}
            cod={cod}
            pod={pod}
            payment={payment}
          />
        )}
      </div>
      <div className="cartpage-right-section">
        <div className="cartpage-mastar-container">
          <div className="card-cart cart w-95 mycart-itemcontainer">
            <label
              className="title"
              style={{ borderBottom: "1px solid #e5e5e5" }}
            >
              Your cart
            </label>

            {filteredProducts.length === 0 ? (
              <div className="mycart-empty-txt-box-1">
                <img
                  className="mycart-empty-image"
                  src="https://mothershut.com/RestoFolders/MOTHERSHUT_Supela_Bhilai/Cart_empty.png"
                  alt=""
                />
                <p className="mycart-empty-txt" onClick={ourMenuClick}>Your Order cart is empty.</p>
                <p className="mycart-empty-txt" onClick={ourMenuClick}>Click to add an item.</p>
                <p className="mycart-empty-txt-2">
                  Cooked by mothers with love and care
                </p>
              </div>
            ) : (
              filteredProducts.map((product) => (
                //console.log(filteredProducts),
                <div
                  key={product.id}
                  style={{ padding: "5px" }}
                  className="products"
                >
                  <div className="product">
                    <img
                      className="image-for-big"
                      src={`${imageBaseUrl}${product.itemImgId}`}
                      alt=""
                    />
                    <div className="products-cart-box-1">
                      <div className="mycart-productname">
                        <span style={{ fontSize: "13px" }} className="f-12">
                          {product.itemName}
                        </span>
                        <p className="menu-card-detail f-11"> <i
                          style={{ marginRight: "3px", marginTop: "2px" }}
                          className="fa-solid fa-indian-rupee-sign f-11"
                        ></i> {product.itemPrice}</p>
                      </div>

                      {/* {loginData ? ( */}
                      <div className="dfjcc">
                        <input
                          className="mycart-suggestion-box"
                          type="text"
                          placeholder="Your suggestion"
                          onChange={(e) => OrderUpdate(e, product.d1ItemId)}
                          onBlur={(e) => saveSuggestion(e, product.id)}
                          value={product.itemSuggestion}
                          name="itemSuggestion"
                        />

                        {product.ecomCartImgId ?
                          <label
                            className="mycart-file-box"
                            htmlFor={`file-input-${product.d1ItemId}`}
                            style={{ cursor: "pointer" }}
                          >
                            Change Image
                            <button style={{ fontSize: "12px" }} onClick={(e) => modalimageOpen(product.ecomCartImgId)} ><i class="fa-regular fa-pen-to-square"></i></button>
                            <button style={{ fontSize: "12px" }} onClick={(e) => DeleteOpen(product.ecomCartImgId)} ><i style={{ color: "red" }} class="fa-solid fa-trash"></i></button>
                          </label>

                          :
                          <label
                            className="mycart-file-box"
                            htmlFor={`file-input-${product.d1ItemId}`}
                            style={{ cursor: "pointer" }}
                          >
                            Click to add image
                          </label>
                        }
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id={`file-input-${product.d1ItemId}`}
                          accept="image/*"
                          onChange={(e) => handleFileChange(e, product.id)}
                        />

                        {/* <img src={`${imageBaseUrl}${product.ecomCartImgId}`} style={{ width: "40px", height: "40px" }} alt="" /> */}
                      </div>
                      {/* ) : (<div className="dfjcc"></div>)} */}

                      {serviceType && serviceType.length > 0 && serviceType[1]?.id != undefined &&
                        (selectedService == `${serviceType[1]?.id}`) ? (
                        ((
                          <div className="mycart-quantitybox">
                            <div className="quantityintosalepricemycart" style={{ width: "100%" }}>
                              {/* {productlist.find(item => item.d1Id === product.d1ItemId) ? (
                                productlist
                                  .filter(item => item.d1Id === product.d1ItemId)
                                  .map(item => ( */}
                              <React.Fragment key={product.d1Id}>
                                {(product.availForHD === false || (product.restoAvailableQuantity === 0 && product.stockCheck === 1)) ? (
                                  <>
                                    {product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                                      <p style={{ textAlign: "center" }} className="mycart-productnotavli">&nbsp;&nbsp;Out Of Stock</p>
                                    ) : <p style={{ textAlign: "center" }} className="mycart-productnotavli">
                                      Not Available for Home delivery
                                    </p>}
                                    <div>
                                      <i
                                        className="fa-solid fa-trash-can mycart-deletebtn"
                                        id="HomedeiveryIdButton"
                                        onClick={() => RemoveCart(product)}
                                      ></i>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="quantity"
                                      style={{ width: "max-content" }} // Corrected "max-width" to "max-content"
                                    >

                                      <button
                                        style={{ paddingBottom: "4px" }}
                                        className="quantity__minus"
                                        onClick={() => RemoveFromCart(product)}
                                      >
                                        -
                                      </button>
                                      <input
                                        className="quantity__input"
                                        type="text"
                                        value={product.quantity}
                                        readOnly
                                      />
                                      <button
                                        className="quantity__plus"
                                        onClick={() => AddToCart(product)}
                                      >
                                        +
                                      </button>
                                      {!RemoveOnMobile && (
                                        <>
                                          <label style={{ fontSize: "12px" }}>
                                            &nbsp;x&nbsp;
                                          </label>
                                          <i
                                            style={{ marginRight: "3px", marginTop: "2px" }}
                                            className="fa-solid fa-indian-rupee-sign f-11"
                                          ></i>
                                          <label
                                            htmlFor=""
                                            style={{ color: "#515151" }}
                                            className="price small f-10"
                                          >
                                            {product.itemPrice}
                                          </label>

                                        </>

                                      )}
                                    </div>
                                    <label
                                      className="price small f-13"
                                      style={{ width: "50px", textAlign: "center" }}
                                    >
                                      <i
                                        style={{ marginRight: "3px" }}
                                        className="fa-solid fa-indian-rupee-sign"
                                      ></i>
                                      {product.itemPrice * product.quantity}
                                    </label>
                                  </>
                                )}
                              </React.Fragment>
                              {/* ))
                              ) : (
                                // Render something else if productlist.find(...) doesn't return an array
                                <></>
                              )} */}
                            </div>
                          </div>
                        ))
                      )
                        :
                        serviceType && serviceType.length > 0 && serviceType[0]?.id != undefined &&
                          (selectedService == `${serviceType[0]?.id}`) ? (
                          <div className="mycart-quantitybox">
                            <div className="quantityintosalepricemycart" style={{ width: "100%" }}>
                              {/* {productlist.find(item => item.d1Id === product.d1ItemId) ? (
                                productlist
                                  .filter(item => item.d1Id === product.d1ItemId)
                                  .map(item => ( */}
                              <React.Fragment key={product.d1Id}>
                                {(product.availForTableOrder === false || (product.restoAvailableQuantity === 0 && product.stockCheck === 1)) ? (
                                  <>
                                    {product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                                      <p style={{ textAlign: "center" }} className="mycart-productnotavli">&nbsp;&nbsp;Out Of Stock</p>
                                    ) : <p style={{ textAlign: "center" }} className="mycart-productnotavli">
                                      Not Available for Table Order
                                    </p>}

                                    <div>
                                      <i
                                        className="fa-solid fa-trash-can mycart-deletebtn"
                                        id="HomedeiveryIdButton"
                                        onClick={() => RemoveCart(product)}
                                      ></i>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="quantity"
                                      style={{ width: "max-content" }} // Corrected "max-width" to "max-content"
                                    >

                                      <button
                                        style={{ paddingBottom: "4px" }}
                                        className="quantity__minus"
                                        onClick={() => RemoveFromCart(product)}
                                      >
                                        -
                                      </button>
                                      <input
                                        className="quantity__input"
                                        type="text"
                                        value={product.quantity}
                                        readOnly
                                      />
                                      <button
                                        className="quantity__plus"
                                        onClick={() => AddToCart(product)}
                                      >
                                        +
                                      </button>
                                      {!RemoveOnMobile && (
                                        <>
                                          <label style={{ fontSize: "12px" }}>
                                            &nbsp;x&nbsp;
                                          </label>
                                          <i
                                            style={{ marginRight: "3px", marginTop: "2px" }}
                                            className="fa-solid fa-indian-rupee-sign f-11"
                                          ></i>
                                          <label
                                            htmlFor=""
                                            style={{ color: "#515151" }}
                                            className="price small f-10"
                                          >
                                            {product.itemPrice}
                                          </label>

                                        </>

                                      )}
                                    </div>
                                    <label
                                      className="price small f-13"
                                      style={{ width: "50px", textAlign: "center" }}
                                    >
                                      <i
                                        style={{ marginRight: "3px" }}
                                        className="fa-solid fa-indian-rupee-sign"
                                      ></i>
                                      {product.itemPrice * product.quantity}
                                    </label>
                                  </>
                                )}
                              </React.Fragment>
                              {/* )) */}
                              {/* ) : ( */}
                              {/* // Render something else if productlist.find(...) doesn't return an array */}
                              {/* <></> */}
                              {/* )} */}
                            </div>
                          </div>
                        )
                          :
                          (
                            <div className="mycart-quantitybox">
                              <div className="quantityintosalepricemycart" style={{ width: "100%" }}>
                                {/* {productlist && productlist.find(item => item.d1Id === product.d1ItemId) ? (
                                  productlist && productlist
                                    .filter(item => item.d1Id === product.d1ItemId)
                                    .map(item => ( */}
                                <React.Fragment key={product.d1Id}>
                                  {(product.availForPickUp === false || (product.restoAvailableQuantity === 0 && product.stockCheck === 1)) ? (
                                    <>
                                      {product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                                        <p style={{ textAlign: "center" }} className="mycart-productnotavli">&nbsp;&nbsp;Out Of Stock</p>
                                      ) : <p style={{ textAlign: "center" }} className="mycart-productnotavli">
                                        {loading ? "" : "Not Available for Pick Up"}
                                      </p>}

                                      <div>
                                        <i
                                          className="fa-solid fa-trash-can mycart-deletebtn"
                                          id="HomedeiveryIdButton"
                                          onClick={() => RemoveCart(product)}
                                        ></i>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="quantity"
                                        style={{ width: "max-content" }} // Corrected "max-width" to "max-content"
                                      >

                                        <button
                                          style={{ paddingBottom: "4px" }}
                                          className="quantity__minus"
                                          onClick={() => RemoveFromCart(product)}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="quantity__input"
                                          type="text"
                                          value={product.quantity}
                                          readOnly
                                        />
                                        <button
                                          className="quantity__plus"
                                          onClick={() => AddToCart(product)}
                                        >
                                          +
                                        </button>
                                        {!RemoveOnMobile && (
                                          <>
                                            <label style={{ fontSize: "12px" }}>
                                              &nbsp;x&nbsp;
                                            </label>
                                            <i
                                              style={{ marginRight: "3px", marginTop: "2px" }}
                                              className="fa-solid fa-indian-rupee-sign f-11"
                                            ></i>
                                            <label
                                              htmlFor=""
                                              style={{ color: "#515151" }}
                                              className="price small f-10"
                                            >
                                              {product.itemPrice}
                                            </label>
                                          </>
                                        )}
                                      </div>
                                      <label
                                        className="price small f-13"
                                        style={{ width: "50px", textAlign: "center" }}
                                      >
                                        <i
                                          style={{ marginRight: "3px" }}
                                          className="fa-solid fa-indian-rupee-sign"
                                        ></i>
                                        {product.itemPrice * product.quantity}
                                      </label>
                                    </>
                                  )}
                                </React.Fragment>
                                {/* ))
                                ) : (
                                  // Render something else if productlist.find(...) doesn't return an array
                                  <></>
                                )} */}
                              </div>
                            </div>
                          )
                      }
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* {showToast && (
            <div className="toastDiv" >
              Item Added Successfully !!
            </div>
          )} */}
          <div className="card-cart coupons w-95" style={{ display: "none" }}>
            <button className="opencoupon-btn" onClick={opencoupon}>
              Add coupon
            </button>
            <label className="title fs-m">Apply coupons</label>

            <div className="form">
              <input
                type="text"
                placeholder="Apply your coupons here"
                className="input_field"
              />
              <button onClick={opencoupon}>Apply</button>
            </div>
          </div>
          <div
            className="card-cart checkout w-95"
            style={{ minHeight: "150px" }}
          >
            <label className="title">Place Order</label>
            <div className="details">
              <span>Your cart subtotal:</span>
              {/* .toFixed(2) */}
              <span>

                <i
                  style={{ marginRight: "5px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>
                {totalAmount !== undefined ? totalAmount : totalAmount}
              </span>


              <span>Discount through applied coupons:</span>
              <span>

                <i
                  style={{ marginRight: "5px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>
                {/* {totalAmount / 20} */}0
              </span>
              <span>Shipping fees:</span>
              <span>

                <i
                  style={{ marginRight: "5px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>
                {/* {totalAmount / 25}{" "} */}0
              </span>
            </div>

            <div className="checkout--footer price">
              <h4> Total Amount</h4>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <label style={{ fontSize: "16px" }} className="price">
                  <i
                    style={{ marginLeft: "5px" }}
                    className="fa-solid fa-indian-rupee-sign"
                  ></i>
                  {totalAmount !== undefined ? totalAmount : "N/A"}{" "}
                </label>{" "}
                &nbsp; &nbsp;
                {RemoveOnMobile && (
                  <button
                    className="checkout-btn"
                    onClick={checkout}
                    style={{ cursor: "pointer" }}
                  >
                    Place Order
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {OpenPaymentSelection && (
        <PaymentModeSelection
          handlePayment={handlePayment}
          cod={cod}
          pod={pod}
          payment={payment}
          totalAmount={totalAmount}
          ClosePayment={ClosePayment}
        />
      )}

      {OpenTableSelection && (
        <TableAndAddress
          AllottedTimeSlot={AllottedTimeSlot}
          dateOptions={dateOptions}
          serviceType={serviceType}
          inputValues={inputValues}
          openaddress={openaddress}
          ChangeDeliveryDate={ChangeDeliveryDate}
          CompanyDetail={CompanyDetail}
          isInputDisabled={isInputDisabled}
          tableGroup={tableGroup}
          tableNumberData={tableNumberData}
          onTableSelect={onTableSelect}
          onFloorSelect={onFloorSelect}
          ChangeDeliveryTime={ChangeDeliveryTime}
          totalAmount={totalAmount}
          CloseTable={CloseTable}
          GoToPayment={GoToPayment}
          selectedService={selectedService}
          setInputValues={setInputValues}
          setOrderData={setOrderData}
          getDeliveryAndTimeSlotId={getDeliveryAndTimeSlotId}
          setAllotedTimeSlot={fetchAllotedTimeSlot}
          setDeliveryAndTimeSlotId={setDeliveryAndTimeSlotId}
        />
      )}

      {mobilesection1 && (
        <MobilesSection1
          OrderType={OrderType}
          totalAmount={totalAmount}
          checkout={checkout}
          selectedService={selectedService}
          inputValues={inputValues}
          tableNumberData={tableNumberData}
          openTable={openTable}
          showchange={showchange}
          AllottedTimeSlot={AllottedTimeSlot}
          filteredTable={filteredTable}
          setFilteredTable={setFilteredTable}
          isButtonVisible={isButtonVisible}
        />
      )}
      {mobilesection2 && (
        <MobilesSection2
          handleServiceChange={handleServiceChange}
          inputValues={inputValues}
          serviceType={serviceType}
          openTable={openTable}
          showchange={showchange}
          gotofrist={gotofrist}
          setInputValues={setInputValues}
        />
      )}
      {mobilesection3 && (
        <MobilesSection3 logindone={logindone} gotofrist={gotofrist} />
      )}

      {addCoupon && <AddCouponModal close={opencoupon} />}
      {addAddress && <AddAddressModal close={openaddress} />}
      {loading && <Loader />}
      {imageModal && (
        <div onClick={() => modalimageOpen()} className="modal-back">
          <img
            src={`${imageBaseUrl}${selectedImgId}`}
            className="suggestion-box-image"
            alt=""
          />
        </div>
      )}

      {mainModal && (
        <MainModal
          fetchData={fetchData}
          OrderType={OrderType}
          closemainmodal={closemainmodal}
        />
      )}
    </div>
  );
};
export default MyCart;
