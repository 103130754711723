import axios from "../axiosConfig";
import moment from 'moment';
//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Order = {

  insertOrder: async (data, order2, order3) => {
    // const convertDateFormat = (inputDate) => {
    //   const parts = inputDate.split("-");
    //   const yyyy_mm_dd = `${parts[2]}-${parts[1]}-${parts[0]}`;
    //   return yyyy_mm_dd;
    // }
    const convertDateFormat = (inputDate) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;

      if (regex.test(inputDate)) {
        return inputDate;
      } else {
        const parts = inputDate.split("-");
        if (parts.length === 3) {
          const yyyy_mm_dd = `${parts[2]}-${parts[1]}-${parts[0]}`;
          return yyyy_mm_dd;
        } else {
          throw new Error("Invalid date format");
        }
      }
    };

    console.log(data);
    console.log(order2);
    console.log(order3);

    var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    var loginData = JSON.parse(loginDataString); // Now it's an object

    // Now you can safely access d2Id and d1Id properties
    console.log(loginData.d2Id);
    console.log(loginData.d1Id);

    // var loginData = JSON.stringify(localStorage.getItem("accountLogin"))
    // console.log(loginData.d2Id);
    // console.log(loginData.d1Id);

    // const coordinatesString = localStorage.getItem('coordinates');
    // var latitude = 0
    // var longitude = 0
    // // const coordinates = JSON.parse(coordinatesString);
    // const coordinates = JSON.parse(coordinatesString) === null || JSON.parse(coordinatesString) === "" ? 0 : JSON.parse(coordinatesString);

    // if (JSON.parse(coordinatesString) != null || JSON.parse(coordinatesString) != "") {
    //   latitude = coordinates.latitude === null || coordinates.latitude === "" ? 0 : coordinates.latitude;
    //   longitude = coordinates.longitude === null || coordinates.longitude === "" ? 0 : coordinates.longitude;
    // }

    const processedOrders = order2.filter(item => item.qty != 0)
      .map((order) => ({
        orderNo: 0,//data.orderNo === undefined ? "" : data.orderNo.toString(),
        serviceTypeId: Number(data.serviceTypeId),
        itemDepartmentId: Number(order.itemDepartmentId),
        godownId: Number(order.godownId),
        d1ItemId: Number(order.d1ItemId),
        d2ItemId: Number(order.d2ItemId),
        qty: Number(order.qty),
        rate: Number(order.rate),
        totalAmt: Number(order.totalAmt),
        kotStatus: "Pending",//order.kotStatus,
        orderDate: order.orderDate,
        orderTime: order.orderTime,
        cancelQty: 0,
        cancelAmt: 0,
        preferenceNo: Number(order.preferenceNo),
        itemSuggestion: order.itemSuggestion || "",
        taxableAmount: Number(order.totalAmt),
        cgstPer: Number(order.cgstPer),
        cgstValue: Number(order.cgstValue),
        sgstPer: Number(order.sgstPer),
        sgstValue: Number(order.sgstValue),
        igstPer: Number(order.igstPer),
        igstValue: Number(order.igstValue),
        cessPer: Number(order.cessPer),
        cessValue: Number(order.cessValue),
        totalTaxableAmt: Number(order.totalTaxableAmt),
        isLocal: Boolean(order.isLocal),
        isInclusive: Boolean(order.isInclusive),
        hasComplementryItem: Boolean(order.hasComplementryItem),
        cookStatus: "Pending",//order.cookStatus,
        itemIncentivePoint: Number(order.itemIncentivePoint),
        itemRunningOrderCounter: 0, //Number(data.itemRunningOrderCounter),
        orderRunningQty: 0, // Number(data.orderRunningQty),
        itemRunningQty: 0, //Number(data.itemRunningQty),
        // userOrderImg: 0,
        preparationTime: Number(order.preparationTime),
        itemName: order.itemName,
        popupCategoryForTable: order.popupCategoryForTable,
        immediateTransferForHD: Boolean(order.immediateTransferForHD),
        isActive: true,
      }));
    console.log(processedOrders);

    const isFromOrderTaker = localStorage.getItem("OrderTaker");
    var OrderDoneFrom = "";
    if (isFromOrderTaker == "Yes")
      OrderDoneFrom = "App";
    else
      OrderDoneFrom = "Web";

    try {
      const apiUrl = `${WebApiUrl}/api/Order/CreateOrder`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          orderSeriesId: Number(data.orderSeriesId),
          orderNo: 0,//data.orderNo.toString(),
          autoOrderNo: 0,//Number(data.autoOrderNo),
          //serviceTypeId: 396, //Number(data.serviceTypeId),
          serviceTypeId: Number(data.serviceTypeId) || 0,
          orderStatus: data.orderStatus,
          overallOrderStatus: "Pending", //data.overallOrderStatus === null ? "" : data.overallOrderStatus,
          orderDate: data.orderDate,
          orderTime: data.orderTime,
          orderDoneBy: Number(data.orderDoneBy),
          orderDoneOn: data.orderDoneOn,
          orderConfirmedBy: Number(data.orderConfirmedBy),
          orderConfirmedOn: data.orderConfirmedOn,
          orderDoneFrom: OrderDoneFrom, //data.orderDoneFrom,
          d1AccountId: loginData.d1Id,
          d2AccountId: loginData.d2Id,
          billTypeId: Number(data.billTypeId),
          tableGroupId: Number(data.tableGroupId) || 0,
          tableId: Number(data.tableId) || 0,
          noOfPerson: Number(data.noOfPerson),
          noOfAdult: Number(data.noOfAdult),
          noOfChild: Number(data.noOfChild),
          couponCode: data.couponCode,
          couponCodeAmount: Number(data.couponCodeAmount),
          deliveryCharges: Number(data.deliveryCharges),
          deliveryDate: convertDateFormat(data.deliveryDate),
          deliveryTimeId: Number(data.deliveryTimeId) || 0,
          deliveryStatus: data.deliveryStatus,
          deliveryBoyId: Number(data.deliveryBoyId),
          deliveryBoyDispatchTime: data.deliveryBoyDispatchTime,
          deliveryBoyTripNo: Number(data.deliveryBoyTripNo),
          deliveryBoyTripIncentive: Number(data.deliveryBoyTripIncentive) || 0,
          deliveryReferenceNo: Number(data.deliveryReferenceNo),
          deliveryPreferenceNo: Number(data.deliveryPreferenceNo),
          deliveryTripStatus: data.deliveryTripStatus,
          orderPrintStatus: Number(data.orderPrintStatus),
          totalAmt: Number(data.grandTotalAmt),
          initialOrderTotalAmt: Number(data.grandTotalAmt),
          grandTotalAmt: Number(data.grandTotalAmt),
          balanceAmt: Number(data.grandTotalAmt) - Number(data.paymentAmount),
          paymentStatus: data.paymentStatus,
          paymentAmount: Number(data.paymentAmount),
          returnAmount: Number(data.returnAmount),
          discountAmount: Number(data.discountAmount),
          paymentDoneBy: Number(data.paymentDoneBy),
          paymentDoneOn: data.paymentDoneOn,
          paymentAttemptedCounter: Number(data.paymentAttemptedCounter),
          paymentMode: Number(data.paymentMode),
          paymentBank: data.paymentBank,
          paymentRemarks: data.paymentRemarks,
          remarks: data.remarks,
          orderSuggestion: data.orderSuggestion,
          kotDispatchTargetTime: data.kotDispatchTargetTime,
          //latitude: data.latitude,
          //longitude: data.longitude,
          latitude: data.latitude || 0,
          longitude: data.longitude || 0,
          currency: 0,
          deliveryAddress: data.deliveryAddress,
          name: data.name,
          mobileNo: data.mobileNo,
          companyGSTNo: data.companyGSTNo,
          companyDetails: data.companyDetails,
          orders2: processedOrders,
          orders3: [
            // {
            //   orderStatus: order3.orderStatus,
            //   orderDateTime: order3.orderDateTime,
            // },
          ],
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      localStorage.setItem("OrderDataToTrack", JSON.stringify(response.data.result));
      return response.data.result;

    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // *************************** Patch update order partially ***************************
  updateOrderPartially: async (data, ord1Id) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/UpdateOrderPartially?ord1Id=${ord1Id}`;
      console.log(apiUrl);
      const response = await axios.patch(apiUrl, data, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return response.data.isSuccess
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again. updateOrderPartially");
      }
    }
  },
  // ************************* Get Serivce Type ***************************
  getServiceType: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetServiceTypes`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      // console.log('Response:', response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get Bill Series Counter ***************************
  getBillSeriesCounter: async (billId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetBillSeriesCounter?billSeriesId=${billId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get All ***************************
  getOrderList: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 5000
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get All Order List Filter ***************************
  getOrderListFilter: async (filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: Number(data.compareValue)
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
      const response = await axios.post(
        apiUrl, {
        filter: filteredData,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        pageNumber: 0,
        pageSize: 5000
      },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get table order from table and current date ***************************
  getOrderByTable: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          pageNumber: 0,
          pageSize: 5000,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get table order from table and current date ***************************
  getOrderByTable: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    try {
      var WebApiUrl = express_server_url;
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          pageNumber: 0,
          pageSize: 5000,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // *************************** Put ***************************
  updateOrder: async (data, order2, order3, order1Id, totalamountmain) => {
    const initialDate = data.deliveryDate;
    console.log(data.deliveryDate);
    //const formattedDate = moment(initialDate, "DD-MM-YYYY").format('YYYY-MM-DD');
    console.log(data);
    console.log(order2);
    console.log(order3);

    const processedOrders = order2.filter(item => item.qty != 0)
      .map(order => ({
        godownId: Number(order.godownId) || 0,
        d1ItemId: Number(order.d1ItemId) || 0,
        d2ItemId: Number(order.d2ItemId) || 0,
        serviceTypeId: Number(data.serviceTypeId),
        itemDepartmentId: Number(order.itemDepartmentId),
        qty: Number(order.qty) || 0,
        rate: Number(order.rate) || 0,
        totalAmt: Number(order.totalAmt) || 0,
        // totalAmt: Number(order.totalAmt) || 0,
        kotStatus: "Pending",//order.kotStatus === null ? "" : order.kotStatus,
        preferenceNo: 0,//Number(order.preferenceNo) || 0,
        orderDate: order.orderDate === null ? "" : order.orderDate,
        orderTime: order.orderTime === null ? "" : order.orderTime,
        itemSuggestion: order.itemSuggestion == null ? "" : order.itemSuggestion,
        itemRunningOrderCounter: Number(order.itemRunningOrderCounter),
        orderRunningQty: Number(order.orderRunningQty),
        itemRunningQty: Number(order.itemRunningQty), // Number(data.itemRunningQty
        taxableAmount: Number(order.taxableAmount) || 0,
        cgstPer: Number(order.cgstPer) || 0,
        cgstValue: Number(order.cgstValue) || 0,
        sgstPer: Number(order.sgstPer) || 0,
        sgstValue: Number(order.sgstValue) || 0,
        igstPer: Number(order.igstPer) || 0,
        igstValue: Number(order.igstValue) || 0,
        cessPer: Number(order.cessPer) || 0,
        cessValue: Number(order.cessValue) || 0,
        totalTaxableAmt: Number(order.totalTaxableAmt) || 0,
        isLocal: Boolean(order.isLocal),
        isInclusive: Boolean(order.isInclusive),
        hasComplementryItem: Boolean(order.hasComplementryItem),
        cookStatus: "Pending",//order.cookStatus === null ? "" : order.cookStatus,
        itemIncentivePoint: Number(order.itemIncentivePoint) || 0,
        preparationTime: order.preparationTime,
        itemName: order.itemName,
        popupCategoryForTable: order.popupCategoryForTable,
        immediateTransferForHD: Boolean(order.immediateTransferForHD),
        isActive: true,
      }));
    console.log(processedOrders);
    try {
      const apiUrl = `${WebApiUrl}/api/Order/UpdateOrder?orderId=${order1Id}`;
      console.log(apiUrl);
      const response = await axios.put(
        apiUrl,
        {
          ord1Id: Number(order1Id) || 0,
          serviceTypeId: Number(data.serviceTypeId),
          tableGroupId: Number(data.tableGroupId) || 0,
          tableId: Number(data.tableId) || 0,
          noOfPerson: Number(data.noOfPerson) || 0,
          noOfAdult: Number(data.noOfAdult) || 0,
          noOfChild: Number(data.noOfChild) || 0,
          couponCode: data.couponCode === null ? "" : data.couponCode,
          couponCodeAmount: Number(data.couponCodeAmount) || 0,
          overallOrderStatus: "Pending", //data.overallOrderStatus === null ? "" : data.overallOrderStatus,
          orderDate: data.orderDate === null ? "" : data.orderDate,
          orderTime: data.orderTime === null ? "" : data.orderTime,
          orderDoneBy: Number(data.orderDoneBy) || 0,
          orderDoneOn: data.orderDoneOn === null ? "" : data.orderDoneOn,
          orderConfirmedBy: Number(data.orderConfirmedBy) || 0,
          orderConfirmedOn: data.orderConfirmedOn === null ? "" : data.orderConfirmedOn,
          deliveryCharges: Number(data.deliveryCharges) || 0,
          deliveryDate: data.deliveryDate === null ? "" : data.deliveryDate,  //formattedDate,
          deliveryTimeId: Number(data.deliveryTimeId) || 0,
          deliveryStatus: data.deliveryStatus === null ? "" : data.deliveryStatus,
          deliveryBoyId: Number(data.deliveryBoyId) || 0,
          deliveryBoyDispatchTime: data.deliveryBoyDispatchTime === null ? "" : data.deliveryBoyDispatchTime,
          deliveryBoyTripNo: Number(data.deliveryBoyTripNo) || 0,
          deliveryBoyTripIncentive: Number(data.deliveryBoyTripIncentive) || 0,
          deliveryReferenceNo: Number(data.deliveryReferenceNo) || 0,
          deliveryPreferenceNo: Number(data.deliveryPreferenceNo) || 0,
          deliveryTripStatus: data.deliveryTripStatus === null ? "" : data.deliveryTripStatus,
          orderPrintStatus: Number(data.orderPrintStatus) || 0,
          totalAmt: Number(totalamountmain) || 0,
          grandTotalAmt: Number(totalamountmain) || 0,
          balanceAmt: Number(totalamountmain) - Number(data.paymentAmount) || 0,
          paymentStatus: "Pending",//data.paymentStatus === null ? "" : data.paymentStatus,
          paymentAmount: Number(data.paymentAmount) || 0,
          returnAmount: Number(data.returnAmount) || 0,
          discountAmount: Number(data.discountAmount) || 0,
          paymentDoneBy: Number(data.paymentDoneBy) || 0,
          paymentDoneOn: data.paymentDoneOn === null ? "" : data.paymentDoneOn,
          paymentAttemptedCounter: Number(data.paymentAttemptedCounter) || 0,
          paymentModeId: Number(data.paymentModeId) || 0,
          paymentBank: data.paymentBank === null ? "" : data.paymentBank,
          paymentRemarks:
            data.paymentRemarks === null ? "" : data.paymentRemarks,
          remarks: data.remarks === null ? "" : data.remarks,
          orderSuggestion:
            data.orderSuggestion === null ? "" : data.orderSuggestion,
          kotDispatchTargetTime: data.kotDispatchTargetTime === null ? "00:00" : data.kotDispatchTargetTime,
          latitude: data.latitude === null ? "" : data.latitude,
          longitude: data.longitude === null ? "" : data.longitude,
          currencyId: Number(data.currencyId) || 0,
          hasRunningOrder: Boolean(data.hasRunningOrder),
          runningOrderCounter: Number(data.runningOrderCounter),
          runningOrderTotalAmt: Number(data.runningOrderTotalAmt) || 0,
          orders2: processedOrders,
          orders3: [
            // {
            //   orderStatus: order3.orderStatus === null ? "" : order3.orderStatus,
            //   orderDateTime: order3.orderDateTime === null ? "" : order3.orderDateTime,
            //   isActive: true,
            // }
          ],
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ************************* Get Order List by order No ***************************
  getOrderListByOrderNo: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderListByOrderNo`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 5000
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;

    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // ************************* Get Order List by order No ***************************
  getOrderListByOrderNoFilter: async (filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderListByOrderNo`;
      const response = await axios.post(apiUrl, {
        filter: filteredData,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        pageNumber: 0,
        pageSize: 5000
      }, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // ************************* Get Order List by user ***************************
  getOrderListByUserFilter: async (userId, filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderListByUserId`;
      const response = await axios.post(apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          masterType: userId,
          pageNumber: 0,
          pageSize: 50000
        },
        {
          withCredentials: true,
        });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // ************************* Get table order from table and current date ***************************
  getOrderListFilter: async (filterData) => {
    //debugger;
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
      const response = await axios.post(apiUrl, {
        filter: filteredData,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        pageNumber: 0,
        pageSize: 50000
      }, {
        withCredentials: true,
      });
      // debugger;
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  imageUpload: async (imageArray) => {
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    try {
      console.log(imageArray);

      const formData = new FormData();

      // Iterate over the imageArray
      for (const imageObj of imageArray) {
        const fileId = Object.keys(imageObj)[0]; // Get the key of the object
        const file = imageObj[fileId]; // Get the file corresponding to the key

        // Append Ord2Id and file to formData
        formData.append('Ord2Id', fileId);
        formData.append('files', file);

        const apiUrl = `${WebApiUrl}/api/Order/imageUpload`;

        // Upload the file
        const response = await axios.post(apiUrl, formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('Response:', response.data.isSuccess);
        if (!response.data.isSuccess) {
          console.error('Update failed:', response.data.errorMessage);
        }

        // Clear the formData for the next iteration
        formData.delete('Ord2Id');
        formData.delete('files');

        await delay(1000);
      }

      // If you want to return something after all files are processed successfully, do it here
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      throw error;
    }
  },

  getCartList: async (d2AccountId) => {
    // const filteredData = filterData.map(data => ({
    //   fieldName: data.fieldName,
    //   operatorName: data.operatorName,
    //   compareValue: data.compareValue
    // }));

    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetAllEcomCartDetails?d2AccountId=${d2AccountId || 0}`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 0
        },
        {
          withCredentials: true,
        }
      );
      // console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  }
  ,

  // ************************* Get GetAllFavouriteItemList All ***************************
  getGetAllFavouriteItemList: async (filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetAllFavouriteItemList`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 0
        },
        {
          withCredentials: true,
        }
      );
      // console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


  // ************************* Insert Add to Cart CreateEcomCartDetail ***************************
  insertCreateEcomCartDetail: async (data) => {
    console.log(data);
    // Now you can safely access d2Id and d1Id properties
    try {
      const apiUrl = `${WebApiUrl}/api/Order/CreateEcomCartDetail`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          d1ItemId: Number(data.d1ItemId),
          d2ItemId: Number(data.d2ItemId),
          d1AccountId: Number(data.d1AccountId),
          d2AccountId: Number(data.d2AccountId),
          userId: Number(data.userId),
          quantity: Number(data.quantity),
          unit: Number(data.unit),
          itemPrice: Number(data.itemPrice),
          clientId: Number(data.clientId)


        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );

      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ************************* Remove from Cart CreateEcomCartDetail ***************************
  RemoveEcomCartDetail: async (data) => {
    console.log(data);
    // Now you can safely access d2Id and d1Id properties
    try {
      const apiUrl = `${WebApiUrl}/api/Order/RemoveEcomCartDetail`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          d1ItemId: Number(data.d1ItemId),
          d2ItemId: Number(data.d2ItemId),
          d1AccountId: Number(data.d1AccountId),
          d2AccountId: Number(data.d2AccountId),
          userId: Number(data.userId),
          quantity: Number(data.quantity),
          unit: Number(data.unit),
          itemPrice: Number(data.itemPrice),
          clientId: Number(data.clientId)
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );

      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },


  UpdateEcomCartPartially: async (data, d2AccountId, d2ItemId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/UpdateEcomCartPartially?D2AccountId=${d2AccountId}&D2ItemId=${d2ItemId}`;
      console.log(apiUrl);
      const response = await axios.patch(apiUrl, data, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return response.data.isSuccess
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  UpdateClientIdByAccountId: async (account) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/UpdateClientIdByAccountId?AccountId=${account.d2Id}`;
      console.log(apiUrl);
      const response = await axios.put(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  DeleteEcomCartByAccountId: async (AccountId) => {
    try {

      const apiUrl = `${WebApiUrl}/api/Order/DeleteEcomCartByAccountId?AccountId=${AccountId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // ************************* Insert Add to Favourite CreateFavouriteItemList ***************************
  insertCreateFavouriteItemList: async (data) => {
    console.log(data);
    // Now you can safely access d2Id and d1Id properties
    try {
      const apiUrl = `${WebApiUrl}/api/Order/CreateFavouriteItemList`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          d1ItemId: Number(data.d1ItemId),
          d2ItemId: Number(data.d2ItemId),
          d1AccountId: Number(data.d1AccountId),
          d2AccountId: Number(data.d2AccountId),
          userId: Number(data.userId),

        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );

      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ************************* delete DeleteEcomCartDetail ****************************
  deleteDeleteFavouriteItemList: async (FavouriteItemId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/DeleteFavouriteItemList?FavouriteItemId=${FavouriteItemId}`;

      //const apiUrl = `${express_server_url}/api/Station?stationId=${stationId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log('Delete Response:', response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },


  // ************************* Get All ***************************

  getRawOrderList: async (filteredData) => {
    console.log("Filtered Data:", filteredData);
    try {
      // Transform the filteredData array into the required format
      const formattedFilter = filteredData.map(filter => ({
        FieldName: filter.fieldName,
        OperatorName: filter.operatorName,
        CompareValue: filter.compareValue
      }));

      const apiUrl = `${WebApiUrl}/api/Order/GetRawOrderList`;
      const response = await axios.post(apiUrl, {
        filter: {
          or: [
            {
              and: formattedFilter
            }
          ]
        },
        order: [
          {
            propertyName: "ord1Id",
            ascending: true
          }
        ],
        pageNumber: 1,
        pageSize: 5000
      });
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error);
      throw error; // Rethrow the error after logging it
    }
  },


  // ************************* Get Order List By Ord1Id ***************************
  getOrderListByOrd1Id: async (order1Id) => {
    // const filteredData = filterData.map(data => ({
    //   fieldName: data.fieldName,
    //   operatorName: data.operatorName,
    //   compareValue: Number(data.compareValue)
    // }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderByOrd1Id`;
      const response = await axios.post(
        apiUrl, {
        filter: [],
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        ord1Id: order1Id,
        pageNumber: 0,
        pageSize: 5000
      },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // ************************* update cartdata partially  ***************************
  UpdateEcomCartPartially: async (data, EcomCartId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/UpdateEcomCartPartiallyWithEcomId?EcomCartId=${EcomCartId}`;
      console.log(apiUrl);
      const response = await axios.patch(apiUrl, data, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return response.data.isSuccess
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  imageUploadForCart: async (imageArray) => {
    try {
      console.log(imageArray);

      const apiUrl = `${WebApiUrl}/api/Order/UpdateEcomCartImage`;
      const response = await axios.post(apiUrl, imageArray, {
        withCredentials: true,
        credentials: 'include',
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Response:", response.data.isSuccess);
      if (!response.data.isSuccess) {
        console.error("Update failed:", response.data.errorMessage);
      }

      await new Promise((resolve) => setTimeout(resolve, 1000)); // Use Promise-based delay

      // If you want to return something after all files are processed successfully, do it here
      return true;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // *************************** Patch update order partially ***************************
  UpdateImgStorePartially: async (data, ImageStoreId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/UpdateImgStorePartially?ImageStoreId=${ImageStoreId}`;
      console.log(apiUrl);
      const response = await axios.patch(apiUrl, data, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return response.data.isSuccess
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again. Update Img Store Partially");
      }
    }
  },

  DeleteImage: async (ImageId) => {
    console.log(ImageId);
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/ItemMaster/DeleteImage?ImageID=${ImageId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
        credentials: "include",
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // ********************* Order Report Filter ***************************
  getOrderReportFilter: async (filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/getOrderListReport`;
      const response = await axios.post(apiUrl, {
        filter: filteredData,
        order: [],
        pageNumber: 0,
        pageSize: 50000
      }, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // *************************** Create Payment Mode with order ***************************
  createPaymentModeWithOrder: async (filterData, createdBy) => {
    console.log(filterData)
    const storedArrayString = localStorage.getItem('accountLogin');
    const loginUser = JSON.parse(storedArrayString);

    const filteredData = filterData.map((data) => ({
      ord1Id: Number(data.ord1Id),
      paymentModeId: Number(data.paymentModeId),
      paymentAmount: Number(data.paymentAmount),
      remarks: "",
      transId: data.transId,
      payUId: data.payUId,
      bankRefNo: data.bankRefNo,
      status: data.status,
      paymentTypeMethod: data.paymentTypeMethod,
      createdBy: Number(createdBy) || loginUser.d1Id || loginUser.userId,
      isActive: true
    }));
    console.log(filteredData);
    try {
      const apiUrl = `${WebApiUrl}/api/Order/CreatePaymentModeWithOrder`;
      console.log(apiUrl);
      const response = await axios.post(apiUrl, filteredData, {
        withCredentials: true,
        credentials: 'include',
      });

      console.log("Response:", response.data);
      return true;

    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // *************************** Patch update item partially ***************************
  updateItemPartially: async (data, ord2Id) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/UpdateOrderItemWisePartially?ord2Id=${ord2Id}`;
      console.log(apiUrl);
      const response = await axios.patch(apiUrl, data, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return response.data.isSuccess
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ************************* Get Payment Mode by ord1Id ***************************
  getPaymentModeByOrd1Id: async (ord1Id, paymentModeName) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetPayIdByOrd1Id?ord1Id=${ord1Id}&paymentModeName=${paymentModeName}`;
      const response = await axios.post(apiUrl, {},
        {
          withCredentials: true,
        });
      console.log('Response:', response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

};

export default Order;
