import React, { useEffect, useState } from "react";
import AddAddress from "../../API/API_SERVISES/AddAddress";
import { useContext } from "react";
import MyContext from "../MyContext";
import moment from "moment";

const MobilesSection1 = (props) => {
  const {
    OrderType,
    checkout,
    selectedService,
    inputValues,
    tableNumberData,
    totalAmount,
    openTable,
    showchange,
    AllottedTimeSlot,
    isButtonVisible,
    //filteredTable,
    //setFilteredTable
  } = props;
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const userLogin = localStorage.getItem("accountLogin");
    if (userLogin) {
      setShowButton(true);
    }
  }, []);

  const { DefaultAddressChange, isInputDisabled, LoginShow } = useContext(MyContext)

  const [filteredTime, setFilteredTime] = useState({});
  const [filteredTable, setFilteredTable] = useState({});

  useEffect(() => {
    console.log("tableNumberData ", tableNumberData)
    console.log("filteredTable ", filteredTable)
  }, [filteredTable])

  // useEffect(() => {
  //   if (Array.isArray(tableNumberData) && tableNumberData.length > 0) {
  //     debugger;
  //     var filteredTableName = tableNumberData.filter(
  //       (item) => item.tableId == inputValues.tableGroupId
  //     );
  //     console.log("filteredTableName", filteredTableName);
  //     setFilteredTable(filteredTableName);
  //   } else {
  //     setFilteredTable("");
  //   }
  // }, [tableNumberData, inputValues.tableGroupId, openTable]);


  useEffect(() => {
    if (Array.isArray(AllottedTimeSlot) && AllottedTimeSlot.length > 0) {
      var filteredTimeName = AllottedTimeSlot.filter(
        (item) => item.id == inputValues.deliveryTimeId
      );
      console.log(filteredTimeName);
      setFilteredTime(filteredTimeName);
    } else {
      setFilteredTime("");
    }
  }, [AllottedTimeSlot, inputValues.deliveryTimeId]);

  const [addressdata, setAddressData] = useState([]);

  useEffect(() => {
    const getAddress = async () => {
      try {
        // Retrieve the accountLogin item from localStorage
        const accountLogin = localStorage.getItem("accountLogin");

        // Parse the JSON string back to an object
        const parsedAccount = JSON.parse(accountLogin);

        // Make sure parsedAccount and parsedAccount.d1Id exist
        if (parsedAccount && parsedAccount.d1Id) {
          const address = await AddAddress.getAddress(parsedAccount.d1Id);
          console.log(address);

          const arrayString = JSON.stringify(address);
          localStorage.setItem('Address', arrayString);

          setAddressData(address);
        } else {
          console.error("Account data is not available or invalid.");
        }
      } catch (error) {
        // Log any errors that occur during the fetch or JSON parsing
        console.error("Failed to fetch address:", error);
      }
    };
    getAddress();
  }, [DefaultAddressChange, LoginShow]);

  function convertTo12HourFormat(twentyFourHourTime) {
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }

    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    if (minutes != undefined && minutes != null) {
      const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
      return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
    }
    else {
      return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
    }
  }

  const convertTime = (date) => {
    // Check if the date is already in the 'dd-mm-yyyy' format
    if (moment(date, 'DD-MM-YYYY', true).isValid()) {
      return date;
    } else {
      return moment(date).format('DD-MM-YYYY');
    }
  }

  return (
    <MyContext.Provider
      value={{
        addressdata
      }}
    >
      <div className="myordercart-mobile">
        {isInputDisabled == false && (
          <div className="myordercart-mobile-1">
            <div style={{ width: "100%" }}>
              <p style={{ margin: "0" }}>
                Service Type : <span>{OrderType}</span>
              </p>
              {isInputDisabled == false && (
                <div onClick={showchange} style={{ color: "#007f00" }}>
                  CHANGE
                </div>
              )}
            </div>
            {selectedService == 751 && (
              <>
                <div style={{ width: "100%" }}>
                  <p style={{ margin: "0" }}>
                    Serve To :
                    {inputValues.tableName ? (
                      <>
                        <span>{inputValues.tableGroupName + " --"}</span>
                        <span>{inputValues.tableName}</span>
                      </>
                    ) : (
                      <span style={{ color: "green" }}>Table not selected</span>
                    )}
                  </p>

                  {isInputDisabled == false && (
                    <div onClick={openTable} style={{ color: "#007f00", marginBottom: "-20px" }}>
                      CHANGE
                    </div>
                  )}
                </div>
                <div style={{ width: "100%" }}>
                  <p style={{ margin: "0" }}>
                    Date and Time :
                    {filteredTime.length > 0 ? (
                      <>
                        <span>{convertTime(inputValues.deliveryDate)}</span>
                        --
                        <span>
                          {/* {filteredTable.length > 0 ? filteredTable[0].tableName : ""} */}
                          {filteredTime.length > 0
                            ? convertTo12HourFormat(String(filteredTime[0].availableFrom))
                            : ""}
                          --
                          {filteredTime.length > 0
                            ? convertTo12HourFormat(String(filteredTime[0].availableTo))
                            : ""}
                        </span>
                      </>
                    ) : (
                      <span style={{ color: "green" }}>
                        Date & Time is not selected
                      </span>
                    )}
                  </p>
                  {/* <div onClick={openTable} style={{ color: "#007f00" }}>
                CHANGE
              </div> */}
                </div>
              </>
            )}

            {selectedService == 752 && (
              <>
                <div style={{ width: "100%" }}>
                  <p style={{ margin: "0", width: "85%" }}>
                    Address :
                    {addressdata && addressdata
                      .filter((address) => address.isDefault === true) // Correctly filter the items where isDefault is true
                      .map(
                        (
                          address,
                          index // Correct usage of the map function with JSX inside
                        ) => (
                          <span key={index}>
                            {address.address} - {address.city}
                          </span>
                        )
                      )}
                  </p>
                  <div onClick={openTable} style={{ color: "#007f00", marginBottom: "-15px" }}>
                    CHANGE
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <p style={{ margin: "0" }}>
                    Date and Time :{" "}
                    {filteredTime.length > 0 ? (
                      <>
                        <span>{convertTime(inputValues.deliveryDate)}</span>
                        --
                        <span>
                          {/* {filteredTable.length > 0 ? filteredTable[0].tableName : ""} */}
                          {filteredTime.length > 0
                            ? convertTo12HourFormat(String(filteredTime[0].availableFrom))
                            : ""}
                          --
                          {filteredTime.length > 0
                            ? convertTo12HourFormat(String(filteredTime[0].availableTo))
                            : ""}
                        </span>
                      </>
                    ) : (
                      <span style={{ color: "green" }}>
                        Date & Time is not selected
                      </span>
                    )}
                  </p>
                  {/* <div onClick={openTable} style={{ color: "#007f00" }}>
                CHANGE
              </div> */}
                </div>
              </>
            )}

            {selectedService == 753 && (
              <>
                <div style={{ width: "100%" }}>
                  <p style={{ margin: "0" }}>
                    Pick-up On :
                    {filteredTime.length > 0 ? (
                      <>
                        <span>{inputValues.deliveryDate}</span>
                        --
                        <span>
                          {/* {filteredTable.length > 0 ? filteredTable[0].tableName : ""} */}
                          {filteredTime.length > 0
                            ? convertTo12HourFormat(String(filteredTime[0].availableFrom))
                            : ""}
                          --
                          {filteredTime.length > 0
                            ? convertTo12HourFormat(String(filteredTime[0].availableTo))
                            : ""}
                        </span>
                      </>
                    ) : (
                      <span style={{ color: "green" }}>
                        Pick up Date is not selected
                      </span>
                    )}
                  </p>
                  <div onClick={openTable} style={{ color: "#007f00" }}>
                    CHANGE
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <p style={{ margin: "0px" }}>
                    Pick-up From :
                    <span>
                      {filteredTable.length > 0 ? filteredTable[0].tableGroup : ""}
                    </span>{" "}
                    Mothers Hut National Highway 34, beside Indian Oil PetrolPump
                    ,Bhatjangla,West Bengal 741101
                  </p>
                </div>
              </>
            )}
          </div>
        )}

        {/* {showButton ? ( */}

        {isButtonVisible && (
          <div className="myordercart-mobile-2" onClick={checkout}>
            Place Order
            <div>
              <i
                style={{ marginRight: "5px" }}
                className="fa-solid fa-indian-rupee-sign"
              ></i>{" "}
              {totalAmount}
            </div>
          </div>
        )}
        {/* // ) : (
      //   <div className="myordercart-mobile-2" onClick={props.showloginsighin}>
      //     Register / Login to Mother's hut
      //   </div>
      // )} */}
      </div>
    </MyContext.Provider>
  );
};

export default MobilesSection1;
