import React, { useContext, useEffect, useState } from "react";
import AccountAPI from "../API/API_SERVISES/Account";
import { useNavigate } from "react-router-dom";
import MyContext from "./MyContext";
import Order from "../API/API_SERVISES/Order";

const OrderBox = (props) => {

  const navigate = useNavigate();

  const { imageBaseUrl } = useContext(MyContext);

  const [isClicked, setIsClicked] = useState({});

  const toggleClass = (id) => {
    setIsClicked(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };
  const OpenTrackModal = (orderNo, ord1Id) => {

    navigate("/TrackOrderStatus", {
      state: {
        orderNo,
        ord1Id
      }
    });
  };
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);

    const fetchData = async () => {
      props.setLoading(true)

      const filteredData = [{
        fieldName: "D1AccountId", //"d1AccId",
        operatorName: "Equal",
        compareValue: loginData.d1Id
      }]
      // var orderlistData = await AccountAPI.getMyOrderList(loginData.d1Id);
      // var orderlistData = await Order.getOrderListFilter(filteredData);
      var data = await Order.getRawOrderList(filteredData);
      // console.log(orderlistData);
      const orderlistData = data.orderList
      setOrderList(orderlistData);
      if (orderlistData) {
        props.setLoading()
      }
      else {
        alert("error")
      }
    };
    fetchData();
  }, []);
  let prevOrderId = null;

  function convertTo12HourFormat(twentyFourHourTime) {
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }

    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    if (minutes != undefined && minutes != null) {
      const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
      return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
    }
    else {
      return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
    }
  }

  const timeFormat = (twentyFourHourTime) => {
    if (!twentyFourHourTime) return 0;

    const [startTime, endTime] = twentyFourHourTime && twentyFourHourTime.split('-');
    if (startTime || endTime) {
      const startT = startTime.trim();
      const endT = endTime.trim();
      let convertedStartTime = 0;
      let convertedEndTime = 0;

      if (startT) {
        convertedStartTime = convertTo12HourFormat(startT);
      }
      if (endT) {
        convertedEndTime = convertTo12HourFormat(endT);
      }
      const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
      return convertedTimeRange;
    }
    return 0;
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column-reverse" }}>
        {/* {orderList.map((order) => ( */}
        {orderList && orderList.length > 0 && orderList.filter((data) => {
          const isDifferentOrderId = data.ord1Id !== prevOrderId;
          prevOrderId = data.ord1Id;
          return isDifferentOrderId;
        }).map((order) => (
          <div key={order.ord1Id}
            className={` ${isClicked[order.ord1Id] ? "custom-div" : ""} my-order-table`}>
            <div className="order-box-top-top">
              <div className="order-box-top">
                <div className="order-detail-list" style={{ width: "60%" }} onClick={() => toggleClass(order.ord1Id)}>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <p className="ordertxt-1">Order No : {order.orderNo},&nbsp; &nbsp;
                      {/* <label style={{ color: order.overallOrderStatus === "cancel" ? "darkred" : "darkgreen" }}>{order.overallOrderStatus === "cancel" ? "Order has Cancelled" : order.overallOrderStatus}</label> */}
                    </p>
                    {/* {order.overallOrderStatus == null &&( */}
                    <>
                      {
                        order.overallOrderStatus === "Cancel" ? (
                          <div style={{ backgroundColor: "#8b000029", maxWidth: "fitContent", borderRadius: "5px" }}>
                            <label style={{ color: "darkred", fontSize: "11px", fontWeight: "600", display: "flex" }}>&nbsp;Cancelled &nbsp;</label>
                          </div>
                        ) : order.overallOrderStatus === "" ? (
                          <div></div>
                        ) : (
                          <div style={{ backgroundColor: "rgba(104, 189, 104, 0.24)", maxWidth: "fitContent", borderRadius: "5px" }}>
                            <label style={{ color: "darkgreen", fontSize: "11px", fontWeight: "600", display: "flex" }}>&nbsp; {order.overallOrderStatus} &nbsp;</label>
                          </div>
                        )
                      }
                    </>
                    {/* )} */}
                  </div>
                  <p className="ordertxt-2">Order Date and Time  : {`${new Date(order.orderDate).getDate().toString().padStart(2, '0')}-${(new Date(order.orderDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(order.orderDate).getFullYear()}`} - - {order.orderTime} </p>
                  <p className="ordertxt-2" >
                    Service Type : {order.serviceTypeName}
                    {order.serviceTypeId === 751 && (
                      <>
                        | Table Name : {order.tableName}
                      </>
                    )}
                  </p>
                  <p className="ordertxt-2">Delivery Date : {`${new Date(order.deliveryDate).getDate().toString().padStart(2, '0')}-${(new Date(order.deliveryDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(order.deliveryDate).getFullYear()}`}</p>
                  <p className="ordertxt-2">
                    Delivery Time Slot : {timeFormat(order.deliveryTime)}
                  </p>
                </div>
                <div
                  style={{ width: "40%", alignItems: "end" }}
                  className="order-detail-list" // onClick={() => toggleClass(order.ord1Id)}
                >
                  <p className="ordertxt-1">Sub Amt : ₹&nbsp;{order.o1TotalAmt}</p>
                  <p className="ordertxt-1">Delivery : ₹&nbsp;{order.deliveryCharges || 0}</p>
                  <p style={{ marginBottom: "15px" }} className="ordertxt-1">
                    Total Amt : ₹&nbsp;{order.o1TotalAmt}
                  </p>
                  <p
                    style={{ marginBottom: "0px", color: "rgb(51, 51, 244)", cursor: "pointer" }}
                    className="ordertxt-3"
                    onClick={() => toggleClass(order.ord1Id)}
                  >
                    See detail &gt;
                  </p>
                  <p
                    style={{
                      marginBottom: "0px", color: "rgba(189, 5, 22, 0.74)", cursor: "pointer",
                      display: order.overallOrderStatus === "Cancel" || order.overallOrderStatus == "Returned" ? "none" : "block"
                    }}
                    className="ordertxt-3"
                    onClick={() => OpenTrackModal(order.orderNo, order.ord1Id)}
                  >
                    Track Status &gt;
                  </p>
                </div>
              </div>
              <div>
                <div
                  style={{
                    borderRadius: "15px",
                    // height: "350px",
                    boxShadow: "none",
                    width: "100%",
                    padding: "0",
                    marginBottom: "0",
                  }}
                  className="card-cart cart "
                >
                  <label className="title" style={{ marginTop: "8px", fontSize: "14px" }}>My Order </label>

                  <div style={{ padding: "5px" }} className="products">
                    {orderList && orderList.length > 0 && orderList
                      .filter((itemdata) => itemdata.ord1Id === order.ord1Id)
                      .map((item) => (
                        <div
                          key={item.id}
                          style={{
                            width: "95%",
                            // paddingBottom: "5px",
                            marginBottom: "5px",
                            borderRadius: "0px",
                            height: "auto",
                          }}
                          className="product"
                        >
                          <div className="products-left">
                            <img
                              style={{ width: "40px", borderRadius: "8px" }}
                              src={`${imageBaseUrl}${item.d1ItemImageId || 0}`}
                              alt=""
                            />
                            <div>
                              <span style={{ fontSize: "11px" }}>
                                {item.d2ItemName}
                              </span>
                              <p style={{ fontSize: "10px" }}>
                                {" "}
                                Quantity : {item.qty} x ₹&nbsp;{item.rate}
                              </p>
                              <div className="flex-start" style={{ width: "130%", display: item.itemSuggestion == "" ? "none" : "block" }}>
                                <label style={{ fontSize: "10px", }}>Item&nbsp;suggesstion&nbsp;:-&nbsp; </label>
                                <label style={{ fontSize: "10px", color: "darkGreen" }}>{item.itemSuggestion}</label>
                              </div>
                            </div>
                          </div>
                          <div
                            className="products-right"
                            style={{ width: "20%", justifyContent: "end" }}
                          >
                            <label
                              className="price small"
                              style={{ marginRight: "12px" }}
                            >
                              <i className="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                              {item.qty * item.rate}
                            </label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderBox;
