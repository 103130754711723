import { useContext, useEffect, useState } from "react";
import Order from "../API/API_SERVISES/Order";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import OrderAPI from "../API/API_SERVISES/Order";
import Payment from "../API/API_SERVISES/Payment";
import Table from "../API/API_SERVISES/Table";
import Loader from "../Component/Loader";
import MyContext from "../Component/MyContext";
import CurrentDateAndTime from "../API/API_SERVISES/CurrentDateAndTime";
import Company from "../API/API_SERVISES/Company";
import User from "../API/API_SERVISES/User";
import axios from '../API/axiosConfig';
import APIConfiguration from "../API/API_SERVISES/APIConfiguration";
import MainModal from "../Component/MainModal";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const TrackOrderStatus = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const { orderNo, ord1Id } = location.state || {};

    const { imageBaseUrl, CompanyDetail, OrderType,
        closemainmodal,
        mainModal,
        setMainModal,
        fetchData } = useContext(MyContext);

    //alert(orderNo);
    const [loading, setLoading] = useState(false);

    const [orderStatus, setOrderStatus] = useState([
        {
            id: 1,
            icon: "fa-solid fa-basket-shopping",
            statusName: "Your order is submitted in Restaurant",
            date: "",
            color: "green",
            paymentStatus: "Pending",
            display: "block",
        },
        {
            id: 2,
            icon: "fa-solid fa-square-check",
            statusName: "",
            date: "",//"31/10/2023 13:10",
            color: "dimgrey",
            paymentStatus: "Pending",
            display: "block",
        },
        {
            id: 3,
            icon: "fa-solid fa-cake-candles",
            statusName: `Restaurant are cooking your Food`,
            date: "",
            color: "dimgrey",
            paymentStatus: "Pending",
            display: "block",
        },
        {
            id: 4,
            icon: "fa-solid fa-motorcycle",
            statusName: "Your Delivery Boy is on the way",
            date: "",
            color: "dimgrey",
            paymentStatus: "Pending",
            display: "none",
        },
        {
            id: 5,
            icon: "fa-solid fa-bag-shopping",
            statusName: "Your food has been delivered",
            date: "",
            color: "dimgrey",
            paymentStatus: "Pending",
            display: "block",
        },
    ]);

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12; // Handle midnight (0 hours)
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    const [orderedData, setOrderedData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = localStorage.getItem("OrderDataToTrack");
            const parsedData = JSON.parse(data);

            if (parsedData === null && parsedData === undefined) {
                return <label>No Order to Track</label>
            }
            else {
                //const orderId = parsedData.orderNo;
                // const filterData = [
                //     {
                //         fieldName: "ord1Id",
                //         operatorName: "Equal",
                //         compareValue: ord1Id,
                //     },
                // ];
                try {
                    const data = await Order.getOrderListByOrd1Id(ord1Id);
                    var orderItemData = data.item1;
                    //if (isMounted) {
                    console.log("orderItemData", orderItemData);
                    setOrderedData(orderItemData);

                    // *************** Change Status **********************
                    const orderDate = moment(orderItemData[0].orderDate).format('DD-MM-YYYY');

                    const updatedStatus = [...orderStatus];

                    const currentUserData = await User.GetCurrentUser()
                    const CompanyData = await Company.GetCompanyById(currentUserData.branchId)

                    console.log("CompanyDetail", CompanyData)
                    updatedStatus[2] = {
                        ...updatedStatus[2],
                        statusName: `${CompanyData.shortName || "Restaurant"} are cooking your Food`,
                    };

                    if (orderItemData[0].orderDate !== null && orderItemData[0].orderDate !== "") {
                        updatedStatus[0] = {
                            ...updatedStatus[0],
                            color: "Green",
                            date: orderDate + " " + convertToAmPm(orderItemData[0].orderTime),
                        };
                    }
                    else {
                        updatedStatus[1] = {
                            ...updatedStatus[1],
                            date: orderDate + " " + convertToAmPm(orderItemData[0].orderTime)
                        };
                    }

                    //alert(orderDate + " " + convertToAmPm(orderItemData[0].orderTime))

                    //alert(orderItemData[0].overallOrderStatus)
                    const updateStatusAtIndex = (index, color, paymentStatus, date) => {
                        updatedStatus[index] = {
                            ...updatedStatus[index],
                            color,
                            paymentStatus,
                            date: date,
                        };
                    };
                    if (orderItemData[0].overallOrderStatus !== "Pending") {
                        updateStatusAtIndex(1, "Green", orderItemData[0].overallOrderStatus, (moment(orderItemData[0].orderConfirmedOn).format('DD-MM-YYYY hh:mm A')));
                    }
                    // ******************* If Order is Done ************************
                    if (orderItemData[0].overallOrderStatus === "Done") {
                        updateStatusAtIndex(1, "Green", orderItemData[0].overallOrderStatus, ("Confirm On:- " + orderDate + " " + moment.utc(orderItemData[0].orderConfirmedOn).utcOffset("+05:30").format('hh:mm A')));
                        updateStatusAtIndex(2, "Green", "", (orderDate + " " + convertToAmPm(orderItemData[0].orderTime)));
                        updateStatusAtIndex(3, "Green", "", (orderDate + " " + convertToAmPm(orderItemData[0].orderTime)));
                        updateStatusAtIndex(4, "Green", "", (orderDate + " " + moment.utc(orderItemData[0].orderDoneOn).utcOffset("+05:30").format('hh:mm A')))//formatDate(orderItemData[0].orderDoneOn)));
                    } else if (orderItemData[0].overallOrderStatus === "Confirm") {  // If Order is Confirm
                        updateStatusAtIndex(1, "Green", orderItemData[0].overallOrderStatus, (moment.utc(orderItemData[0].orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')));
                    }
                    // if (orderItemData[0].overallOrderStatus === "Done") {
                    //     updateStatusAtIndex(1, "Green", orderItemData[0].overallOrderStatus, moment(orderItemData[0].orderConfirmedOn).format("DD-MM-YYYY hh:mm A"));
                    //     updateStatusAtIndex(2, "Green", "",  moment(orderItemData[0].orderTime).format("DD-MM-YYYY hh:mm A"));
                    //     updateStatusAtIndex(3, "Green", "",  moment(orderItemData[0].orderTime).format("DD-MM-YYYY hh:mm A"));
                    //     updateStatusAtIndex(4, "Green", "",  moment(orderItemData[0].orderDoneOn).format("DD-MM-YYYY hh:mm A"));
                    // } else if (orderItemData[0].overallOrderStatus === "Confirm") {
                    //     updateStatusAtIndex(1, "Green", orderItemData[0].overallOrderStatus,  moment(orderItemData[0].orderConfirmedOn).format("DD-MM-YYYY hh:mm A"));
                    // }

                    if (orderItemData[0].serviceTypeId == 752) {
                        updatedStatus[3] = {
                            ...updatedStatus[3],
                            display: "block"
                        };
                        if (orderItemData[0].deliveryTripStatus == "Open") {
                            const deliveryTimeLabel = (
                                <label style={{ color: "black" }}>
                                    Delivery Boy Dispatch Time: <span style={{ color: "darkGreen" }}>{convertToAmPm(orderItemData[0].deliveryBoyDispatchTime)}</span>
                                </label>
                            );
                            updateStatusAtIndex(3, "Green", "", deliveryTimeLabel);
                        }
                        if (orderItemData[0].deliveredTime && orderItemData[0].deliveredTime != "") {
                            updateStatusAtIndex(4, "Green", "", (orderDate + " " + moment.utc(orderItemData[0].orderDoneOn).utcOffset("+05:30").format('hh:mm A')));//formatDate(orderItemData[0].orderDoneOn)));
                        }
                    }
                    // ******************* Check If any Item is Transferred or not *****************
                    const val = orderItemData.filter(item => item.o2KotStatus == "Transferred")
                    if (val.length > 0) {
                        updateStatusAtIndex(2, "Green", "", "");
                    }
                    // for (let i = 0; orderItemData.length > i; i++) {
                    //     if (orderItemData[i].o2KotStatus == "Transferred") {
                    //         updateStatusAtIndex(3, "Green", "", orderItemData[i].orderConfirmedOn); // Transferred Time
                    //     }
                    // }


                    setOrderStatus(updatedStatus);
                    console.log("updatedStatus", updatedStatus)
                    //}
                } catch (error) {
                    console.error("Error fetching order item data:", error);
                }
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    function convertToAmPm(time) {
        const [hours, minutes, seconds] = time.split(':').map(Number);

        const period = hours >= 12 ? 'PM' : 'AM';

        const formattedHours = hours % 12 || 12;

        const formattedTime = [
            formattedHours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0')
        ].join(':');

        return `${formattedTime} ${period}`;
    }

    // const [options, setOptions] = useState();

    // const fetchPayOptions = async (rupee) => {
    //     try {
    //         const data = await Payment.getPaymentOptions(rupee);
    //         console.log("options1", data);
    //         setOptions(data);
    //         return data;
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // };

    // const [apiConfigData, setApiConfigData] = useState([])

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const filteredData = [{
    //                 "fieldName": "isDefault",
    //                 "operatorName": "equal",
    //                 "compareValue": true
    //             }];
    //             const data = await APIConfiguration.getAPIConfigurationFilter(60, filteredData);
    //             setApiConfigData(data);
    //         }
    //         catch (error) {
    //             console.log(error);
    //         }
    //     }
    //     fetchData();
    // }, [])

    const handlePaymentBtn = async () => {
        const orderNo = orderedData[0].orderNo;
        const ord1Id = orderedData[0].ord1Id;
        navigate("/Request", {
            state: {
                orderNo,
                ord1Id
            }
        });

        // if (!window.Razorpay) {
        //     const script = document.createElement('script');
        //     script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        //     script.onload = () => {
        //         setIsRazorpayLoaded(true);
        //     };
        //     document.body.appendChild(script);
        // } else {
        //     setIsRazorpayLoaded(true);
        // }

        // if (!isRazorpayLoaded) {
        //     console.error('Razorpay SDK is not loaded yet.');
        //     return;
        // }

        // const amountToPay = orderedData[0].grandTotal - orderedData[0].paymentAmount;
        // const orderNo = orderedData[0].orderNo;

        // const data = await fetchPayOptions(amountToPay);

        // const options = {
        //     key: data.key,
        //     amount: data.amount,
        //     currency: data.currency,
        //     name: data.name,
        //     description: data.description,
        //     handler: function (response) {
        //         console.log(response)
        //         console.log("Payment is Successful\n" +
        //             "razorpay_payment_id:", response.razorpay_payment_id,
        //             "Order No:", orderNo,
        //             "Amount:", amountToPay);
        //         alert("Payment is Successful\n" +
        //             "razorpay_payment_id: " + response.razorpay_payment_id +
        //             "\nOrder No: " + orderNo +
        //             "\nAmount: " + amountToPay);
        //     },
        //     prefill: {
        //         name: 'Customer Name',
        //         email: 'customer@example.com',
        //         contact: '9039024004'
        //     },
        //     theme: {
        //         color: '#3009cc'
        //     }
        // };
        // console.log("options2", options);
        // const rzp1 = await new window.Razorpay(options);
        // rzp1.open();

        // *******************************
        // const currentDate = new Date();
        const formattedDate = await CurrentDateAndTime.getCurrentDateTime() //currentDate.toISOString();

        const storedArrayString = localStorage.getItem('accountLogin');
        const loginUser = JSON.parse(storedArrayString);

        const updatedValues = [
            {
                operationType: 0,
                path: "overallOrderStatus",
                op: "Add",
                from: "string",
                value: "Confirm"
            },
            {
                operationType: 0,
                path: "orderConfirmedBy",
                op: "Add",
                from: "string",
                value: 0
            },
            {
                operationType: 0,
                path: "orderConfirmedOn",
                op: "Add",
                from: "string",
                value: formattedDate
            },
            {
                operationType: 0,
                path: "paymentStatus",
                op: "Add",
                from: "string",
                value: "Confirm"
            },
            {
                operationType: 0,
                path: "paymentConfirmedBy",
                op: "Add",
                from: "string",
                value: 0
            },
            {
                operationType: 0,
                path: "paymentConfirmedOn",
                op: "Add",
                from: "string",
                value: formattedDate
            },
        ]
        await Order.updateOrderPartially(updatedValues, orderedData[0].ord1Id);
        //fetchData();
    }

    const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);

    useEffect(() => {
    }, []);

    const CancelOrderBtn = async () => {
        //alert(orderedData[0].overallOrderStatus)
        //alert("Are you sure ? You want to cancel this order", "Yes", "No")
        if (orderedData[0].overallOrderStatus === "Cancel") {
            alert("Your Order is already Cancelled");
            return false;
        }
        else if (orderedData[0].overallOrderStatus === "Done") {
            alert("Your Order is Confirm, Cannot Cancel this Order");
            return false;
        }
        else if (orderedData[0].overallOrderStatus === "Confirm") {
            alert("Your Order is Confirm, Cannot Cancel this Order");
            return false;
        }

        if (window.confirm("Are you sure you want to cancel this order?")) {

            const filteredData = [{
                operationType: 0,
                path: "tableStatus",
                op: "Add",
                from: "string",
                value: "Free"
            }]
            const data = cancelOrder();
            if (data === true)
                if (orderedData[0].serviceTypeId == 751)
                    await Table.updateTablePartially(filteredData, orderedData[0].tableId);
        }
    }
    const [cancelBtn, setCancelBtn] = useState(true);

    const cancelOrder = async () => {
        if (orderedData[0].overallOrderStatus === "Pending") {
            const updatedValues = [
                {
                    operationType: 0,
                    path: "overallOrderStatus",
                    op: "Add",
                    from: "string",
                    value: "Cancel"
                }
            ];
            const isSuccess = await OrderAPI.updateOrderPartially(updatedValues, orderedData[0].ord1Id);
            if (isSuccess)
                alert("Your order has been Cancelled");
            setCancelBtn(false);
            return true;
            //navigate("/MyAccountPage");
        }
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    function convertTimeTo12HourFormat(time) {
        // Split the input time string to get hours, minutes, and seconds
        const [hours, minutes, seconds] = time.split(':').map(Number);

        // Determine the period (AM/PM)
        const period = hours >= 12 ? 'PM' : 'AM';

        // Adjust hours for 12-hour format
        const adjustedHours = hours % 12 || 12; // Converts 0 to 12 for midnight

        // Return the formatted time
        return `${String(adjustedHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
    }

    // Assuming orderedData is defined somewhere in your code
    <div className="flex-start">
        <label className="ques">Delivery Time Slot: &nbsp;</label>
        <label className="ans">{orderedData && orderedData.length > 0 && timeFormat(orderedData[0].deliveryTime)}</label>
    </div>

    const CallButtonClick = (accMobileNo) => {
        try {
            const phoneNumber = { accMobileNo };
            const message = JSON.stringify({ type: 'call', phoneNumber });
            window.ReactNativeWebView.postMessage(message);
        }
        catch (error) {
            const phoneNumber = { accMobileNo };
            window.location.href = `tel:${phoneNumber}`
        }
    }

    const handleJustPayPayment = async () => {
        if (cancelBtn) {
            setLoading(true);
            //const orderedData[0] = orderedData.find(item => item.ord1Id === ord1Id);

            if (!orderedData[0]) {
                console.error('Order not found');
                return;
            }

            let orderNo = orderedData[0].orderNo;
            const balAmt = Number(orderedData[0].grandTotal - ((orderedData[0].o1CancelledAmt || 0) + orderedData[0].paymentAmount));

            // ******************* Payment Gateway ********************
            const filteredData = [{
                fieldName: "ord1Id",
                operatorName: "Equal",
                compareValue: orderedData[0].ord1Id,
            }]

            const dateTime = new Date().toISOString();

            const previousPaymentDetails = await Payment.getPaymentGatewayDetailsFilter(filteredData)

            let paymentData;

            if (previousPaymentDetails && previousPaymentDetails.length > 0) {
                const spiltData = Number((previousPaymentDetails[0].orderNo).split("_")[1]) || 0;

                orderNo = `${orderedData[0].orderNo}_${spiltData + 1}`

                paymentData = {
                    ord1Id: orderedData[0].ord1Id,
                    orderNo: orderNo,
                    orderRefNo: orderedData[0].orderSeriesId,
                    date: dateTime,
                    amount: balAmt,
                    apiId: 0,
                    apiName: "",
                    pgOrderId: "",
                    pgOrderStatus: "",
                    pgTrackingId: "",
                    pgBankRefNo: "",
                    pgFailureMessage: "",
                    pgPaymentMode: "",
                    pgCardName: "",
                    pgStatusCode: "",
                    pgStatusMessage: "",
                    transactionDetail: "",
                    remarks: "",
                }
            }
            else {
                orderNo = orderedData[0].orderNo;
                paymentData = {
                    ord1Id: orderedData[0].ord1Id,
                    orderNo: orderNo,
                    orderRefNo: orderedData[0].orderSeriesId,
                    date: dateTime,
                    amount: balAmt,
                    apiId: 0,
                    apiName: "",
                    pgOrderId: "",
                    pgOrderStatus: "",
                    pgTrackingId: "",
                    pgBankRefNo: "",
                    pgFailureMessage: "",
                    pgPaymentMode: "",
                    pgCardName: "",
                    pgStatusCode: "",
                    pgStatusMessage: "",
                    transactionDetail: "",
                    remarks: "",
                }
            }
            const insertedData = await Payment.insertPayment(paymentData);
            // localStorage.setItem("JusPayOrderNo", orderNo);

            // ***********************************
            const protocol = window.location.protocol;
            const hostname = window.location.hostname;
            const port = window.location.port;

            const fullUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

            // const returnUrl = `${fullUrl}/Response`;
            const returnUrl = `https://care.mothershut.com/Response`;

            const data = { orderNo, balAmt, returnUrl };
            const apiUrl = `${WebApiUrl}/api/JusPayPayment/initiateJuspayPayment`;
            if ((balAmt && balAmt != 0) || (orderedData && orderedData[0].overallOrderStatus != "Cancel")) {
                try {
                    const response = await axios.post(apiUrl, data, { withCredentials: true });

                    if (response.data && response.data.payment_links && response.data.payment_links.web) {
                        const url = response.data.payment_links.web;
                        console.log("orderNo", orderNo);

                        // const windowName = 'newWindow'; // Name of the new window
                        // const windowFeatures = 'width=600,height=400,scrollbars=yes,resizable=yes'; // Features for the new window

                        // // Open a new window with the specified URL and features
                        // window.open(url, windowName, windowFeatures);

                        window.location.href = url;
                        localStorage.setItem("JusPayOrderNo", orderNo);
                        localStorage.setItem("Ord1Id", orderedData[0].ord1Id);
                    } else {
                        console.error('Payment links not found in response');
                    }
                    setLoading(false);
                } catch (error) {
                    console.error('Error initiating payment:', error);
                    setLoading(false);
                }
            }
            setLoading(false);
        }
    };

    return (
        <div>
            <div>
                {/* <div className="heading">
                     <div className="head-display space-between">
                    <div className="space-evenly">
                        <Link to="/OrderTakerView">
                            <i className="fa-solid fa-arrow-left icon-white" style={{ cursor: "pointer" }}></i>
                        </Link>
                        <h2 className="head-title">Payment of (931703)</h2>
                    </div>
                    <label style={{color:"orange"}}>FEEDBACK</label>
                    <i className="fa-solid fa-star" style={{ color: "orange" }}></i>
                </div> 
                </div> */}
                <div style={{ backgroundColor: "whitesmoke", height: "75%", marginBottom: "10px", marginTop: "0px" }}>
                    <div className="flex-center">
                        <label className="ques">Track your Order Status</label><br />
                    </div>
                    <div className="mt-2 flex-center">
                        <img src="https://c8.alamy.com/comp/2GWXAFW/little-girl-tries-to-be-a-chef-serving-food-2GWXAFW.jpg" alt="DeliveryBoyImage" style={{ height: "100px", width: "100px" }}></img><br />
                    </div>
                    <div className="" style={{ marginLeft: "0px", display: "flex", flexDirection: "column", alignItems: "center" }}>

                        {/*eslint-disable-next-line array-callback-return*/}
                        {orderStatus.map((status, index) => (
                            <div style={{ display: status.display, width: "90%" }}>
                                <div className="flex-start" key={index} style={{ marginTop: "20px", }}>
                                    <div className="iconDiv flex-center" style={{ backgroundColor: status.color, alignItems: "center" }}>
                                        <i className={status.icon} style={{}}></i>
                                    </div>
                                    <div className="statusDiv">
                                        <label className="ques">{status.statusName}</label>
                                        {(() => {
                                            if (index === 1) {
                                                return (
                                                    <div>
                                                        <label className="ques">Your Order is {status.paymentStatus}</label><br />
                                                        {orderedData && orderedData.length > 0 && (orderedData[0].paymentStatus == null | orderedData[0].paymentStatus == "Pending") && (
                                                            <div>
                                                                {status.paymentStatus === "Pending" && (
                                                                    <>
                                                                        <label className="ques"> to confirm order call {CompanyDetail && CompanyDetail?.mobileNo}. </label><br />
                                                                        <label className="ques"> ---- OR ---- </label><br />
                                                                        <label className="ques"> Pay now to auto confirm this order</label><br />
                                                                    </>
                                                                )}
                                                                {/* <label className="ques"> Your Payment is pending <span className="ques" style={{ color: "red" }} disabled={!isRazorpayLoaded} onClick={() => handlePaymentBtn()}>Click Here</span> to pay Online</label> */}
                                                                <label className="ques" style={{ fontSize: "16px", color: "darkblue" }}> Your Payment is pending <span className="ques" style={{ color: "red", fontSize: "16px" }} onClick={() => handleJustPayPayment()}>Click Here</span> to pay Online.</label>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                            else if (index === 3) {
                                                if (orderedData && orderedData.length > 0 && orderedData[0].serviceTypeId == 752) {
                                                    return (
                                                        <div style={{ display: orderedData[0].deliveryBoyContactNo == null ? "none" : "block" }}>
                                                            <label className="ques" style={{ color: "#447577" }}>Delivery Boy Name : <span className="ans">{orderedData[0].deliveryBoyName}</span></label><br />
                                                            <label className="ques" style={{ color: "#447577" }}>Delivery Boy Contact No : <span className="ans">{orderedData[0].deliveryBoyContactNo}</span></label>&nbsp;&nbsp;
                                                            <i className="fa-solid fa-phone-flip mt-1" style={{ color: "green", fontSize: "14px" }} onClick={() => CallButtonClick(orderedData[0].deliveryBoyContactNo)}></i>
                                                        </div>
                                                    )
                                                }
                                            }
                                            return null;
                                        })()}
                                        <label className="ques" style={{ color: "green", fontWeight: "bold", display: "flex" }}> {status.date}</label>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="mt-4" style={{ backgroundColor: "white", borderRadius: "5px", marginTop: "15px", marginRight: "10px", padding: "10px 10px", width: "90%", marginBottom: "20px" }}>
                            <div style={{ backgroundColor: "whitesmoke", padding: "10px 15px", marginBottom: "10px" }}>
                                <div className="flex-start">
                                    <label className="ques">Order No : &nbsp;</label>
                                    <label className="ans">{orderedData && orderedData.length > 0 && orderedData[0].orderNo}</label>
                                </div>
                                <div className="flex-start">
                                    <label className="ques">Order Date : &nbsp;</label>
                                    <label className="ans">{orderedData && orderedData.length > 0 && moment(orderedData[0].orderDate).format("DD-MM-YYYY")}</label>
                                </div>
                                <div className="flex-start">
                                    <label className="ques">Service Type : &nbsp;</label>
                                    <label className="ans">{orderedData && orderedData.length > 0 && orderedData[0].serviceTypeName}</label>
                                </div>
                                <div className="flex-start">
                                    <label className="ques">Del Time Slot : &nbsp;</label>
                                    {/* <label className="ans">{orderedData && orderedData.length > 0 && orderedData[0].deliveryTime}</label> */}
                                    <label className="ans">{orderedData && orderedData.length > 0 && timeFormat(orderedData[0].deliveryTime)}</label>
                                </div>
                                <div className="flex-start">
                                    <label className="ques">KDTT : &nbsp;</label>
                                    {/* <label className="ans">{orderedData && orderedData.length > 0 && moment(orderedData[0].kotDispatchTargetTime).format("hh:mm A")}</label> */}
                                    <label className="ans">{orderedData && orderedData.length > 0 && orderedData[0].kotDispatchTargetTime != "" && convertTimeTo12HourFormat(orderedData[0].kotDispatchTargetTime)}</label>
                                </div>
                                <div className="flex-start">
                                    <label className="ques">Order Date : &nbsp;</label>
                                    <label className="ans">
                                        {orderedData && orderedData.length > 0 &&
                                            moment(orderedData[0].orderConfirmedOn).format("DD-MM-YYYY hh:mm A")
                                        }
                                    </label>
                                </div>
                            </div>
                            {(() => {
                                //if (parsedData !== null) {
                                //    return parsedData.map((cart, index) => (
                                //        <div>
                                //            <div className="row" style={{ margin: "5%", display: "flex", justifyContent: "space-evenly" }}>
                                //                <div className="col-sm-2 ">
                                //                    <img src={cart.imageSource} alt={cart.imageName} style={{ width: "70px", height: "70px", borderRadius: "5px" }}></img>
                                //                </div>
                                //                <div className="col-sm-6 ">
                                //                    <div className="row">
                                //                        <label><strong>{cart.imageName}</strong></label>
                                //                        <label>Qty: {cart.quantity} x Rs. {cart.price} /-</label>
                                //                    </div>
                                //                </div>
                                //                <div className="col-sm-3 " style={{ display: "flex", justifyContent: "end" }}>
                                //                    <h6>Rs <label >{cart.quantity * cart.price}</label> /-</h6>
                                //                </div>
                                //            </div>
                                //            <hr />
                                //        </div>
                                //    ))
                                //}
                            })()}
                            {/*<hr style={{ height: "3px", backgroundColor: "grey", opacity: "inherit" }} />*/}
                            {orderedData && orderedData.map((data, index) => (
                                <div key={index}>
                                    <div style={{ padding: "0px", borderBottom: "1px solid #cecece" }}>
                                        <div className="space-between" style={{ padding: "5px" }}>
                                            <div className="flex-start" style={{ alignItems: "center" }}>
                                                <div className="">
                                                    <img src={`${imageBaseUrl}${data.d1ItemImageId || 0}`}
                                                        alt="" style={{ width: "65px", height: "65px", borderRadius: "5px", marginTop: "8px" }}></img>
                                                </div>
                                                <div className="flex-start" style={{ marginLeft: "10px" }}>
                                                    <div className="p-1" style={{ fontSize: "13px" }}>
                                                        <label style={{}}>{data.d2ItemName}</label><br />
                                                        <label>Qty: {data.qty} x ₹ {data.rate}</label>
                                                        <div className="flex-start" style={{ display: data.itemSuggestion == "" ? "none" : "block" }}>
                                                            <label style={{ fontSize: "10px", }}>Item suggesstion :- &nbsp; </label>
                                                            <label style={{ fontSize: "10px", color: "darkGreen" }}>{data.itemSuggestion}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="ques">₹&nbsp;{data.qty * data.rate}</label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div style={{ borderRadius: "5px", backgroundColor: "#f9f9f9", display: "flex", justifyContent: "flex-end", alignItems: "flex-end", flexDirection: "column", padding: "10px", margin: "10px" }}>
                                <p style={{ margin: "0px", fontSize: "14px" }}>Sub Amount: ₹ {orderedData && orderedData.length > 0 && orderedData[0].o1TotalAmt}</p>
                                <p style={{ margin: "0px", fontSize: "14px" }}>Delivery Amount: ₹ 00.00</p>
                                <p style={{ margin: "0px", fontSize: "14px" }}>Total Amount: ₹ {orderedData && orderedData.length > 0 && orderedData[0].o1TotalAmt}</p><br />
                            </div>

                            {(() => {
                                if (orderedData && orderedData.length > 0 && orderedData[0].serviceTypeId === 752)
                                    return (
                                        <div style={{ borderRadius: "5px", backgroundColor: "#f9f9f9", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", padding: "10px", margin: "10px" }}>
                                            {/* <p style={{ margin: "0px", display: orderedData && orderedData.length > 0 ? "block" : "none" }} ><u>Deliveries on this trip :-</u></p> */}
                                            <p style={{ margin: "0px", display: orderedData && orderedData.length > 0 ? "block" : "none" }} ><u>Delivery Address :-</u></p>
                                            <p style={{ margin: "0px", fontSize: "13px" }}>
                                                {orderedData && orderedData.length > 0 ? (
                                                    orderedData[0].accAddress || ""
                                                ) : (
                                                    ""
                                                )}
                                            </p>

                                        </div>
                                    )
                            })()}
                            <div style={{ display: (orderedData && orderedData.length > 0 && orderedData[0].overallOrderStatus == "Done") || (orderedData && orderedData.length > 0 && orderedData[0].overallOrderStatus == "Confirm") ? "none" : "block" }}>
                                <div className="flex-center">
                                    {cancelBtn ?
                                        <button className="redBtn" onClick={CancelOrderBtn}>Cancel Order</button>
                                        :
                                        <label className="" style={{ width: "80%", fontSize: "14px", textAlign: "center", backgroundColor: "darkred", color: "white", fontSize: "14px", padding: "2px" }}>Order has been Cancelled</label>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
            {mainModal && (
                <MainModal
                    fetchData={fetchData}
                    OrderType={OrderType}
                    closemainmodal={closemainmodal}
                />
            )}
        </div>
    );
};

export default TrackOrderStatus;
