import React, { useState } from "react";

const VariableProductModal = (props) => {
  // const [selectedOption, setSelectedOption] = useState("option1");

  // const handleOptionChange = (value) => {
  //   setSelectedOption(value);
  // };

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [itemName, setItemName] = useState();

  const handleOptionChange = (optionId, itemId, iName) => {
    setItemName(iName);
    setSelectedOption(optionId);
    setSelectedItemId(itemId);
  };

  const data = props.data;
  const parantData = props.parantData;

  const { product, handleDecrementValue, buttonClickCounts, handleIncrementValue, AddToCart, RemoveFromCart, cartData, isTimeWithinRange, selectedService, isDateWithinRange
  } = props

  // // console.log(parantData);

  return (
    <div className="variableproduct-modal">
      <i
        className="fa-solid fa-xmark variable-cross"
        onClick={props.variableClose}
      ></i>
      <div className="vp-heading-box">
        <i className="fa-solid fa-circle-stop"></i>
        {parantData === undefined ||
          parantData === 0 ||
          parantData === "" ? null : (
          <div className="VP-heading">{parantData.name}</div>
        )}
      </div>
      <div className="vp-modal-content">
        {parantData === undefined ||
          parantData === 0 ||
          parantData === "" ? null : (
          <div className="VP-heading">Choose Your {parantData.name}</div>
        )}

        <div className="vp-modal-content-main">
          {data.map((product, index) => (
            <div key={index}>
              <div className="Variable_productname">
                {/* <input
                  type="radio"
                  name="selectedOption"
                  id={`option${index + 1}`}
                  checked={selectedOption === `option${index + 1}`}
                  onChange={() => handleOptionChange(`option${index + 1}`, item.d2Id, item.name)}
                /> */}
                <label htmlFor={`option${index + 1}`} className="VP-heading-2">
                  {product.name}
                </label>
              </div>


              {/* table order */}
              {selectedService == "751" && (
                (
                  <>
                    {product == null ? "" : product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                      <p className="ourmenu-productnotavli">Out Of stock</p>
                    )
                      :
                      (
                        <>
                          {product.restoAvailableTime.length > 0 && product.availableForTableOrder && product.restoAvailableTime.length > 0 === true ?
                            (
                              <>
                                {product.restoAvailableTime.some((timeSlot) => {
                                  // Check if both availableTimefrom and availableTimeTo are null
                                  if (timeSlot.availableTimefrom === null && timeSlot.availableTimeTo === null) {
                                    return true; // Consider product available if both are null
                                  }
                                  // Otherwise, check if availableTimefrom and availableTimeTo are defined and within range
                                  return timeSlot.availableTimefrom && timeSlot.availableTimeTo && isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo);
                                }) ? (
                                  product.restoAvailableTime.map((timeSlot, index) => (
                                    <span key={index}>
                                      {timeSlot.availableTimefrom && timeSlot.availableTimeTo ? (
                                        <p className="menu-card-price">
                                          {isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) ? (
                                            <>
                                              {product != null && product.availableForTableOrder === true && (
                                                <div className="quantity-box">
                                                  <p className="menu-card-price">
                                                    <div className="quantity">
                                                      <button
                                                        style={{ paddingBottom: "4px" }}
                                                        className="quantity__minus"
                                                        onClick={() =>
                                                          handleDecrementValue(
                                                            product.d2Id,
                                                            product.name,
                                                            product.attributeParentId,
                                                            product)
                                                        }
                                                      >
                                                        -
                                                      </button>
                                                      <input
                                                        className="quantity__input"
                                                        type="text"
                                                        value={buttonClickCounts[product.d2Id] || 0}
                                                        readOnly
                                                      />
                                                      <button
                                                        className="quantity__plus"
                                                        onClick={() =>
                                                          handleIncrementValue(
                                                            product.d2Id,
                                                            product.name,
                                                            product.isAttributeItem,
                                                            product.attributeParentId,
                                                            product
                                                          )
                                                        }
                                                      >
                                                        +
                                                      </button>
                                                    </div>

                                                  </p>
                                                  {product.restoAvailableQuantity === cartData.find(item1 =>
                                                    (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                                  )?.quantity && product.stockCheck === 1 ? (
                                                    <p className="ourmenu-productnotavli">Out Of stock</p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </p>
                                      ) : (
                                        <div className="quantity-box">
                                          <p className="menu-card-price">
                                            <div className="quantity">
                                              <button
                                                style={{ paddingBottom: "4px" }}
                                                className="quantity__minus"
                                                onClick={() =>
                                                  handleDecrementValue(
                                                    product.d2Id,
                                                    product.name,
                                                    product.attributeParentId,
                                                    product)
                                                }
                                              >
                                                -
                                              </button>
                                              <input
                                                className="quantity__input"
                                                type="text"
                                                value={buttonClickCounts[product.d2Id] || 0}
                                                readOnly
                                              />
                                              <button
                                                className="quantity__plus"
                                                onClick={() =>
                                                  handleIncrementValue(
                                                    product.d2Id,
                                                    product.name,
                                                    product.isAttributeItem,
                                                    product.attributeParentId,
                                                    product
                                                  )
                                                }
                                              >
                                                +
                                              </button>
                                            </div>

                                          </p>
                                          {product.restoAvailableQuantity === cartData.find(item1 =>
                                            (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                          )?.quantity && product.stockCheck === 1 ? (
                                            <p className="ourmenu-productnotavli">Out Of stock</p>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      )}
                                    </span>
                                  ))
                                ) : (
                                  <p className="menu-card-price" style={{ color: 'red', marginBottom: "4px" }}>Not Available Now </p>
                                )}

                              </>
                            ) : (
                              <p className="menu-card-price">
                                {product != null && product.availableForTableOrder === true && (
                                  <div className="quantity-box">
                                    <p className="menu-card-price">
                                      <div className="quantity">
                                        <button
                                          style={{ paddingBottom: "4px" }}
                                          className="quantity__minus"
                                          onClick={() =>
                                            handleDecrementValue(
                                              product.d2Id,
                                              product.name,
                                              product.attributeParentId,
                                              product
                                            )
                                          }
                                        >
                                          -
                                        </button>
                                        <input
                                          className="quantity__input"
                                          type="text"
                                          value={buttonClickCounts[product.d2Id] || 0}
                                          readOnly
                                        />
                                        <button
                                          className="quantity__plus"
                                          onClick={() =>
                                            handleIncrementValue(
                                              product.d2Id,
                                              product.name,
                                              product.isAttributeItem,
                                              product.attributeParentId,
                                              product
                                            )
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </p>
                                    {/* Check if the product is out of stock */}
                                    {product.restoAvailableQuantity ===
                                      cartData.find(
                                        (item1) =>
                                          item1.d2ItemId === product.d2Id ||
                                          item1.d2ItemId === product.d2ItemId
                                      )?.quantity && product.stockCheck === 1 ? (
                                      <p className="ourmenu-productnotavli">Out Of stock</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </p>

                            )}

                          {product.restoAvailableStartDate && product.restoAvailableEndDate ? (
                            <p className="menu-card-price">
                              Available Date:
                              {isDateWithinRange(product.restoAvailableStartDate, product.restoAvailableEndDate) ? (
                                <p style={{ color: 'green', marginLeft: '10px' }}>Available Today!</p>
                              ) : (
                                <p style={{ color: 'red', marginLeft: '10px' }}>Not Available Today</p>
                              )}
                            </p>
                          ) : (
                            <></>
                          )}

                          {product != null && product.availableForTableOrder === false && (
                            <p className="ourmenu-productnotavli">Not Available For Table Order</p>
                          )}


                        </>

                      )}

                  </>
                )
              )}

              {/* Home delivery order */}
              {selectedService == "752" && (
                <>
                  {product == null ? "" : product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                    <p className="ourmenu-productnotavli">Out Of stock</p>
                  ) : (
                    <>
                      {product.restoAvailableTime && product.restoAvailableTime.length > 0 && product.availableForHomeDelivery === true ? (
                        <>
                          {product.restoAvailableTime.some((timeSlot) => {
                            const available = timeSlot.availableTimefrom && timeSlot.availableTimeTo;
                            return available ? isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) : true;
                          }) ? (
                            product.restoAvailableTime.map((timeSlot, index) => (
                              <span key={index}>
                                {timeSlot.availableTimefrom && timeSlot.availableTimeTo ? (
                                  isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) && (
                                    <div className="quantity-box">
                                      <p className="menu-card-price">
                                        <div className="quantity">
                                          <button
                                            style={{ paddingBottom: "4px" }}
                                            className="quantity__minus"
                                            onClick={() =>
                                              handleDecrementValue(
                                                product.d2Id,
                                                product.name,
                                                product.attributeParentId,
                                                product)
                                            }
                                          >
                                            -
                                          </button>
                                          <input
                                            className="quantity__input"
                                            type="text"
                                            value={buttonClickCounts[product.d2Id] || 0}
                                            readOnly
                                          />
                                          <button
                                            className="quantity__plus"
                                            onClick={() =>
                                              handleIncrementValue(
                                                product.d2Id,
                                                product.name,
                                                product.isAttributeItem,
                                                product.attributeParentId,
                                                product
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>

                                      </p>
                                      {product.restoAvailableQuantity === cartData.find(item1 =>
                                        (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                      )?.quantity ? (
                                        <p className="ourmenu-productnotavli">Out Of stock</p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <div className="quantity-box">
                                    <p className="menu-card-price">
                                      <div className="quantity">
                                        <button
                                          style={{ paddingBottom: "4px" }}
                                          className="quantity__minus"
                                          onClick={() =>
                                            handleDecrementValue(
                                              product.d2Id,
                                              product.name,
                                              product.attributeParentId,
                                              product)
                                          }
                                        >
                                          -
                                        </button>
                                        <input
                                          className="quantity__input"
                                          type="text"
                                          value={buttonClickCounts[product.d2Id] || 0}
                                          readOnly
                                        />
                                        <button
                                          className="quantity__plus"
                                          onClick={() =>
                                            handleIncrementValue(
                                              product.d2Id,
                                              product.name,
                                              product.isAttributeItem,
                                              product.attributeParentId,
                                              product
                                            )
                                          }
                                        >
                                          +
                                        </button>
                                      </div>

                                    </p>
                                    {product.restoAvailableQuantity === cartData.find(item1 =>
                                      (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                    )?.quantity ? (
                                      <p className="ourmenu-productnotavli">Out Of stock</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </span>
                            ))
                          ) : (
                            <p className="menu-card-price" style={{ color: 'red', marginBottom: "4px" }}>Not Available Now</p>
                          )}
                        </>
                      ) : (
                        <p className="menu-card-price">
                          {product != null && product.availableForTableOrder === true && (
                            <div className="quantity-box">
                              <p className="menu-card-price">
                                <div className="quantity">
                                  <button
                                    style={{ paddingBottom: "4px" }}
                                    className="quantity__minus"
                                    onClick={() =>
                                      handleDecrementValue(
                                        product.d2Id,
                                        product.name,
                                        product.attributeParentId,
                                        product
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    className="quantity__input"
                                    type="text"
                                    value={buttonClickCounts[product.d2Id] || 0}
                                    readOnly
                                  />
                                  <button
                                    className="quantity__plus"
                                    onClick={() =>
                                      handleIncrementValue(
                                        product.d2Id,
                                        product.name,
                                        product.isAttributeItem,
                                        product.attributeParentId,
                                        product
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </p>
                              {/* Check if the product is out of stock */}
                              {product.restoAvailableQuantity ===
                                cartData.find(
                                  (item1) =>
                                    item1.d2ItemId === product.d2Id ||
                                    item1.d2ItemId === product.d2ItemId
                                )?.quantity && product.stockCheck === 1 ? (
                                <p className="ourmenu-productnotavli">Out Of stock</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </p>
                      )}

                      {product.restoAvailableStartDate && product.restoAvailableEndDate ? (
                        <p className="menu-card-price">
                          Available Date:
                          {isDateWithinRange(product.restoAvailableStartDate, product.restoAvailableEndDate) ? (
                            <p style={{ color: 'green', marginLeft: '10px' }}>Available Today!</p>
                          ) : (
                            <p style={{ color: 'red', marginLeft: '10px' }}>Not Available Today</p>
                          )}
                        </p>
                      ) : (
                        <></>
                      )}

                      {product != null && product.availableForHomeDelivery === false && (
                        <p className="ourmenu-productnotavli">Not Available For Home Delivery</p>
                      )}
                    </>
                  )}
                </>
              )}

              {/* Pick Up order */}
              {selectedService == "753" && (
                <>
                  {product == null ? "" : product.restoAvailableQuantity === 0 && product.stockCheck === 1 ? (
                    <p className="ourmenu-productnotavli">Out Of stock</p>
                  ) : (
                    <>
                      {product.restoAvailableTime && product.restoAvailableTime.length > 0 && product.availableForPickUp === true ? (
                        <>
                          {product.restoAvailableTime.some((timeSlot) => {
                            const available = timeSlot.availableTimefrom && timeSlot.availableTimeTo;
                            return available ? isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) : true;
                          }) ? (
                            product.restoAvailableTime.map((timeSlot, index) => (
                              <span key={index}>
                                {timeSlot.availableTimefrom && timeSlot.availableTimeTo ? (
                                  isTimeWithinRange(timeSlot.availableTimefrom, timeSlot.availableTimeTo) && (
                                    <div className="quantity-box">
                                      <p className="menu-card-price">
                                        <div className="quantity">
                                          <button
                                            style={{ paddingBottom: "4px" }}
                                            className="quantity__minus"
                                            onClick={() =>
                                              handleDecrementValue(
                                                product.d2Id,
                                                product.name,
                                                product.attributeParentId,
                                                product)
                                            }
                                          >
                                            -
                                          </button>
                                          <input
                                            className="quantity__input"
                                            type="text"
                                            value={buttonClickCounts[product.d2Id] || 0}
                                            readOnly
                                          />
                                          <button
                                            className="quantity__plus"
                                            onClick={() =>
                                              handleIncrementValue(
                                                product.d2Id,
                                                product.name,
                                                product.isAttributeItem,
                                                product.attributeParentId,
                                                product
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>

                                      </p>
                                      {product.restoAvailableQuantity === cartData.find(item1 =>
                                        (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                      )?.quantity && product.stockCheck === 1 ? (
                                        <p className="ourmenu-productnotavli">Out Of stock</p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <div className="quantity-box">
                                    <p className="menu-card-price">
                                      <div className="quantity">
                                        <button
                                          style={{ paddingBottom: "4px" }}
                                          className="quantity__minus"
                                          onClick={() =>
                                            handleDecrementValue(
                                              product.d2Id,
                                              product.name,
                                              product.attributeParentId,
                                              product)
                                          }
                                        >
                                          -
                                        </button>
                                        <input
                                          className="quantity__input"
                                          type="text"
                                          value={buttonClickCounts[product.d2Id] || 0}
                                          readOnly
                                        />
                                        <button
                                          className="quantity__plus"
                                          onClick={() =>
                                            handleIncrementValue(
                                              product.d2Id,
                                              product.name,
                                              product.isAttributeItem,
                                              product.attributeParentId,
                                              product
                                            )
                                          }
                                        >
                                          +
                                        </button>
                                      </div>

                                    </p>
                                    {product.restoAvailableQuantity === cartData.find(item1 =>
                                      (item1.d2ItemId === product.d2Id || item1.d2ItemId === product.d2ItemId)
                                    )?.quantity && product.stockCheck === 1 ? (
                                      <p className="ourmenu-productnotavli">Out Of stock</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </span>
                            ))
                          ) : (
                            <p className="menu-card-price" style={{ color: 'red', marginBottom: "4px" }}>Not Available Now</p>
                          )}
                        </>
                      ) : (
                        <p className="menu-card-price">
                          {product != null && product.availableForTableOrder === true && (
                            <div className="quantity-box">
                              <p className="menu-card-price">
                                <div className="quantity">
                                  <button
                                    style={{ paddingBottom: "4px" }}
                                    className="quantity__minus"
                                    onClick={() =>
                                      handleDecrementValue(
                                        product.d2Id,
                                        product.name,
                                        product.attributeParentId,
                                        product
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    className="quantity__input"
                                    type="text"
                                    value={buttonClickCounts[product.d2Id] || 0}
                                    readOnly
                                  />
                                  <button
                                    className="quantity__plus"
                                    onClick={() =>
                                      handleIncrementValue(
                                        product.d2Id,
                                        product.name,
                                        product.isAttributeItem,
                                        product.attributeParentId,
                                        product
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </p>
                              {/* Check if the product is out of stock */}
                              {product.restoAvailableQuantity ===
                                cartData.find(
                                  (item1) =>
                                    item1.d2ItemId === product.d2Id ||
                                    item1.d2ItemId === product.d2ItemId
                                )?.quantity && product.stockCheck === 1 ? (
                                <p className="ourmenu-productnotavli">Out Of stock</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </p>
                      )}

                      {product.restoAvailableStartDate && product.restoAvailableEndDate ? (
                        <p className="menu-card-price">
                          Available Date:
                          {isDateWithinRange(product.restoAvailableStartDate, product.restoAvailableEndDate) ? (
                            <p style={{ color: 'green', marginLeft: '10px' }}>Available Today!</p>
                          ) : (
                            <p style={{ color: 'red', marginLeft: '10px' }}>Not Available Today</p>
                          )}
                        </p>
                      ) : (
                        <></>
                      )}

                      {product != null && product.availableForPickUp === false && (
                        <p className="ourmenu-productnotavli">Not Available For Pick Up </p>
                      )}
                    </>
                  )}
                </>
              )}



              <div
                className="vp-price-box"
                style={{ width: "12%", justifyContent: "center" }}
              >
                <i
                  style={{ marginRight: "3px", marginTop: "2px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>
                {product.salePrice}{" "}
              </div>
            </div>
          ))}

          {/* <button
            className="vp-add-btn"
            onClick={() => {
              if (selectedItemId) {
                props.handleIncrementValue(selectedItemId, itemName);
              }
            }}
            disabled={!selectedOption}  // Disable button if no option is selected
          >
            Add to Cart
          </button> */}
        </div>

      </div>
    </div>
  );
};

export default VariableProductModal;
