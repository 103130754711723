import React, { useContext } from "react";
import MyContext from "./MyContext";
import { useLocation } from "react-router-dom";
// import image from ""

const CartProduct = (props) => {
  const { product, addingItemInCart, RemoveItemInCart } = props;
  const { buttonClickCounts, setButtonClickCounts, imageBaseUrl, loginData } = useContext(MyContext);
  const navigate = useLocation();

  const handleIncrementValue = (id, ParentId) => {
    addingItemInCart(id);

    const currentCount = buttonClickCounts[id] || 0;
    const currentCount1 = ParentId !== 0 ? buttonClickCounts[ParentId] || 0 : null;

    // Prepare the updated state object
    const updatedCounts = {
      ...buttonClickCounts,
      [id]: currentCount + 1,
    };

    // Only add ParentId if it's not 0
    if (ParentId !== 0) {
      updatedCounts[ParentId] = currentCount1 + 1;
    }

    setButtonClickCounts(updatedCounts);


  };

  const handleDecrementValue = (id, ParentId) => {
    RemoveItemInCart(id);
    const currentCount = buttonClickCounts[id] || 0;
    let newButtonClickCounts = { ...buttonClickCounts };

    // Decrease count for id
    if (currentCount > 0) {
      newButtonClickCounts[id] = currentCount - 1;
    }

    // Decrease count for ParentId if it's not 0 or null
    if (currentCount > 0) {
      if (ParentId !== 0 && ParentId !== null) {
        const currentParentCount = buttonClickCounts[ParentId] || 0;
        if (currentParentCount > 0) {
          newButtonClickCounts[ParentId] = currentParentCount - 1;
        }
      }

    }

    // Update state with the new counts
    setButtonClickCounts(newButtonClickCounts);

  };

  const filteredProducts = product.filter((item) => item.quantity > 0);

  const ourMenuClick = () => {
    navigate("/OurMenu");
  }

  return (
    <>
      {filteredProducts.length === 0 ? (
        <div className="mycart-empty-txt-box">
          <img className="mycart-empty-image" src="https://mothershut.com/RestoFolders/MOTHERSHUT_Supela_Bhilai/Cart_empty.png" alt="" />
          <p className="mycart-empty-txt" onClick={ourMenuClick}>Your Order cart is empty.</p>
          <p className="mycart-empty-txt" onClick={ourMenuClick}>Click to add an item.</p>
          <p className="mycart-empty-txt-2">Cooked by mothers with love and care</p>
        </div>
      ) : (
        <>
          {filteredProducts.map((product) => (
            // .filter((product) => product.d2AccountId === loginData.d2Id)
            <div key={product.id} style={{ padding: props.padding, width: props.width2, color: product.color }} className="card-cart-product  cart-image">
              <div className="card-cartimage-div">
                <img className="card-menu-image-1" src={`${imageBaseUrl}${product.itemImgId}`} alt="" />

              </div>
              <div className="card-cart-content-div">
                <div className="cart-card-detail-box">
                  <p className="card-cart-txt-1" style={{ margin: "0" }}>
                    {product.itemName}
                  </p>
                  <p className="card-cart-txt-2" style={{ margin: "0" }}>
                    <i
                      style={{ marginRight: "3px" }}
                      className="fa-solid fa-indian-rupee-sign rupees-icon"
                    ></i>
                    {product.itemPrice}
                  </p>
                </div>
                <div className="quantity">
                  <button
                    style={{ paddingBottom: "4px" }}
                    className="quantity__minus"
                    onClick={() => RemoveItemInCart(product)}
                  // onClick={() => handleDecrementValue(product.d2Id, product.attributeParentId)}
                  >
                    -
                  </button>
                  <input
                    className="quantity__input"
                    type="text"
                    value={product.quantity}
                    readOnly
                  />
                  <button
                    className="quantity__plus"
                    onClick={() => addingItemInCart(product)}
                  // onClick={()=>handleIncrementValue(product.d2Id, product.attributeParentId)}
                  >
                    +
                  </button>
                </div>
                <div className="cart-card-detail-box-2">
                  <p className="card-cart-txt-3" style={{ margin: "0" }}>
                    <i
                      style={{ marginRight: "3px" }}
                      className="fa-solid fa-indian-rupee-sign rupees-icon"
                    ></i>
                    {product.itemPrice * product.quantity}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  )
};

export default CartProduct;