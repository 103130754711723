import React, { useState, useContext, useEffect } from "react";
import "../Css/style.css";
import "../Css/tabletcss.css";
import "../Css/mobilecss.css";
import "../Css/main.css";
import "../Css/Draggable.css";
import ImageSlider from "../Component/ImageSlider";
import ProductCard from "../Component/ProductCard";
import MyContext from "../Component/MyContext";
import ItemGroup from "../API/API_SERVISES/ItemGroup";
import MainModal from "../Component/MainModal";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    imageBaseUrl, ItemGroupMaster, setItemGroupMaster,
    mainModal,
    closemainmodal,
    fetchData,
    OrderType,
    setActiveLink
  } = useContext(MyContext);



  const [itemgroupForHomePage, setItemgroupForHomePage] = useState([])

  useEffect(() => {
    setActiveLink("/")
    setItemgroupForHomePage(ItemGroupMaster)
  }, [ItemGroupMaster])

  const [searchValue, setSearchValue] = useState("");

  const searchvaluefunction = async (e) => {
    //navigate("/OurMenu")
    setSearchValue(e.target.value);

    const filteredData = [
      {
        fieldName: "name",
        operatorName: "contains",
        compareValue: e.target.value || "",
      },
    ];

    const data = await ItemGroup.GetItemGroupFilter(filteredData)
    const SearchItem = data.sort((a, b) => a.displayOrder - b.displayOrder);
    if (SearchItem) {
      setItemgroupForHomePage(SearchItem)
    }
  };


  return (
    <div>
      <ImageSlider />

      <div className="">
        <div className="search-input-homepage-box">
          <div className="container-input">
            <input
              type="text"
              placeholder="Search"
              name="text"
              className="input"
              value={searchValue}
              onChange={searchvaluefunction}
            />
            <svg
              fill="#000000"
              width="20px"
              height="20px"
              viewBox="0 0 1920 1920"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M790.588 1468.235c-373.722 0-677.647-303.924-677.647-677.647 0-373.722 303.925-677.647 677.647-677.647 373.723 0 677.647 303.925 677.647 677.647 0 373.723-303.924 677.647-677.647 677.647Zm596.781-160.715c120.396-138.692 193.807-319.285 193.807-516.932C1581.176 354.748 1226.428 0 790.588 0S0 354.748 0 790.588s354.748 790.588 790.588 790.588c197.647 0 378.24-73.411 516.932-193.807l516.028 516.142 79.963-79.963-516.142-516.028Z"
                fill-rule="evenodd"
              ></path>
            </svg>
          </div>
          <button className="homepage-srch-btn">Search</button>
        </div>

        <div className="product-card-box">
          {itemgroupForHomePage === undefined || itemgroupForHomePage === null || itemgroupForHomePage.length === 0 ? (
            <p>No items available</p>
          ) : (
            itemgroupForHomePage.sort((a, b) => a.displayOrder - b.displayOrder)
              .filter(item => item.primaryGroup === true && item.showInMenu === true).map((Item) => (
                <ProductCard
                  key={Item.d1_Id} // Make sure to include a unique key when rendering lists of elements
                  heading={Item.menuName}
                  ItemId={Item.d2_Id}
                  timing="15 MIN"
                  icon="fa-solid fa-utensils"
                  imageBaseUrl={imageBaseUrl}
                  Imageid={Item.images}
                />
              ))
          )}
        </div>
      </div>
      {/* {mainModal && <MainModal closemainmodal={closemainmodal} />} */}
      {mainModal && (
        <MainModal
          fetchData={fetchData}
          OrderType={OrderType}
          closemainmodal={closemainmodal}
        />
      )}
    </div>
  );
};

export default HomePage;

