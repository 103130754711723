import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const SMS = {

  SendSMS: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/SMS/send-sms`;
      const response = await axios.post(
        apiUrl,
        {
          to: data.to,
          message: data.message,
          smsTempAttr: data.smsTempAttr,
          smsTempVal: data.smsTempVal
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return true;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

// ************************** WhatsApp API **********************
   SendWhatsApp: async (data) => {
        try {
          const apiUrl = `${WebApiUrl}/api/SMS/send-WhatsAppMsg`;
          const response = await axios.post(
            apiUrl,
            {
              to: data.to,
              templateName: data.templateName,
              templateLanguage: data.templateLanguage,
              headerSection: data.headerSection,
              bodySection: data.bodySection,
              fileMediaId:data.fileMediaId,
            },
            {
              withCredentials: true,
              credentials: "include",
            }
          );
          console.log("Response:", response.data);
          return true;
          // setMainDataCallback(response.data.result);
        } catch (error) {
          console.error("Error:", error.message);
        }
      },

};

export default SMS;