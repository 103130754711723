import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Company = {

  CreateCompany: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Company`;
      const response = await axios.post(
        apiUrl,
        {
          name: data.name,
          businessTypes: Number(data.businessTypes),
          countryId: 1101,
          stateId: 8,
          mobileNo: "",
          email: data.email,
        },
        {
          withCredentials: true,
        }
      );
      console.log(response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // **********************{Insert Company Setting}************************

  insertCreateCompanyMasterSetting: async (data) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/Company/CreateCompanyMasterSetting`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          additionalDetails: parseBoolean(data.additionalDetails),
          vatGstDetails: parseBoolean(data.vatGstDetails),
          upiPaymentDetails: parseBoolean(data.upiPaymentDetails),
          faxNo: parseBoolean(data.faxNo),
          loginThroughBranchCode: parseBoolean(data.loginThroughBranchCode),

        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get All ***************************
  getCompanyMasterSettings: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Company/GetCompanyMasterSettings`;
      const response = await axios.post(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  GetCompany: async () => {
    console.log("get company");
    try {
      const apiUrl = `${WebApiUrl}/api/Company/GetCompanies`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log(response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  updateCompany: async (data) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }

    try {
      const apiUrl = `${WebApiUrl}/api/Company?CompanyId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        {
          id: Number(data.id),
          name: data.name,
          printName: data.printName,
          shortName: data.shortName,
          branchCode: data.branchCode,
          fySessionFrom: data.fySessionFrom,
          fySessionTo: data.fySessionTo,
          businessTypes: Number(data.businessTypes),
          defaultLanguage: Number(data.defaultLanguage),
          firmAddress1: data.firmAddress1,
          firmAddress2: data.firmAddress2,
          firmAddress3: data.firmAddress3,
          countryId: 1108,
          stateId: Number(data.stateId),
          pinCode: data.pinCode,
          mobileNo: data.mobileNo,
          mobileNoForOTP: data.mobileNoForOTP,
          whatsappNo: data.whatsappNo,
          email: data.email,
          telephoneNo: data.telephoneNo,
          pan: data.pan,
          website: data.website,
          facebook: data.facebook,
          instagram: data.instagram,
          fax: data.fax,
          currencySymbol: data.currencySymbol,
          currencyString: data.currencyString,
          currencyUnit: data.currencyUnit,
          currencyDecimalPlaces: Number(data.currencyDecimalPlaces),
          dayIntervalForBackup: Number(data.dayIntervalForBackup),
          ecomMinShopLimit: Number(data.ecomMinShopLimit),
          enableVatGST: parseBoolean(data.enableVatGST),
          // vatGstType: data.vatGstType,
          enableAddTaxSchg: parseBoolean(data.enableAddTaxSchg),
          gstTinNo: data.gstTinNo,
          cstNo: data.cstNo,
          drugLicenseNo: data.drugLicenseNo,
          drugLicenseExpiryDate: data.drugLicenseExpiryDate,
          termsCondition: data.termsCondition,
          payeeName: data.payeeName,
          upiId: data.upiId,
          transferMsg: data.transferMsg,
          currencyCode: data.currencyCode,
          transferVoucherNo: data.transferVoucherNo,
          payAmt: Number(data.payAmt),
          isEnblMobOrdr: parseBoolean(data.isEnblMobOrdr),
          isEnblPayFrmOrdrTakerApp: parseBoolean(data.isEnblPayFrmOrdrTakerApp),
          isEnableWebsite: parseBoolean(data.isEnableWebsite),
          isEnableHomeDelivery: parseBoolean(data.isEnableHomeDelivery),
          isEnableTblOrder: parseBoolean(data.isEnableTblOrder),
          isEnablePickUp: parseBoolean(data.isEnablePickUp),
          isEnblItmAvailTimeChk: parseBoolean(data.isEnblItmAvailTimeChk),
          isEnblItmAvailDateChk: parseBoolean(data.isEnblItmAvailDateChk),
          isEnblDlvryPnt: parseBoolean(data.isEnblDlvryPnt),
          isEnblTmSltPopupInHmPg: parseBoolean(data.isEnblTmSltPopupInHmPg),
          isEnblCnclOrdrFrmDlvryBoyApp: parseBoolean(
            data.isEnblCnclOrdrFrmDlvryBoyApp
          ),
          isAllowTblOptionForGuest: parseBoolean(data.isAllowTblOptionForGuest),
          vatGstType: "",
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetCompanyById: async (CompanyId) => {
    try {
      console.log("get by id");
      const apiUrl = `${WebApiUrl}/api/Company?CompanyId=${CompanyId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


  CompanyCreationAutoLogin: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/UserInfo/login`;
      console.log(apiUrl);
      const response = await axios.post(apiUrl, {
        compCode: "c0001",
        userName: "admin",
        password: "admin",
        comClientRefId: "string",
      }, {
        withCredentials: true,
      });
      return true

    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Authentication failed: Incorrect username or password");
      } else {
        alert("An error occurred during login. Please try again.");
      }
    }
    //AccountGroupApi.GetAccountGroups(setMainDataCallback);
    //StateInfoApi.GetState(setStateInfoCallback);
  }


};
export default Company;
