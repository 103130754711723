import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import HomePage from "./MyApp/Pages/HomePage";
import NavBar from "./MyApp/Component/NavBar";
import Footer from "./MyApp/Component/Footer";
import MyAccount from "./MyApp/Pages/MyAccount";
import MyCart from "./MyApp/Pages/MyCart";
import MyFavorites from "./MyApp/Pages/MyFavorites";
import OurMenu from "./MyApp/Pages/OurMenu";
import MyAccountPage from "./MyApp/Pages/MyAccountPage";
import MyContext from "./MyApp/Component/MyContext";
import { useEffect, useState } from "react";
import MobileBottomMentBtn from "./MyApp/Component/MobileBottomMentBtn";
import TermsAndConditions from "./MyApp/Pages/TermsAndConditions";
import NotFound from "./MyApp/Pages/NotFound";
import Company from "./MyApp/API/API_SERVISES/Company";
import ItemMasterApi from "./MyApp/API/API_SERVISES/ItemMaster";
import itemGroupApi from "./MyApp/API/API_SERVISES/ItemGroup";
import User from "./MyApp/API/API_SERVISES/User";
import TrackOrderStatus from "./MyApp/Pages/TrackOrderStatus";
import Request from "./MyApp/Pages/Request";
import Response from "./MyApp/Pages/Response";
import Order from "./MyApp/API/API_SERVISES/Order";
import Loader from "./MyApp/Component/Loader";
import CurrentDateAndTime from "./MyApp/API/API_SERVISES/CurrentDateAndTime";
import SMS from "./MyApp/API/API_SERVISES/SMS";
import SmsTemplate from "./MyApp/API/API_SERVISES/SMSTemplate";
import Feedback from "./MyApp/Pages/Feedback";
import DeliveryAndTimeSlot from "./MyApp/API/API_SERVISES/DeliveryAndTimeSlot";
import AddAddress from "./MyApp/API/API_SERVISES/AddAddress";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function App() {

  const location = useLocation();

  const [checkCredentialsPopup, setCheckCredentialsPopup] = useState(false);
  const [EcomToken, setEcomToken] = useState("");
  const [CompanyDetail, setCompanyDetail] = useState({})
  const [currentUser, setCurrentUser] = useState(false)
  const [currentUserData, setCurrentUserData] = useState([])
  const [DefaultAddressChange, setDefaultAddressChange] = useState(false)
  const [ItemGroupMaster, setItemGroupMaster] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [LoginShow, setLoginShow] = useState(true);
  const [myAccount, setMyAccount] = useState(false);
  const [productlist, setProductList] = useState([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [accessToken, setaccessToken] = useState("");
  const [refreshToken, setrefreshToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [addresses, setAddAddresses] = useState([]);
  const currentPath = window.location.pathname;

  const [activeLink, setActiveLink] = useState(currentPath);

  const initialOrderList = [];
  const initialOrderList3 = [];
  const initialCartData = [];
  const initialButtonClickCounts = [];

  const storedOrderList = localStorage.getItem("orderList");
  const storedOrderList3 = localStorage.getItem("orderList3");
  const storedCartData = localStorage.getItem("cartData");
  const storedItemCount = localStorage.getItem("itemCount");
  const [OrderType, setOrderType] = useState("Home Delivery")
  const [selectedService, setSelectedService] = useState(752);
  const [orderList, setOrderList] = useState(
    storedOrderList ? JSON.parse(storedOrderList) : initialOrderList
  );
  const [orderList3, setOrderList3] = useState(
    storedOrderList3 ? JSON.parse(storedOrderList3) : initialOrderList3
  );
  const [cartData, setCartData] = useState(
    storedCartData ? JSON.parse(storedCartData) : initialCartData
  );
  const [buttonClickCounts, setButtonClickCounts] = useState(
    storedItemCount ? JSON.parse(storedItemCount) : initialButtonClickCounts
  );

  const [saveCartInLocal, setSaveCartInLocal] = useState([]);
  const [cartNumber, setCartNumber] = useState(0);
  const [wishListNumber, setWishListNumber] = useState(0);
  const [serviceTypeShow, setServiceTypeShow] = useState(true)

  const [paymentCart, setPaymentCart] = useState(true)

  const [loginPanel, setLoginPanel] = useState(true);
  const [signin, setSignin] = useState(false);
  const [getOtp, setgetOtp] = useState(false);
  const [forgot, setforgot] = useState(false);
  const [ResetPassword, setResetPassword] = useState(false);
  const [isFromOrderTaker, setIsOrderTaker] = useState(false);
  const [wishList, setWishList] = useState([]);
  const [RunningOrderData, setRunningOrderData] = useState(0);
  const [inputValues, setInputValues] = useState({
    d1ItemId: "",
    d2ItemId: "",
    d1AccountId: "",
    d2AccountId: "",
    userId: "",
    quantity: "",
    unit: "Pcs",
    itemPrice: "",
    clientId: ""
  });

  const openCredentialModal = () => {
    setCheckCredentialsPopup(!checkCredentialsPopup);
  };

  const checkCredentials1 = async () => {
    console.log('checkCredentials1 called');
    if (currentUser === false) {
      const data = await User.checkCredentials();
      if (data === false) { // false
        openCredentialModal();
      } else {
        const currentUserData = await User.GetCurrentUser();
        if (currentUserData) {
          setCurrentUserData(currentUserData);
          setCurrentUser(true);
        }
      }
    }
  };

  useEffect(() => {
    console.log('useEffect called');
    if (currentUser === false) {
      checkCredentials1();
    }
  }, []);

  const sendToken = async () => {
    if (EcomToken == "" && EcomToken == undefined) {
      alert("Check the Token and try again")
    } else {
      const data = await User.SendToken(EcomToken);
      if (data == true) {
        alert("Lets go")
        window.location.reload()
      }
      else {
        alert("Check the Token and try again")
      }
    }
  };
  const handletoken = (e) => {
    var token = e.target.value;
    setEcomToken(token);
  };

  const [currentTimeData, setCurrentTimeData] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    GetTime();
    GetDate();
  }, []);
  const GetTime = async () => {
    if (currentUser === true) {
      const currentTime = await CurrentDateAndTime.getCurrentTime();
      setCurrentTimeData(currentTime);
    }
  };
  const GetDate = async () => {
    if (currentUser === true) {
      const currentTime = await CurrentDateAndTime.getCurrentDate();
      setCurrentDate(currentTime);
    }
  };


  const fetchItemMaster = async () => {
    setLoading(true);
    try {
      const categoryName = await ItemMasterApi.GetItemMasterFilterWithPage();
      setProductList(categoryName);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };


  useEffect(() => {
    if (productlist.length === 0 && currentUser === true) {
      fetchItemMaster();
    }
  }, [currentUser]);


  useEffect(() => {
    if (!currentUser) return; // Guard clause to exit if currentUser is false
    const fetchCompanyDetail = async () => {
      try {
        const CompanyData = await Company.GetCompanyById(
          currentUserData.branchId
        );
        if (CompanyData) {
          setCompanyDetail(CompanyData);
        } else {
          console.log("Error while finding the company");
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
        // alert("Error while finding the company");
      }
    };

    fetchCompanyDetail();
  }, [currentUser]);

  const fetchDataig = async () => {
    if (!currentUser) return; // Guard clause to exit if currentUser is false
    if (productlist.length === 0 && currentUser === true) {
      setLoading(true)
      const data = await itemGroupApi.GetItemGroup();
      const ItemGroupMaster = data.sort((a, b) => a.displayOrder - b.displayOrder);
      if (ItemGroupMaster) {
        setItemGroupMaster(ItemGroupMaster);
        setLoading(false)
      }
      else {
        console.error("error while finding the item group master")
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchDataig();
  }, [currentUser]);

  const fetchCartdata = async () => {
    try {
      setLoading(true)
      var loginDataString = localStorage.getItem("accountLogin"); // This is a string
      var loginData = JSON.parse(loginDataString);
      setLoginData(loginData);

      //var isFromOrderTaker = localStorage.getItem("OrderTaker");
      // let data;
      // if (isFromOrderTaker !== "Yes") {
      // }
      const data = await Order.getCartList(loginData ? loginData.d2Id || 0 : 0);
      setCartData(data);

      let buttonClickCounts = {};

      // Iterate over the data array to populate the buttonClickCounts object
      data && data.forEach((item) => {
        buttonClickCounts[item.d2ItemId] = item.quantity;
      });

      console.log(productlist);

      const parentQuantities = {};

      // Iterate over the productlist to aggregate quantities
      if (productlist.length > 0) {
        productlist.forEach((product) => {
          const parentId = product.attributeParentId;
          const childQuantity = buttonClickCounts[product.d2Id];

          // Add child quantity to parent quantity
          if (parentId !== undefined && childQuantity !== undefined) {
            if (!parentQuantities[parentId]) {
              parentQuantities[parentId] = 0;
            }
            parentQuantities[parentId] += childQuantity;
          }
        });
        // Step 2: Update the buttonClickCounts object with the aggregated parent quantities
        Object.keys(parentQuantities).forEach((parentId) => {
          const id = Number(parentId);
          if (buttonClickCounts[id] !== undefined) {
            buttonClickCounts[id] += parentQuantities[id];
          } else {
            buttonClickCounts[id] = parentQuantities[id];
          }
        });
        console.log(buttonClickCounts);
        setButtonClickCounts(buttonClickCounts);
      }
      setLoading(false)
    } catch (error) {
      console.error("Error fetching API configuration:", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    if (currentUser === true) {
      fetchCartdata();
    }
  }, [productlist, currentUser]);

  const fetchDataWish = async () => {
    var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    var loginData = JSON.parse(loginDataString);
    if (loginData) {
      setLoginData(loginData);
      const filteredData = [
        {
          fieldName: "d1AccountId",
          operatorName: "equal",
          compareValue: loginData.d1Id,
        },
      ];
      if (currentUser) {
        try {
          setLoading(true)
          const data = await Order.getGetAllFavouriteItemList(filteredData);
          setWishList(data);
          setLoading(false)
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false)
        }
      }
    }
  };

  useEffect(() => {
    fetchDataWish();
  }, [currentUser]);

  // const calculateTotalQty = (data) => {
  //   return data.reduce((total, item) => {
  //     console.log(item.quantity); // Debug individual quantities
  //     return total + Number(item.quantity || 0);
  //   }, 0);
  // };

  // if (cartData && Array.isArray(cartData)) {
  //   console.log("CartData App.js", cartData);
  //   const totalQty = calculateTotalQty(cartData);
  //   setCartNumber(totalQty);
  //   alert(totalQty);
  // setCartNumber(cartData.length);

  useEffect(() => {
    if (currentUser) {
      if (cartData && Array.isArray(cartData)) {
        const totalQuantity = cartData.reduce((total, item) => total + (item.quantity || 0), 0);
        // console.log("CartData App.js", cartData);
        setCartNumber(totalQuantity);
      } else {
        // Handle the case when cartData is undefined or not an array
        setCartNumber(0);
      }
    }
    setWishListNumber(wishList.length);
  }, [currentUser, cartData, LoginShow, wishList]);

  // Serialize state and update local storage whenever it changes
  const fetchData = () => {
    var isFromOrderTaker = localStorage.getItem("OrderTaker");
    var ServiceTypeData = localStorage.getItem("ServiceType");
    if (ServiceTypeData == 752) {
      setOrderType("Home Delivery")
      setSelectedService(ServiceTypeData)
    } else if (ServiceTypeData == 753) {
      setOrderType("Pick Up")
      setSelectedService(ServiceTypeData)
    } else if (ServiceTypeData == 751) {
      setOrderType("Table Order")
      setSelectedService(ServiceTypeData)
    } else if (ServiceTypeData == null || ServiceTypeData == undefined) {
      //alert(isFromOrderTaker)
      if (isFromOrderTaker == "Yes") {
        setSelectedService(751)
        setOrderType("Table Order")
      }
      else {
        setSelectedService(752)
        setOrderType("Home Delivery")
      }
    }
  }

  useEffect(() => {
    fetchData();
  })

  useEffect(() => {
    const serializedOrder = JSON.stringify(orderList);
    const serializedOrder3 = JSON.stringify(orderList3);
    const serializedCartData = JSON.stringify(cartData);
    const cartNumber1 = JSON.stringify(cartNumber);
    const itemCount1 = JSON.stringify(buttonClickCounts);

    localStorage.setItem("itemCount", "");
    localStorage.setItem("cartNumber", "");
    localStorage.setItem("orderList", "");
    localStorage.setItem("orderList3", "");
    // if(serializedCartData === "undefined"){
    //   localStorage.setItem("cartData", "");
    // }
    // else{
    //   localStorage.setItem("cartData", serializedCartData);
    // }
    localStorage.setItem("cartData", "");
  }, [orderList, orderList3, cartData, cartNumber, buttonClickCounts]);

  // Rest of your component code
  const AccessToken = { accessToken };
  const RefreshToken = {
    refreshToken,
  };

  //  console.log(AccessToken);
  //  console.log(RefreshToken);

  // Event handler function to prevent right-click
  const disableRightClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("contextmenu", disableRightClick);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  const navigate = useNavigate();
  const userLogin = localStorage.getItem("accountLogin");
  const userArray = userLogin ? JSON.parse(userLogin) : null;

  const myaccountmodal = () => {
    const userLogin = localStorage.getItem("accountLogin");
    const userArray = userLogin ? JSON.parse(userLogin) : null;

    if (userArray) {
      // UserArray is present, navigate to "/MyAccountPage"
      // setMyAccount(!myAccount);
      setActiveLink("/MyAccountPage")
      navigate("/MyAccountPage");
    } else {
      // UserArray is not present, continue with your existing logic
      setMyAccount(!myAccount);
      document.body.style.overflow = myAccount ? "auto" : "hidden";
      signupshow();
    }
  };

  const myaccountmodal1 = () => {
    setMyAccount(!myAccount);
    document.body.style.overflow = myAccount ? "auto" : "hidden";
    signupshow1();
  };

  const signupshow = () => {
    setLoading(true);
    setLoginPanel(true);
    setSignin(false);
    setgetOtp(false)
    setforgot(false)
    setResetPassword(false)
    setIsOrderTaker(false)
    setLoading(false);
  };
  const ResetPasswordUI = () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(false)
    setforgot(false)
    setResetPassword(true)
    setIsOrderTaker(false)
  };

  const GoToOTP = async () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(true)
    setforgot(false)
    setResetPassword(false)
    setIsOrderTaker(false)

  };
  const signupshow1 = () => {
    setLoginPanel(false);
    setSignin(true);
    setgetOtp(false)
    setforgot(false)
    setResetPassword(false)
    setIsOrderTaker(false)

  };

  const forgotPaggword = () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(false)
    setforgot(true)
    setResetPassword(false)
    setIsOrderTaker(false)
  };

  const CheckOtpAndSignIn = () => {

  }

  const loginPanelOfOrderTaker = () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(false)
    setforgot(false)
    setResetPassword(false)
    setIsOrderTaker(true)
  }

  // const handleKeyDown = (e) => {
  //   // Check if F12 is pressed
  //   if (e.key === 'F12') {
  //     e.preventDefault(); // Prevent the default behavior (opening developer tools)
  //     alert('Developer tools are disabled');
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('keydown', handleKeyDown);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  const calculateTotalAmount = () => {
    const totalAmount = cartData.reduce((total, item) => {
      return total + item.quantity * item.itemPrice;
    }, 0);
    // console.log('Total amount:', totalAmount);
    setTotalAmount(totalAmount);
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [orderList, cartData]);

  const AddToCart = async (item) => {
    setLoading(true)
    var carditem = cartData.find(item1 => item1.d2ItemId === item.d2Id || item1.d2ItemId === item.d2ItemId);
    const parantData = productlist.find(item1 => item1.d2Id === item.d2Id || item1.d2Id === item.d2ItemId);

    if (carditem?.quantity >= parantData?.restoAvailableQuantity && parantData?.stockCheck === 1) {
      alert(`Out of Stock Only ${parantData?.restoAvailableQuantity} Quantity available in stock`);
    }
    else {
      const parantData = productlist.find((item1) => item1.d2Id === item.d2Id || item.d2ItemId);

      if (parantData.attributeParentId === 0 && parantData.isAttributeItem === true) {
      } else {
        var loginDataString = localStorage.getItem("accountLogin"); // This is a string
        var loginData = JSON.parse(loginDataString); // Now it's an object
        if (loginData === null) {
          loginData = {
            d1Id: 0,
            d2Id: 0,
          }
        }
        if (cartData.length !== 0) {
          var quantityFromCartData = cartData.filter(item1 => (item1.d1ItemId === item.d1Id))[0];
        }
        const data = {
          d1ItemId: item.d1Id || item.d1ItemId,
          d2ItemId: item.d2Id || item.d2ItemId,
          d1AccountId: loginData ? (loginData.d1Id || 0) : 0,
          d2AccountId: loginData ? (loginData.d2Id || 0) : 0,
          userId: 0,
          quantity: (item?.quantity || quantityFromCartData?.quantity || 0) + 1,
          unit: "Pcs",
          itemPrice: item.salePrice || item.itemPrice,
          clientId: 0
        };
        const isSuccess = await Order.insertCreateEcomCartDetail(data);
        if (isSuccess) {
          await fetchCartdata();
          setInputValues({
            d1ItemId: "",
            d2ItemId: "",
            d1AccountId: "",
            d2AccountId: "",
            userId: "",
            quantity: "",
            unit: "",
            itemPrice: "",
            clientId: ""
          });
          calculateTotalAmount();
          setLoading(false)
        }
      }
      setLoading(false)
    }
    setLoading(false)
  };

  const RemoveFromCart = async (item) => {
    setLoading(true)

    var loginDataString = localStorage.getItem("accountLogin"); // This i~s a string
    var loginData = JSON.parse(loginDataString); // Now it's an object
    if (cartData.length !== 0) {
      var quantityFromCartData = cartData.filter(item1 => (item1.d1ItemId === item.d1Id))[0];
    }
    const data = {
      d1ItemId: item.d1Id || item.d1ItemId,
      d2ItemId: item.d2Id || item.d2ItemId,
      d1AccountId: loginData ? (loginData.d1Id || 0) : 0,
      d2AccountId: loginData ? (loginData.d2Id || 0) : 0,
      userId: 0,
      quantity: (item?.quantity || quantityFromCartData?.quantity || 0) - 1,
      unit: "Pcs",
      itemPrice: item.salePrice || item.itemPrice,
      clientId: 0
    };
    const isSuccess = await Order.RemoveEcomCartDetail(data);
    if (isSuccess) {
      setLoading(false)

      fetchCartdata();
      setInputValues({
        d1ItemId: " ",
        d2ItemId: " ",
        d1AccountId: " ",
        d2AccountId: " ",
        userId: " ",
        quantity: " ",
        unit: " ",
        itemPrice: " ",
        clientId: ""
      });
      calculateTotalAmount();
      setLoading(false)
    }
    setLoading(false)
  };

  const imageBaseUrl = `${WebApiUrl}/api/ItemMaster/GetImage?D1ItemId=`;

  const [mainModal, setMainModal] = useState(false);

  const closemainmodal = () => {
    setMainModal(false);
    document.body.style.overflow = "auto";
  };

  const openmainmodal = () => {
    setMainModal(true);
    document.body.style.overflow = "auto";
  };

  const [AllottedTimeSlot, setAllottedTimeSlot] = useState([]);

  const getDeliveryTimeSlots = async (updatedValues, serviceTypeData) => {
    try {
      setLoading(true);
      const deliverySlot = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues, CompanyDetail.isEnableGoogleMap);
      if (deliverySlot.length == 0) {
        setAllottedTimeSlot([])
        return false;
      }
      const uniqueSlots = deliverySlot.filter((data, index, self) =>
        index === self.findIndex((t) => t.id === data.id)
      );

      const serviceTypeId = updatedValues.orderType;

      const serviceData = serviceTypeData.filter(item => item.id == serviceTypeId)

      const selectedTimeSlot = uniqueSlots.slice(0, serviceData[0].noOfTimeSlot);

      setAllottedTimeSlot(selectedTimeSlot);
      localStorage.setItem("deliveryTime", selectedTimeSlot[0]?.id)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching time slots:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const getCurrentDate = async () => {
    const date = await CurrentDateAndTime.getCurrentDate();
    return date;
  };

  useEffect(() => {
    const fetchData = async () => {
      const selectedIsoDate = localStorage.getItem("deliverydate");
      const currentDate = await getCurrentDate();

      var isCrntDate;
      if (currentDate == selectedIsoDate)
        isCrntDate = true
      else
        isCrntDate = false

      var loginDataString = localStorage.getItem("accountLogin");
      var loginData = JSON.parse(loginDataString);

      var isFromOrderTaker = localStorage.getItem("OrderTaker");
      let ServiceTypeData = localStorage.getItem("ServiceType");

      let timeDistance = 0;
      if (ServiceTypeData == 752) {
        if (loginData) {
          const defaultData = await AddAddress.getAddress(loginData.d1Id)
          if (defaultData) {
            setAddAddresses(defaultData)
            const defaultAddress = defaultData.filter(item => item.isDefault == true);
            if (defaultAddress[0]?.distance != null && defaultAddress[0]?.distance != 0) {
              timeDistance = defaultAddress[0]?.distance;
            }
          }
        }
      }
      if (loginData) {
        const addressesData = await AddAddress.getAddress(loginData.d1Id)
        setAddAddresses(addressesData)
      }

      if (isFromOrderTaker == "Yes") {
        ServiceTypeData = 751;
      }
      let updatedValues = {
        deliveryPointName: 1,
        distance: timeDistance,
        orderType: ServiceTypeData,
        isCurrentDate: isCrntDate,
      };
      const serviceTypeData = await Order.getServiceType();
      getDeliveryTimeSlots(updatedValues, serviceTypeData)
    }
    fetchData();

  }, [])

  const hideHeaderFooterRoutes = ['/Response'];
  const shouldHideHeaderFooter = hideHeaderFooterRoutes.includes(location.pathname);

  // ********************************************************************
  const messageWhatsAppFunction = async (smsType, OrderId, AccId, mobileNo) => {
    // ******************** SMS/MSG **********************************
    debugger;
    let smsData;
    try {
      smsData = await SmsTemplate.GetAssignTemplatesByName(smsType, OrderId, AccId)

      const sendDataSms = {
        to: mobileNo,
        message: smsData[0]?.templateForFormDetails,
        smsTempAttr: smsData[0]?.templateForSMSAPI,
        smsTempVal: smsData[0]?.templateId,
      }
      const sendSMS = await SMS.SendSMS(sendDataSms);
    }
    catch (error) {
      console.log(error)
    }
    // ******************** WhatsApp MSG **********************************
    try {
      let mediaId = "";

      if (smsData[0]?.messageHeaderSection_ != "" || smsData[0]?.messageHeaderSection_.includes("WhatsappInvPdfUpload")) {
        mediaId = "";
      }
      const sendDataWhatsApp = {
        to: mobileNo,
        templateName: smsData[0]?.templateForWhatsappAPI,
        templateLanguage: smsData[0]?.whatsappLanguage,
        headerSection: smsData[0]?.messageHeaderSection_,
        bodySection: smsData[0]?.messageBodySection_,
        fileMediaId: mediaId
      }
      const sendWhatsApp = await SMS.SendWhatsApp(sendDataWhatsApp)
    }
    catch (error) {
      console.log(error)
    }
    // ****************************************************************
  }

  return (
    <>
      <MyContext.Provider
        value={{
          // constextProduct,
          cartData,
          orderList3,
          orderList,
          setCartData,
          setOrderList3,
          setOrderList,
          myAccount,
          signupshow1,
          setMyAccount,
          myaccountmodal,
          loginPanel,
          signin,
          getOtp,
          GoToOTP,
          signupshow,
          myaccountmodal1,
          setrefreshToken,
          setaccessToken,
          refreshToken,
          accessToken,
          setLoading,
          cartNumber,
          buttonClickCounts,
          setButtonClickCounts,
          userArray,
          LoginShow,
          imageBaseUrl,
          setLoginShow,
          OrderType,
          setOrderType,
          CompanyDetail,
          setProductList,
          productlist,
          selectedService,
          setSelectedService,
          setServiceTypeShow,
          serviceTypeShow,
          paymentCart,
          setPaymentCart,
          isInputDisabled,
          setIsInputDisabled,
          CheckOtpAndSignIn,
          DefaultAddressChange,
          setDefaultAddressChange,
          fetchData,
          fetchItemMaster,
          ItemGroupMaster,
          forgotPaggword,
          forgot,
          setforgot,
          ResetPassword,
          ResetPasswordUI,
          setItemGroupMaster,
          isFromOrderTaker,
          setIsOrderTaker,
          fetchCartdata,
          RemoveFromCart,
          AddToCart,
          calculateTotalAmount,
          totalAmount,
          loginData,
          wishList,
          fetchDataWish,
          setRunningOrderData,
          RunningOrderData,
          currentUser,
          setTotalAmount,
          addresses,
          setAddAddresses,
          mainModal,
          setMainModal,
          closemainmodal,
          openmainmodal,
          setAllottedTimeSlot,
          AllottedTimeSlot,
          currentTimeData,
          messageWhatsAppFunction,
          activeLink,
          setActiveLink,
          currentDate, GetDate,
          GetTime
        }}
      >
        {!shouldHideHeaderFooter && <NavBar cartNumber={cartNumber} wishList={wishListNumber} />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/MyCart" element={<MyCart />} />
          <Route path="/OurMenu" element={<OurMenu />} />
          <Route path="/MyFavorites" element={<MyFavorites />} />
          <Route path="/MyAccountpage" element={<MyAccountPage />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/TrackOrderStatus" element={<TrackOrderStatus />} />
          <Route path="/Request" element={<Request />} />
          <Route path="/Response" element={<Response />} />
          <Route path="/Feedback" element={<Feedback />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {!shouldHideHeaderFooter && <MobileBottomMentBtn cartNumber={cartNumber} />}
        {!shouldHideHeaderFooter && <Footer />}

        {myAccount && <MyAccount close={myaccountmodal} />}
        {checkCredentialsPopup && (
          <div className="modal-back">
            <div
              className="account-modal"
              style={{
                height: "120px",
                flexDirection: "column",
                paddingBottom: "10px",
              }}
            >
              <div
                className="input-group"
                style={{ width: "90%", margin: "auto 0" }}
              >
                <label htmlFor="username">Enter Token</label>
                <input
                  type="text"
                  onChange={(e) => handletoken(e)}
                  id="username"
                  placeholder=""
                  name=""
                />
              </div>
              <button disabled={EcomToken != "" && EcomToken != null && EcomToken != undefined ? false : true}
                style={{ padding: "5px 20px", border: "none", borderRadius: "5px", backgroundColor: "rgba(167, 139, 250, 1)" }} onClick={sendToken}> Submit </button>
            </div>
          </div>
        )}

      </MyContext.Provider>


      {loading && <Loader />}

    </>
  );
}

export default App;
